import React from 'react'
import styled from 'styled-components'

interface ExplorerTabTypes {
    active?: boolean;
    text?: string;
    onClick: any;
    borderRadius?: string;
    borderRight?: string;
}


const ExplorerTabstyle = styled.div<ExplorerTabTypes>`
    cursor: pointer;
    max-width: 200px;
    width: 100%;
    background: ${(props) => props.active ? '#282828' : '#e7e7e7'};
    color: ${(props) => props.active ? 'white' : 'black'};
    border: 1px #282828 solid;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    height: 49px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${(props) => props.borderRadius};
    border-right: ${(props) => props.borderRight};
    @media screen and (max-width: 768px) {
        
    }
`
const ExplorerTab = ({
    active,
    text,
    onClick,
    borderRadius,
    borderRight
}: ExplorerTabTypes) => {
    return (
        <ExplorerTabstyle
            active={active}
            onClick={onClick}
            borderRadius={borderRadius}
            borderRight={borderRight}
        >
            {text}
        </ExplorerTabstyle>
    )
}

export default ExplorerTab
