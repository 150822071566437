import { useState } from 'react'
import CustomInput from './common/CustomInput'
import { FlexBox } from './common/FlexBox'
import CustomText from './common/CustomText'
import SocialImageLink from './common/SocialImageLink'
import { DISCORD_BLACK_ICON, SUCCESS_ICON, TWITTER_ICON } from 'constants/image.constants'
import { CRASHR_DISCORD_URL, CRASHR_TWITTER_URL } from 'constants/url.constant'
import CustomButton from './common/CustomButton'
import { storeEmails } from 'api/api'
import { infoAlert } from 'hooks/alert'
import CustomImage from './common/CustomImage'

const ComingSoon = () => {
  const [email, setEmail] = useState<string>()
  const [success, setSuccess] = useState<boolean>(false);
  const [isValidEmail, setIsValidEmail] = useState<boolean>(true); // Initially set to true

  const handleEmailChange = (e) => {
    const inputValue = e.target.value;
    setEmail(inputValue);

    // Regular expression for email validation
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const isValid = emailRegex.test(inputValue);

    setIsValidEmail(isValid);
  };

  const storeEmailValue = async () => {
    if (!isValidEmail) {
      return;
    } else {
      const status: number = await storeEmails(email)
      if (status === 200) {
        // infoAlert("Your email is stored in the waiting list")
        setSuccess(true);
      } else {
        infoAlert("Your email is already existing!!!")
      }
    }

  }
  return (
    <>
      <FlexBox
        borderBottom='1px solid black'
        borderLeft='2px solid black'
        borderTop='2px solid black'
        borderRight='2px solid black'
        paddingTop='106px'
        paddingBottom='106px'
        paddingLeft='15px'
        paddingRight='15px'
        mdPaddingLeft='15px'
        mdPaddingRight='15px'
        mdPaddingTop='30px'
        mdPaddingBottom='30px'
        direction='column'
        justifyContent='center'
        alignItems='center'
        textAlign='center'
      >
        <CustomText
          text={`Coming Soon`}
          fontSize='66px'
          mdFontSize='50px'
          mdLineHeight='120%'
          fontWeight='700'
          textAlign='center'
          fontFamily='Inconsolata'
        />
        <CustomText
          text={`We’re cooking up some exciting new features, available to you very soon. `}
          fontSize="21px"
          fontWeight='600'
          textAlign='center'
          marginTop='23px'
          mdFontSize='16px'
          mdMarginTop='16px'
        />
        {
          !success &&
          <>
            <FlexBox gap="24px" marginTop='64px' mdJustifyContent='center' mdAlignItems='center' mdMarginTop='43px'>
              <CustomInput
                placeholder='Enter your email'
                maxWidth='433px'
                height='48px'
                fontWeight='400'
                padding='8px 10px'
                bgColor='#fcf1CD'
                type='email'
                value={email}
                onChange={handleEmailChange}
              />
              <CustomButton
                width='144px'
                height='48px'
                text="Notify me"
                smWidth='144px'
                fontFamily='Open Sans'
                fontWeight='600'
                smHeight='38px'
                onClick={storeEmailValue}
              />
            </FlexBox>
            {!isValidEmail && <p style={{ color: 'red', fontSize: '16px' }}>Please enter a valid email address.</p>}
          </>
        }
        {
          success &&
          <FlexBox marginTop='64px' direction='column' gap="24px" justifyContent='center' alignItems='center'>
            <CustomImage
              width='66px'
              height='66px'
              mdWidth='36px'
              mdHeight='36px'
              image={SUCCESS_ICON}
            />
            <CustomText
              text={`You will be notified!`}
              color={`#40A140`}
              fontSize='21px'
              fontWeight='600'
            />
          </FlexBox>
        }
      </FlexBox>
      <FlexBox
        borderBottom='2px solid black'
        borderLeft='2px solid black'
        borderTop='1px solid black'
        borderRight='2px solid black'
        paddingTop='49px'
        paddingBottom='50px'
        mdPaddingTop='20px'
        mdPaddingBottom='20px'
        direction='column'
        justifyContent='center'
        alignItems='center'
        textAlign='center'
        bgColor='#fae39c'
        gap="24px"
      >
        <CustomText
          text={`Stay Connected, Stay Updated! `}
          fontSize='21px'
          fontWeight='400'
        />
        <FlexBox gap="16px" mdDirection='row'>
          <SocialImageLink
            image={DISCORD_BLACK_ICON}
            link={CRASHR_DISCORD_URL}
            width='32px'
            height='24px'
            mdWidth='27px'
            mdHeight='20px'
          />
          <SocialImageLink
            image={TWITTER_ICON}
            link={CRASHR_TWITTER_URL}
            width='26px'
            height='24px'
            mdWidth='24px'
            mdHeight='22px'
          />

        </FlexBox>

      </FlexBox>

    </>
  )
}

export default ComingSoon