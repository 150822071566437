import { MAIN_POLICY_ID } from "./_main.constant";
import { TEST_POLICY_ID } from "./_test.contants";


export const NFT_LISTING_API: string = "https://cardano-mainnet.blockfrost.io/api/v0/assets/policy/" + MAIN_POLICY_ID + "?page=";

// export const NFT_LISTING_API: string = "https://cardano-preprod.blockfrost.io/api/v0/assets/policy/" + MAIN_POLICY_ID;


export const GET_NFT_ASSET_API = "https://fk6vsmvml8.execute-api.eu-west-2.amazonaws.com/default/getNFTinfo?unit=";

export const GET_MY_NFTS_API = "https://4y90ofqzdi.execute-api.eu-west-2.amazonaws.com/default/crashr-getNFTs?address="

export const GET_CLUBS_API = "https://t24pnqu1dl.execute-api.us-west-2.amazonaws.com/items"


/*** my profile */
// get user data api + walletAddress
export const GET_USER_DATA_API = "https://qru4p76ipi.execute-api.us-west-2.amazonaws.com/items/"
export const UPDATE_USER_DATA_API = "https://ykeb2rfw6k.execute-api.us-west-2.amazonaws.com/default"