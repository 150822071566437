// This file contains variables for different themes

export const light = {
  body: '#fff',
  text: '#202020',
  bodyRgba: '255, 225, 255',
  textRgba: '32, 32, 32',
  bg: '#000000',

  carouselColor: '#EEEDDE',

  fontxs: '0.75em',
  fontsm: '0.875em',
  fontmd: '1em', // 1em = 16px
  fontlg: '1.25em',
  fontlx: '1.5em',
  fontxl: '2em',
  fontxlm: '2.25em',
  fontxlx: '2.5em',
  fontxxl: '3em',
  fontxxxl: '4em',

  fontButton: '0.875em',

  navHeight: '5rem',

  //custom css

  btnColor: '#85FF10',
  btnRadius: '0'
};

export const dark = {
  body: '#202020',
  text: '#fff',
  bodyRgba: '32, 32, 32',
  textRgba: '255, 225, 255',

  carouselColor: '#EEEDDE',

  fontxs: '0.75em',
  fontsm: '0.875em',
  fontmd: '1em', // 1em = 16px
  fontlg: '1.25em',
  fontxl: '2em',
  fontxxl: '3em',
  fontxxxl: '4em',

  fontButton: '0.875em',

  navHeight: '5rem'
};
