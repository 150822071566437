import CustomImage from 'components/common/CustomImage'
import { FlexBox } from 'components/common/FlexBox'

import styled from 'styled-components'
const ExplorerNFTCardStyle = styled.div`
    max-width: 171px;
    width: 100%;
    border-radius: 4px; 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;   
`
const CustomNFTImage = styled.img`
    width: 171px;
    height: 171px;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px
`

const ExplorerNFTCard = ({
    image, name
}: ExplorerNFTCardType) => {
    return (
        <ExplorerNFTCardStyle>
            <CustomNFTImage
                src={image}
            />

            <FlexBox
                color='black'
                bgColor='white'
                borderBottomLeftRadius='4px'
                borderBottomRightRadius='4px'
                fontSize='21px'
                lineHeight='21px'
                fontWeight='700'
                height='51px'
                textAlign='center'
                borderRadius='3.76px'
                width='100%'
                alignItems='center'
            >
                {name}
            </FlexBox>
        </ExplorerNFTCardStyle>
    )
}

export default ExplorerNFTCard
