import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { FlexBox } from '../common/FlexBox';
import CustomText from '../common/CustomText';
import CustomImage from 'components/common/CustomImage';
import { WALLETS_DATA } from 'constants/wallets.constant';
import { useWalletConnect } from 'hooks/WalletConnect';
import { useEffect, useState } from 'react';
import { ARROW_DOWN_ICON, ARROW_UP_ICON } from 'constants/image.constants';

interface Props {
  show: boolean;
  onClose: () => void;
  setConnected: any;
}

const StyledModal = styled(Modal)`
  box-shadow: 0px 5px 50px 9px #242424;
  .modal-dialog{
    margin: auto;
    max-width: 620x;
    width: 100%;
    background: transparent;
    border-radius: 16px;
    @media screen and (max-width: 550px) {
      max-width: 100%;
    }
  }
  .modal-header{
    border-bottom: none;
  }
  .btn-close{
    --bs-btn-close-bg: url('/assets/images/icons/close.svg')
  }
  .connect-success-content {
    box-shadow: 0px 5px 50px 9px #242424;
    background: #202020;
    border-radius: 3px;
    width: 100%;
    overflow: hidden;
    border: none;
    @media screen and (max-width: 550px) {
      height: 100%;
    }
  }
`;

const ModalBody = styled(Modal.Body)`
  &.modal-body{
    padding-top: 16px;
    padding-left: 60px;
    padding-right: 60px;
    padding-bottom: 50px;
    @media screen and (max-width: 768px) {
      padding-left: 30px;
      padding-right: 30px;
      padding-bottom: 25px;

    }
  }
`

const ConnectWalletModal = ({ show, onClose, setConnected }: Props) => {
  const [showMore, setShowMore] = useState(true)
  // @ts-ignore
  const { enableWallet, myWalletAddress } = useWalletConnect();
  const handleConnectWallet = (walletName: string) => async () => {
    try {
      await enableWallet(walletName);
      onClose();
    } catch (err) {
      onClose();
    }
  };
  useEffect(() => {
    if (myWalletAddress !== undefined && myWalletAddress.length > 0) {
      setConnected(true)
    }
  }, [myWalletAddress, setConnected])
  return (
    <StyledModal show={show} onHide={onClose} centered contentClassName="connect-success-content">


      <Modal.Header closeButton>

      </Modal.Header>
      <ModalBody>

        <CustomText
          text="Connect your wallet"
          fontSize='38px'
          fontWeight='700'
          fontFamily='Inconsolata'
          mdFontSize='30px'
          color='white'
        />
        <CustomText
          text={`Select you wallet. If you don’t have a wallet, please select a provider to create one. `}
          fontSize='16px'
          fontWeight='400'
          maxWidth='488px'
          marginTop='16px'
          marginBottom='33px'
          color='white'
        />

        <FlexBox
          paddingTop='12px'
          paddingBottom='11px'
          paddingLeft='16px'
          paddingRight='16px'
          mdPaddingLeft='10px'
          mdPaddingRight='10px'
          mdPaddingTop='10px'
          mdPaddingBottom='10px'
          bgImage='linear-gradient(94deg, rgba(255, 255, 255, 0.75) -57.59%, rgba(255, 255, 255, 0.34) 84.48%, rgba(255, 255, 255, 0.25) 116.04%)'
          border='3px'
          gap='26px'
          mdDirection='row'
          justifyContent='start'
          onClick={handleConnectWallet('nami')}
          cursor='pointer'
          mdJustifyContent='space-between'
          alignItems='center'
          borderRadius='3px'
        >
          <FlexBox
            justifyContent='start'
            gap="26px"
            alignItems='center'
            mdDirection='row'
            mdJustifyContent='start'

          >
            <CustomImage
              width='36px'
              height='40px'
              image={`/assets/images/wallets/nami.svg`}
            />
            <CustomText
              text={`Nami`}
              color='white'
              fontSize='28px'
              fontWeight='700'
              fontFamily='Inconsolata'
            />
          </FlexBox>
          <CustomText
            text="Recommended"
            fontSize='16px'
            fontWeight='600'
            color='#b0b9fb'
            fontFamily='Open Sans'

          />

        </FlexBox>
        <FlexBox
          justifyContent='end'
          gap="8px"
          alignItems='center'
          cursor='pointer'
          marginTop='16px'
        >
          {
            showMore ?
              <>
                <CustomText
                  cursor='pointer'
                  text={`Show More`}
                  onClick={() => setShowMore(false)}
                  fontSize='16px'
                  fontWeight='700'
                  color='white'
                />
                <CustomImage
                  image={ARROW_DOWN_ICON}
                  width='12px'
                  height='7px'
                />
              </>
              :
              <>
                <CustomText
                  cursor='pointer'
                  text={`Show Less`}
                  onClick={() => setShowMore(true)}
                  fontSize='16px'
                  fontWeight='700'
                  color='white'
                />
                <CustomImage
                  image={ARROW_UP_ICON}
                  width='12px'
                  height='7px'
                />
              </>
          }
        </FlexBox>
        {
          !showMore &&
          <FlexBox
            direction='column'
            gap="16px"
            marginTop='16px'
          >
            {
              WALLETS_DATA.map((wallet, index) => {
                return (
                  <FlexBox
                    key={index}
                    paddingTop='12px'
                    paddingBottom='11px'
                    paddingLeft='16px'
                    paddingRight='20px'
                    mdPaddingLeft='10px'
                    mdPaddingRight='10px'
                    mdPaddingTop='10px'
                    mdPaddingBottom='10px'
                    gap='26px'
                    mdDirection='row'
                    justifyContent='start'
                    onClick={handleConnectWallet(wallet?.link)}
                    cursor='pointer'
                    mdJustifyContent='start'
                    alignItems='center'
                    borderRadius='3px'
                    bgImage='linear-gradient(94deg, rgba(255, 255, 255, 0.75) -57.59%, rgba(255, 255, 255, 0.34) 84.48%, rgba(255, 255, 255, 0.25) 116.04%)'
                  >
                    <CustomImage
                      width='36px'
                      height='45px'
                      image={wallet.image}
                    />
                    <CustomText
                      text={wallet.text}
                      fontSize='28px'
                      fontWeight='700'
                      color='white'
                      fontFamily='Inconsolata'
                    />

                  </FlexBox>
                )
              })
            }
          </FlexBox>
        }

      </ModalBody>
    </StyledModal>
  )
}

export default ConnectWalletModal