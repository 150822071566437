import React, { useContext, useEffect, useState } from 'react'
import { Container, PageWrapper } from '../../../styles/GlobalStyles'
import { FlexBox } from '../../../components/common/FlexBox'
import CustomText from '../../../components/common/CustomText'
import CustomBorderButton from '../../../components/common/CustomBorderButton'
import CustomButton from '../../../components/common/CustomButton'
import ClubCard from '../../../components/card/ClubCard'
import styled from 'styled-components'
import CustomLinkButton from 'components/common/CustomLinkButton'
import CreateClubModal from 'components/modal/CreateClubModal'
import CreateClubSuccessModal from 'components/modal/CreateClubSuccessModal'
import CustomSearchInput from 'components/common/CustomSearchInput'
import axios from 'axios'
import { GET_CLUBS_API } from 'constants/api.constants'
import { getClubs } from 'api/api'
import ClubRegisterModal from 'components/modal/ClubRegisterModal'
import { useWalletConnect } from 'hooks/WalletConnect'
import { GlobalProvider } from 'App'

const CommunityClub = () => {

  const [showModal, setShowModal] = useState(false)
  const [clubs, setClubs] = useState<ClubType[]>([])
  const [displayClubs, setDisplayClubs] = useState<ClubType[]>([])
  const [search, setSearch] = useState<string>("");
  const [clubInputData, setClubInputData] = useState<ClubType>({
    id: '',
    club_name: '',
    description: '',
    image: '',
    website: '',
    instagram: '',
    twitter: '',
    discord: '',
    location: '',
    entries: {},
    organizer: ''
  }
  )
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [showRegisterModal, setShowRegisterModal] = useState(false)
  const [activeRegisterClub, setActiveRegisterClub] = useState<ClubType>();
  const { myWalletAddress } = useWalletConnect()
  const { myNFTs, loading } = useContext(GlobalProvider);
  
  const getClubsData = async () => {
    const clubs = await getClubs();
    console.log("clubs", clubs)
    setClubs(clubs)
    setDisplayClubs(clubs)
  };

  useEffect(() => {
    let filtered_arr = clubs.filter(item => item?.club_name.toLowerCase().includes(search.toLocaleLowerCase()));
    setDisplayClubs(filtered_arr)
  }, [search])

  useEffect(() => {
    getClubsData()
  }, [])

  useEffect(() => {
    if (myWalletAddress !== undefined) {
      setClubInputData((prev) => ({
        ...prev,
        organizer: myWalletAddress
      }));
    }
  }, [myWalletAddress])

  useEffect(() => {
  }, [clubInputData])
  return (
    <PageWrapper>
      <Container
        maxWidth='1380px'
        paddingLeft='17px'
        paddingRight='17px'
        mdPaddingTop='41px'
      >
        <CustomText
          text={`Clubs`}
          height="40px"
          color="black"
          fontSize='50px'
          fontWeight='700'
          mdFontSize='38px'
        />
        <CustomText
          text="Do you own a business, project, DAO, or want to start something of your own?"
          fontSize='18px'
          fontWeight='400'
          color='black'
          marginTop='20px'
          mdMarginTop='40px'
          mdFontSize='16px'
        />
        <CustomText
          text="Utilize Clubs to promote, network and grow your own clique. "
          fontSize='18px'
          fontWeight='400'
          color='black'
          marginTop='11px'
          mdMarginTop='20px'
          mdFontSize='16px'
        />

        <FlexBox justifyContent='start' mdJustifyContent='center' mdAlignItems='center'>
          <CustomButton
            text="Create a club"
            bgColor='#282828'
            width='171px'
            height='42px'
            fontSize='16px'
            fontWeight='600'
            marginTop='30px'
            borderRadius='3px'
            color='white'
            smWidth='171px'
            smHeight='42px'
            marginBottom='34px'
            onClick={() => setShowModal(true)}
            disabled={myNFTs.length === 0}
          />
        </FlexBox>
        <CustomSearchInput
          input={search}
          setInput={setSearch}
          placeholder={`Search Club`}
        />


        <FlexBox maxWidth='1345px' gap="20px" flexWrap='wrap' marginTop='73px' mdAlignItems='center' mdJustifyContent='center'>
          {
            displayClubs && displayClubs.length > 0 && displayClubs.map((item: ClubType, o) => {
              return (
                <>
                  <ClubCard
                    key={o}
                    data={item}
                    setShowRegisterModal={setShowRegisterModal}
                    setActiveRegisterClub={setActiveRegisterClub}
                  />
                </>
              )
            })
          }
        </FlexBox>

      </Container>
      {
        showModal &&
        <CreateClubModal
          show={showModal}
          onClose={() => setShowModal(false)}
          setShowSuccessModal={setShowSuccessModal}
          clubInputData={clubInputData}
          getClubsData = {getClubsData}
          setClubInputData={setClubInputData}
        />
      }

      {
        showSuccessModal &&
        <CreateClubSuccessModal
          show={showSuccessModal}
          onClose={() =>{
            setShowSuccessModal(false);
            getClubsData()
          }}
          clubInputData={clubInputData}
        />
      }

      {
        showRegisterModal && activeRegisterClub &&
        <ClubRegisterModal
          show={showRegisterModal}
          onClose={() =>{
            setShowRegisterModal(false)
          }}
          data={activeRegisterClub}
          getClubsData = {getClubsData}
        />
      }

    </PageWrapper>
  )
}

export default CommunityClub