import CustomText from 'components/common/CustomText'
import { TERM_CONDITIONS_DATA } from 'constants/document'
import React from 'react'
import { Container, PageWrapper } from 'styles/GlobalStyles'

const TermsCondition = () => {
    return (
        <PageWrapper>
            <Container maxWidth='1101px' paddingLeft='25px' paddingRight='25px'>
                <CustomText
                    text="Terms & Conditions"
                    fontSize='50px'
                    fontWeight='700'
                    color='black'
                    mdFontSize='38px'
                    textAlign='center'
                    mdLineHeight='120%'
                    marginBottom='67px'
                />
                {
                    TERM_CONDITIONS_DATA.map((item, index) => {
                        return (
                            <div key={index}>
                                {
                                    item.title !== "" &&
                                    <div>{item.title} </div>
                                }
                                <div>{item.content}</div>
                                <br />
                            </div>
                        )
                    })
                }
            </Container>
        </PageWrapper>
    )
}

export default TermsCondition
