import { useEffect, useState } from 'react'
import { useMedia } from 'react-use'
import axios from 'axios';
import styled from 'styled-components';

import { BomberAsset, BomberImage, BomberNFT, BomberNFTsList, ExplorerFiltering, ExplorerFilteringSelection } from './index.styled';
import CustomButton from '../../components/common/CustomButton'
import { FlexBox } from '../../components/common/FlexBox'
import ExplorerNFTCard from 'components/pages/explorer/ExplorerNFTCard';
import CustomText from 'components/common/CustomText';
import CustomInput from 'components/common/CustomInput';
import { GET_NFT_ASSET_API, NFT_LISTING_API } from 'constants/api.constants';

import { BOMBER_NFT_DATA, NFT_FILTER_DATA } from 'constants/document';

import CustomSearchInput from 'components/common/CustomSearchInput';
import { MAIN_PROJECT_ID } from 'constants/_main.constant';
import InfiniteScroll from "react-infinite-scroll-component";

const ExplorerOverview = () => {
    const [bomberNFTs, setBomberNFTs] = useState([]);
    const [filterNFTs, setFilterNFTs] = useState([]);
    const [displayNfts, setDisplayNfts] = useState([]);
    const [search, setSearch] = useState<string>("");
    const [activeFilters, setActiveFilters] = useState({});
    const [checked1, setChecked1] = useState<boolean>(false);
    const [checked2, setChecked2] = useState<boolean>(false);
    const isMobile = useMedia('(max-width: 768px)');

    // get all nfts listing of bomber collection
    // const getNFTListing = async () => {
    //     try {
    //         const nftData = []; // Initialize an empty array to store the results

    //         for (let i = 1; i <= 16; i++) {
    //             const response = await axios.get(NFT_LISTING_API + i, {
    //                 headers: {
    //                     project_id: MAIN_PROJECT_ID,
    //                     'Content-Type': 'application/json',
    //                 },
    //             });

    //             // Use Promise.all to fetch data for all items concurrently
    //             const nftDataPromises = response.data.map(async (item) => {
    //                 const res = await axios.get(GET_NFT_ASSET_API + item.asset);
    //                 return res.data;
    //             });

    //             // Wait for all promises to resolve for this iteration
    //             const nftDataBatch = await Promise.all(nftDataPromises);

    //             // Add the batch of data to the nftData array
    //             nftData.push(...nftDataBatch);
    //         }

    //         console.log("nftData", nftData)
    //         setBomberNFTs(nftData);
    //         setFilterNFTs(nftData);
    //     } catch (error) {
    //         console.error("Error fetching data:", error);
    //     }
    // };
    const getAttributes = () => {
        let attributes = {
            "Background": [],
            "Clothes": [],
            "Headwear": [],
            "Skins": [],
            "Mouth": [],
            "Eyes": []
        }
        for (let i = 0; i < BOMBER_NFT_DATA.length; i++) {
            if (BOMBER_NFT_DATA[i].onchain_metadata["Eyes"]) {
                if (attributes["Eyes"].indexOf(BOMBER_NFT_DATA[i].onchain_metadata["Eyes"]) === -1) {
                    attributes["Eyes"].push(BOMBER_NFT_DATA[i].onchain_metadata["Eyes"])
                }
            }
            if (BOMBER_NFT_DATA[i].onchain_metadata["Skins"]) {
                if (attributes["Skins"].indexOf(BOMBER_NFT_DATA[i].onchain_metadata["Skins"]) === -1) {
                    attributes["Skins"].push(BOMBER_NFT_DATA[i].onchain_metadata["Skins"])
                }
            }
            if (BOMBER_NFT_DATA[i].onchain_metadata["Background"]) {
                if (attributes["Background"].indexOf(BOMBER_NFT_DATA[i].onchain_metadata["Background"]) === -1) {
                    attributes["Background"].push(BOMBER_NFT_DATA[i].onchain_metadata["Background"])
                }
            }
            if (BOMBER_NFT_DATA[i].onchain_metadata["Clothes"]) {
                if (attributes["Clothes"].indexOf(BOMBER_NFT_DATA[i].onchain_metadata["Clothes"]) === -1) {
                    attributes["Clothes"].push(BOMBER_NFT_DATA[i].onchain_metadata["Clothes"])
                }
            }
            if (BOMBER_NFT_DATA[i].onchain_metadata["Headwear"]) {
                if (attributes["Headwear"].indexOf(BOMBER_NFT_DATA[i].onchain_metadata["Headwear"]) === -1) {
                    attributes["Headwear"].push(BOMBER_NFT_DATA[i].onchain_metadata["Headwear"])
                }
            }
            if (BOMBER_NFT_DATA[i].onchain_metadata["Mouth"]) {
                if (attributes["Mouth"].indexOf(BOMBER_NFT_DATA[i].onchain_metadata["Mouth"]) === -1) {
                    attributes["Mouth"].push(BOMBER_NFT_DATA[i].onchain_metadata["Mouth"])
                }
            }
        }
    }

    const updateActiveFilters = (type: string, value: string) => {
        if (value === "") {
            // Create a copy of the activeFilters object
            const temp = { ...activeFilters };

            // Delete the property with the specified type
            delete temp[type];

            // Update the state with the modified object
            setActiveFilters(temp);
        } else {
            setActiveFilters((prevFilters) => ({
                ...prevFilters,
                [type]: value,
            }));
        }
    };
    useEffect(() => {
        let filtered_arr = BOMBER_NFT_DATA.filter(item => item?.onchain_metadata?.name.toLowerCase().includes(search.toLocaleLowerCase()));

        let filteredArray = filtered_arr.filter(item => {
            return Object.entries(activeFilters).every(([key, value]) => {
                return item.onchain_metadata?.[key] === value;
            });
        });

        setFilterNFTs(filteredArray);

    }, [search, activeFilters]);

    useEffect(() => {
        if (checked1) {
            let filtered_arr = BOMBER_NFT_DATA.filter(item => item.onchain_metadata["Special Bombers"] === "1/1");
            setDisplayNfts(filtered_arr)
            setFilterNFTs(filtered_arr)
        } else {
            setDisplayNfts(BOMBER_NFT_DATA);
            setFilterNFTs([...BOMBER_NFT_DATA.slice(0, filterNFTs.length + 20)]);
        }
    }, [checked1])

    const getDisplayNfts = () => {
        setDisplayNfts(BOMBER_NFT_DATA);
        setFilterNFTs([...BOMBER_NFT_DATA.slice(0, filterNFTs.length + 20)]);
    };
    useEffect(() => {
        // getAttributes();
        getDisplayNfts()
    }, []);
    return (
        <>
            <InfiniteScroll
                dataLength={filterNFTs.length} //This is important field to render the next data
                next={getDisplayNfts}
                hasMore={filterNFTs.length < displayNfts.length ? true : false}
                loader={<h4>Loading...</h4>}
            >
                <FlexBox marginTop='50px'>
                    <CustomSearchInput
                        input={search}
                        setInput={setSearch}
                        placeholder={`Search Asset`}
                    />
                </FlexBox>
                {/** */}
                <FlexBox justifyContent='space-between' marginTop='62px'>
                    {
                        !isMobile &&
                        <ExplorerFiltering>
                            <FlexBox
                                justifyContent='space-between'
                                alignItems='center'
                                mdDirection='row'
                                mdJustifyContent='space-between'
                            >
                                <CustomText
                                    text={`Show 1/1s only`}
                                    fontSize='16px'
                                    fontWeight='400'
                                />
                                <CustomInput
                                    type='checkbox'
                                    height='24px'
                                    maxWidth='24px'
                                    checked={checked1}
                                    onChange={() => setChecked1(!checked1)}
                                />
                            </FlexBox>
                            <FlexBox
                                justifyContent='space-between'
                                alignItems='center'
                                marginTop='36.69px'
                                mdDirection='row'
                                mdJustifyContent='space-between'
                            >
                                <CustomText
                                    text={`Listed in Market`}
                                    fontSize='16px'
                                    fontWeight='400'
                                />
                                <CustomInput
                                    type='checkbox'
                                    height='24px'
                                    maxWidth='24px'
                                    checked={checked2}
                                    onChange={() => setChecked2(!checked2)}
                                />
                            </FlexBox>
                            <FlexBox
                                direction='column'
                                gap="24px"
                                marginTop='52px'
                            >
                                {
                                    Object.keys(NFT_FILTER_DATA).map((type, j) => {
                                        return (
                                            <div>
                                                <CustomText
                                                    text={type}
                                                    fontSize='16px'
                                                    fontWeight='400'
                                                />

                                                <ExplorerFilteringSelection
                                                    key={j}
                                                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                        updateActiveFilters(type, e.target.value);
                                                    }}
                                                >
                                                    <option value="">Select</option>
                                                    {NFT_FILTER_DATA[type].map((option: string, s: number) => (
                                                        <option key={s} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </ExplorerFilteringSelection>
                                            </div>
                                        )
                                    })
                                }

                            </FlexBox>
                        </ExplorerFiltering>
                    }


                    <BomberNFTsList>
                        {
                            filterNFTs.map((item, index) => {
                                if (item.asset_name === null) return;
                                return (
                                    <ExplorerNFTCard
                                        key={index}
                                        image={item.onchain_metadata?.image.replace(
                                            "ipfs://",
                                            "https://image-optimizer.jpgstoreapis.com/"
                                        )}
                                        name={item.onchain_metadata?.name}
                                    />
                                )
                            })
                        }
                    </BomberNFTsList>



                </FlexBox>
            </InfiniteScroll >
        </>
    )
}

export default ExplorerOverview