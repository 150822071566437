import { FlexBox } from 'components/common/FlexBox'
import ExplorerNFTCard from 'components/pages/explorer/ExplorerNFTCard'
import { NFT_ATTRIBUTE_IMAGES } from 'constants/document'
import React, { useState } from 'react'
import styled from 'styled-components'
const ExplorerAttributesStyle = styled.div`
    max-width: 1089px;
    width: 100%;
    margin: auto;
    margin-top: 66px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`
const ExplorerAttributesSelect = styled.select`
    margin: auto;
    max-width: 539px;
    width: 100%;
    height: 52px;
    border: 1px solid black;
    border-radius: 3px;
    padding-left: 38px;
    background-image: url('/assets/images/icons/select_down.svg');
    background-repeat: no-repeat;
    background-position: right 12px center;
    appearance: none; /* Remove default styles (not supported in all browsers) */
    -webkit-appearance: none;
    ::-ms-expand {
      display: none; /* Hide arrow icon in IE/Edge */
    }
`


const ExplorerAttributes = () => {
    const [activeFilter, setActiveFilter] = useState<string>("Background")
    return (
        <ExplorerAttributesStyle>
            <ExplorerAttributesSelect onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setActiveFilter(e.target.value)
            }}>
                {
                    Object.keys(NFT_ATTRIBUTE_IMAGES).map((sub: string, index: number) => {
                        return (
                            <option key={index}>{sub}</option>
                        )
                    })
                }
            </ExplorerAttributesSelect>

            <FlexBox
                flexWrap='wrap'
                maxWidth='1089px'
                gap="70px"
                marginTop='60px'
                justifyContent='center'
                alignItems='center'
            >
                {
                    activeFilter && NFT_ATTRIBUTE_IMAGES && NFT_ATTRIBUTE_IMAGES[activeFilter] && NFT_ATTRIBUTE_IMAGES[activeFilter].map((nft, index) => {
                        return (
                            <ExplorerNFTCard
                                image={nft.image}
                                name={nft.name}
                            />
                        )
                    })
                }
            </FlexBox>
        </ExplorerAttributesStyle>
    )
}

export default ExplorerAttributes
