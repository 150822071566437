import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { FlexBox } from '../common/FlexBox';
import CustomText from '../common/CustomText';
import CustomButton from '../common/CustomButton';
import CustomImage from 'components/common/CustomImage';
import { GREEN_SUCCESS_ICON } from 'constants/image.constants';


const StyledModal = styled(Modal)`
  .modal-dialog{
    margin: auto;
    max-width: 620px;
    width: 100%;
    
    background: transparent;
    border-radius: 16px;
    @media screen and (max-width: 550px) {
      max-width: 100%;
      height: 100vh;
    }
  }
  .modal-header{
    border-bottom: none;
  }
  .btn-close{
    --bs-btn-close-bg: url('/assets/images/icons/close.svg')
  }
  .connect-success-content {
    background: #202020;
    box-shadow: 0px 5px 50px 9px #242424;
    border-radius: 3px;
    width: 100%;
    overflow: hidden;
    border: none;
    height: 540px;
    @media screen and (max-width: 550px) {
      height: 100%;
    }
  }
`;

const ModalBody = styled(Modal.Body)`
  &.modal-body{
  }
`

interface Props {
    show: boolean;
    onClose: () => void;
    message: string;
}
const ConnectSuccessModal = ({ show, onClose, message }: Props) => {
    return (
        <StyledModal show={show} onHide={onClose} centered contentClassName="connect-success-content">


            <Modal.Header closeButton>

            </Modal.Header>
            <ModalBody>
                <FlexBox
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                    mdJustifyContent='start'
                    mdAlignItems='start'
                >
                    <CustomImage
                        image={GREEN_SUCCESS_ICON}
                        width='68px'
                        height='68px'
                        mdHeight='43px'
                        mdWidth='43px'
                    />
                    
                    <CustomText
                        fontFamily='Inconsolata'
                        text={`Success!`}
                        color='white'
                        fontSize='38px'
                        textAlign='center'
                        fontWeight='700'
                        marginTop='65.5px'
                        mdMarginTop='17px'
                    />
                    <CustomText
                        text={message}
                        marginTop='16px'
                        fontSize='16px'
                        fontWeight='400'
                        mdMarginTop='6px'
                        mdFontSize='16px'
                        fontFamily='Open Sans'
                        color='white'
                    />
                    <CustomButton
                        text="Close"
                        width='328px'
                        height="48px"
                        fontSize='18px'
                        fontWeight='600'
                        boxShadow='0px 1px 4px -2px rgba(255, 255, 255, 0.50)'
                        onClick={() =>{
                            onClose();
                        }}
                        border = "#70B970 1.75px solid"
                        borderRadius='3px'
                        marginTop='90.5px'
                    />
                </FlexBox>
                
            </ModalBody>
        </StyledModal>
    )
}

export default ConnectSuccessModal