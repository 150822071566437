import ComingSoon from 'components/ComingSoon';
import BountiesCard from 'components/card/BountiesCard';
import CustomButton from 'components/common/CustomButton';
import CustomInput from 'components/common/CustomInput';
import CustomText from 'components/common/CustomText'
import { FlexBox } from 'components/common/FlexBox'
import SocialImageLink from 'components/common/SocialImageLink';
import { DISCORD_BLACK_ICON, TWITTER_ICON, TWITTER_WHITE_ICON } from 'constants/image.constants';
import { CRASHR_DISCORD_URL, CRASHR_TWITTER_URL } from 'constants/url.constant';
import { useState } from 'react'
import styled from 'styled-components';
import { Container, PageWrapper } from 'styles/GlobalStyles'

interface BountiesTabstyleTypes {
    active?: boolean;
    borderRadius?: string;
}

const BountiesTab = styled.div<BountiesTabstyleTypes>`
    cursor: pointer;
    width:50%;
    height: 49px;
    background: ${(props) => props.active ? '#282828' : '#e7e7e7'};
    color: ${(props) => props.active ? 'white' : 'black'};
    border-radius: ${(props) => props.borderRadius};
    border: 1px #282828 black;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    height: 49px;
    display: flex;
    align-items: center;
    justify-content: center;
`
const TAB_LIST = [
    {
        text: "Active",
        borderRadius: "4px 0px 0px 4px"
    },
    {
        text: "Completed",
        borderRadius: "0px 4px 4px 0px"
    },
]

const Bounties = () => {
    // const [activeTab, setActiveTab] = useState("Active")
    return (
        <PageWrapper>
            <Container
                maxWidth='1206px'
                paddingLeft='30px'
                paddingRight='30px'
            >
                <ComingSoon />
                {/* <CustomText
                    text={`Bounties`}
                    height="40px"
                    color="black"
                    fontSize='50px'
                    fontWeight='700'
                    mdFontSize='38px'
                />
                

                <FlexBox
                    maxWidth='598px'
                    marginTop='44px'
                    mdDirection='row'
                    mdMarginTop='19px'
                >
                    {
                        TAB_LIST.map((tab, index) => {
                            return (
                                <BountiesTab
                                    key={index}
                                    active={activeTab === tab.text}
                                    onClick={() => {
                                        setActiveTab(tab.text)
                                    }}
                                    borderRadius={tab.borderRadius}
                                >
                                    {
                                        tab.text
                                    }
                                </BountiesTab>
                            )
                        })
                    }
                </FlexBox>

                
                <FlexBox>
                    <FlexBox
                        marginTop='79px'
                        justifyContent='center'
                        gap="63px"
                        maxWidth='1200px'
                        flexWrap='wrap'
                        mdMarginTop='36px'
                    >
                        <BountiesCard />
                        <BountiesCard />
                        <BountiesCard />
                        <BountiesCard />
                    </FlexBox>
                </FlexBox> */}
            </Container>
        </PageWrapper>
    )
}

export default Bounties
