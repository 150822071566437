export const BRAND_IMAGE = "/assets/images/brand.png"
export const CRASHR_BRAND_IMAGE = "/assets/images/crashr_brand.svg"


export const CARDANO_SYMBOL_IMAGE = '/assets/images/cardano.svg';

export const DEFAULT_NFT_IAMGE = "/assets/images/_default_nft.svg"

/*** home page */
export const BOMBERS_HORIZONTAL_IMAGE = "/assets/images/Bombers_Logo_Horizontal.png";
export const HOME_BG_IAMGE = "/assets/images/background/bg_home.svg";


/*** leaderboards */
export const VOTE_UP_IMAGE = "/assets/images/icons/vote-up.svg";
export const VOTE_DOWN_IMAGE = "/assets/images/icons/vote-down.svg";
export const BOMB_ICON = "/assets/images/icons/bomb.svg";


export const TEST_EXP_IMAGE = "/assets/images/explor-ico.svg";

export const DEFAULT_AVATAR_IMAGE = "/assets/images/default_avatar.svg"

/** icons */
// social icons
export const DISCORD_BLACK_ICON = "/assets/images/icons/social/discord_black.svg";
export const DISCORD_WHITE_ICON = "/assets/images/icons/social/discord_white.svg";
export const TWITTER_ICON = "/assets/images/icons/social/twitter.svg";
export const TWITTER_WHITE_ICON = "/assets/images/icons/social/twitter_white.svg";
export const CONNECT_ICON = "/assets/images/icons/social/connect.svg";
export const CRASHR_BLACK_ICON = "/assets/images/icons/social/crashr_black.svg";


export const CLOCK_ICON = "/assets/images/icons/clock.svg";
export const SUCCESS_ICON = "/assets/images/icons/success.svg";
export const GREEN_SUCCESS_ICON = "/assets/images/icons/green_success.svg";
export const FAIL_ICON = "/assets/images/icons/fail.svg";

export const INSTAGRAM_ICON = "/assets/images/icons/instagram.png";

export const CRASHR_ICON = "/assets/images/icons/crashr.png";

export const LOCATION_ICON = "/assets/images/icons/location.svg";
export const CALENDAR_ICON = "/assets/images/icons/calendar.svg"

export const FOOTER_TWITTER_ICON = "/assets/images/icons/twitter_small.svg";
export const FOOTER_DISCORD_ICON = "/assets/images/icons/discord_small.svg"

export const LOCK_ICON = "/assets/images/icons/lock.svg";

export const WALLET_ICON = "/assets/images/icons/wallet.svg"

export const ARROW_DOWN_ICON = "/assets/images/icons/arrow_down.svg";
export const ARROW_UP_ICON = "/assets/images/icons/arrow_up.svg";

export const WEBSITE_ICON = "/assets/images/icons/website.svg";

export const UPLOAD_ICON = "/assets/images/icons/upload.svg";

/** background */
export const CONGRATS_IMAGE = "/assets/images/background/bg_congrats.svg";

export const CRASHR_LOGO_HORIZONTAL = "/assets/images/CRASHR_Logo_Horizontal.svg"
export const BG_CALENDAR_IAMGE = "/assets/images/background/bg_calendar.svg"


export const DEFAULT_PROFILE_IMAGE = "/assets/images/default_profile.svg"



export const EXTERNAL_SHARE_IMAGE = "/assets/images/external.svg";
export const SPINNING_IMAGE = "/assets/images/loading-loading-forever.gif";
export const SUCCESS_IMAGE = "/assets/images/success.svg";
export const ALERT_RING_IMAGE = "/assets/images/alert_ring.svg";


export const DEFAULT_AVATAR_ICONS = [
    '/assets/images/avatars/default_avatar1.png',
    '/assets/images/avatars/default_avatar2.png',
    '/assets/images/avatars/default_avatar3.png',
    '/assets/images/avatars/default_avatar4.png',
]