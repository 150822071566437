import React, { useState } from 'react'
import styled from 'styled-components'
import CustomText from '../../../components/common/CustomText'
import { Container, PageWrapper } from '../../../styles/GlobalStyles'
import { FlexBox } from '../../../components/common/FlexBox'
import CustomButton from '../../../components/common/CustomButton'
// import DatePicker from "react-datepicker";
const HostEventStyle = styled.div`
  
`

const CustomInput = styled.input`
  width: 100%;
  height: 79px;
  border-radius: 10px;
  padding: 16px 75px 14px 32px;
  color: #787878;
  font-size: 24px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
`

const CustomTextArea = styled.textarea`
  width: 100%;
  height: 198px;
  border-radius: 10px;
  padding: 16px 75px 14px 32px;
  color: #787878;
  font-size: 24px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
`


const HostEvent = () => {
  const [startDate, setStartDate] = useState(new Date());
  return (
    <PageWrapper>
      <Container maxWidth='706px'>
        <HostEventStyle>
          <CustomText
            text="Host Event"
            fontSize='36px'
            lineHeight='41px'
            fontWeight='700'
            color='black'
            justifyContent='center'
            textAlign='center'
          />
          <FlexBox direction='column' marginTop='57px'>
            <FlexBox justifyContent='space-between'>
              <CustomText
                text="<string>* </string>&nbsp;Header"
                fontSize='24px'
                lineHeight='24px'
                fontWeight='700'
              />
              <CustomText
                text="Max. 25 Characters"
                fontSize='14px'
                lineHeight='24px'
                fontWeight='700'
                color="#F73737"
              />
            </FlexBox>
            <CustomInput
              placeholder='First thing users will see! Ex: Meet up in Miami'
            />
          </FlexBox>

          <FlexBox direction='column' marginTop='14px'>
            <FlexBox justifyContent='space-between'>
              <CustomText
                text="<string>* </string>&nbsp;Description"
                fontSize='24px'
                lineHeight='24px'
                fontWeight='700'
              />
              <CustomText
                text="Max. 60 Characters"
                fontSize='14px'
                lineHeight='24px'
                fontWeight='700'
                color="#F73737"
              />
            </FlexBox>
            <CustomTextArea
              placeholder='Any other notes?'
            />
          </FlexBox>

          <FlexBox direction='column' marginTop='14px'>
            <CustomText
              text="<string>* </string>&nbsp;Event Date/Time"
              fontSize='24px'
              lineHeight='24px'
              fontWeight='700'
            />
            <CustomInput
              placeholder='Location of the party'
            />
          </FlexBox>


          <FlexBox direction='column' marginTop='14px'>
            <CustomText
              text="<string>* </string>&nbsp;Location"
              fontSize='24px'
              lineHeight='24px'
              fontWeight='700'

            />
            <CustomInput
              placeholder='Location of the party'
            />
            <CustomText
              text="or"
              color="#f73737"
              fontWeight='700'
              lineHeight='24px'
              letterSpacing='1.60px'
              fontSize='32px'
              marginTop='9px'
              marginBottom='9px'
            />

            <CustomInput
              placeholder='Link to the online party'
            />
          </FlexBox>


          <CustomButton
            text='Post Event'
            width='209px'
            height='48px'
            bgColor='#FF7A00'
            fontSize='20px'
            fontWeight='700'
            lineHeight='24px'
            marginTop='49px'
          />


        </HostEventStyle>
      </Container>
    </PageWrapper>
  )
}

export default HostEvent