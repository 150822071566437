import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { FlexBox } from '../common/FlexBox';
import CustomText from '../common/CustomText';
import CustomButton from '../common/CustomButton';
import CustomInput from 'components/common/CustomInput';
import CustomTextArea from 'components/common/CustomTextarea';
import { useMedia } from 'react-use';
import { useState } from 'react';
import CustomImage from 'components/common/CustomImage';
import { CALENDAR_ICON, CLOCK_ICON, CONNECT_ICON, DISCORD_BLACK_ICON, LOCATION_ICON, TWITTER_ICON } from 'constants/image.constants';
import SocialImageLink from 'components/common/SocialImageLink';
import axios from 'axios';
import { useWalletConnect } from 'hooks/WalletConnect';
import { infoAlert } from 'hooks/alert';
import { timestampToDayTime } from 'hooks/date';


const StyledModal = styled(Modal)`
  .modal-dialog{
    margin: auto;
    max-width: 1351px;
    width: 100%;
    
    background: transparent;
    border-radius: 16px;
    
    @media screen and (max-width: 550px) {
      max-width: 100%;

    }
  }
  .modal-header{
    border-bottom: none;
    align-items: start;
    &.btn-close{
        font-size: 40px !important;
    }

  }
  .connect-success-content {
    background: white;
    border-radius: 3px;
    width: 100%;
    overflow: hidden;
    border: none;
    
    @media screen and (max-width: 550px) {
    }
  }
`;

const ModalBody = styled(Modal.Body)`
    padding: 18px 47px 28px 28px; 
    &.modal-body{
    }
`

interface Props {
    show: boolean;
    onClose: () => void;
    data: any;
    getEventsData: any;
}
const EventRegisterModal = ({ show, onClose, data, getEventsData }: Props) => {
    const [step, setStep] = useState<number>(1);
    const { myWalletAddress } = useWalletConnect()
    const isMobile = useMedia('(max-width: 550px)');
    const registerEvent = async () => {
        const url = "https://h5a8xyp2h2.execute-api.us-west-2.amazonaws.com/test";
        const reqData = {
            id: data.id,
            entries: myWalletAddress
        }

        try {
            const response = await axios.post(url, JSON.stringify(reqData));
            if (response.status === 200) {
                getEventsData();
                onClose()
                infoAlert("You successfully registered at the event!")
            }
        } catch (error) {
            console.error("registerEvent err", error);
        }
    }
    return (
        <StyledModal show={show} onHide={onClose} centered contentClassName="connect-success-content">


            <Modal.Header closeButton>
            </Modal.Header>
            <ModalBody>
                <CustomText
                    fontFamily='Inconsolata'
                    fontSize='67px'
                    fontWeight='700'
                    text={data.event_name && data.event_name}
                    lineHeight='100%'
                    mdFontSize='21px'
                />
                <CustomText
                    text={`Hosted by ${data.organizer && data.organizer.slice(0, 10)}...`}
                    marginTop='28px'
                    fontSize='28px'
                    fontWeight='400'
                    mdFontSize='14px'
                    mdMarginTop='33px'

                />
                {
                    isMobile &&
                    <CustomText
                        text={`${data.entries.length} people going`}
                        color="#58c8be"
                        fontSize='36px'
                        fontWeight='700'
                        className='text-nowrap'
                    />
                }
                <FlexBox marginTop='71px' direction='column' gap="18px" mdGap='8px' mdMarginTop='33px'>
                    <FlexBox justifyContent='start' gap="16px" alignItems='center' mdDirection='row' mdJustifyContent='start'>
                        <CustomImage
                            image={CLOCK_ICON}
                            width='58px'
                            height='58px'
                            mdHeight='19px'
                            mdWidth='19px'
                        />
                        <CustomText
                            text={`${data.event_time && timestampToDayTime(data.event_time)}`}
                            fontWeight='400'
                            fontSize='28px'
                            mdFontSize='14px'
                        />
                    </FlexBox>
                    <FlexBox justifyContent='start' gap="16px" alignItems='center' mdDirection='row' mdJustifyContent='start'>
                        <CustomImage
                            image={LOCATION_ICON}
                            width='58px'
                            height='58px'
                            mdHeight='19px'
                            mdWidth='19px'
                        />
                        <CustomText
                            text={`${data.event_location && data.event_location}`}
                            fontWeight='400'
                            fontSize='28px'
                            mdFontSize='14px'
                        />
                    </FlexBox>
                </FlexBox>


                <CustomText
                    text={`${data.description && data.description}`}
                    fontSize='28px'
                    fontWeight='400'
                    marginTop='90px'
                    mdMarginTop='32px'
                />
                <FlexBox
                    justifyContent='space-between'
                    alignItems='center'
                    marginTop='24px'
                >
                    {
                        !isMobile &&
                        <CustomText
                            text={`${data.entries.length} people going`}
                            color="#58c8be"
                            fontSize='36px'
                            fontWeight='700'
                            className='text-nowrap'
                        />
                    }

                    <FlexBox
                        justifyContent='start'
                        paddingLeft='74px' marginTop='44px' gap='25px'
                        width='auto'
                        mdDirection='row'
                    >
                        {
                            data.twitter && data.twitter !== "" &&
                            <SocialImageLink
                                image={TWITTER_ICON}
                                width='63px'
                                height='63px'
                                mdWidth='27px'
                                mdHeight='24px'
                                link={data.twitter}
                            />
                        }
                        {
                            data.instagram && data.instagram !== "" &&
                            <SocialImageLink
                                image={CONNECT_ICON}
                                width='63px'
                                height='63px'
                                mdWidth='27px'
                                mdHeight='24px'
                                link={data.instagram}
                            />
                        }
                        {
                            data.discord && data.discord !== "" &&
                            <SocialImageLink
                                image={DISCORD_BLACK_ICON}
                                width='63px'
                                height='63px'
                                mdWidth='27px'
                                mdHeight='24px'
                                link={data.discord}
                            />
                        }

                    </FlexBox>
                </FlexBox>
                <FlexBox
                    marginTop='76px'
                    justifyContent='end'
                    mdDirection='row-reverse'
                    mdJustifyContent='end'

                >

                    <CustomButton
                        width='407px'
                        height='65px'
                        fontSize='30px'
                        fontWeight='600'
                        smWidth='171px'
                        smHeight='42px'
                        smFontSize='16px'
                        disabled={data.organizer === myWalletAddress || data.entries.includes(myWalletAddress)}
                        onClick={() => {
                            registerEvent();
                        }}
                        text={`Register`}
                    />
                </FlexBox>
            </ModalBody>
        </StyledModal>
    )
}

export default EventRegisterModal