import styled from 'styled-components';
import { useMedia } from 'react-use';
import { memo } from 'react';

import { Container } from 'styles/GlobalStyles';
import CustomImage from 'components/common/CustomImage';
import CustomText from 'components/common/CustomText';
import { CRASHR_BRAND_IMAGE, CRASHR_LOGO_HORIZONTAL, DISCORD_WHITE_ICON, FOOTER_DISCORD_ICON, FOOTER_TWITTER_ICON, TWITTER_ICON, TWITTER_WHITE_ICON } from 'constants/image.constants';
import { FlexBox } from 'components/common/FlexBox';
import CustomLinkButton from 'components/common/CustomLinkButton';
import SocialImageLink from 'components/common/SocialImageLink';
import { CRASHR_DISCORD_URL, CRASHR_TWITTER_URL } from 'constants/url.constant';

const FooterWrapper = styled.footer`
  position: absolute;
  bottom: 0;
  width: 100%;
  color: black;
  background: #202020 !important;
  display: flex;
  justify-content: center;
`;

const Footer = memo(
  () => {
    const isMobile = useMedia('(max-width: 768px)');
    return (
      <FooterWrapper>
        <Container
          maxWidth='1382px'
          paddingLeft='31px'
          paddingRight='31px'
          paddingBottom='0px'
          paddingTop='0px'
          mdPaddingTop='44px'
          mdPaddingBottom='40px'
        >
          {

            <>
              <FlexBox
                paddingTop='52px'
                paddingLeft='10px'
                paddingBottom='63px'
                justifyContent='start'
                gap="191px"
                mdGap='27px'
                borderBottom='1px solid white'
                mdBorderBottom='none'
              >
                <FlexBox
                  maxWidth='366px'
                  direction='column'
                  gap="20px"
                  alignSelf='flex-end'

                >
                  <CustomImage
                    image={CRASHR_BRAND_IMAGE}
                    width='150px'
                    height='36px'
                  />
                  <CustomText
                    text={`Empowering users to craft their digital identities as they interact and trade.`}
                    fontSize='16px'
                    fontWeight='400'
                    color='white'
                    mdFontSize='13px'
                  />

                  <FlexBox width='auto' gap="24px" mdDirection='row' marginTop='43px' mdMarginTop='13px'>
                    <SocialImageLink
                      image={TWITTER_WHITE_ICON}
                      link = {CRASHR_TWITTER_URL}
                      width='69px'
                      height='40px'
                      mdWidth='47px'
                      mdHeight='27px'
                    />
                    <SocialImageLink
                      image={DISCORD_WHITE_ICON}
                      link = {CRASHR_DISCORD_URL}
                      width='51px'
                      height='40px'
                      mdWidth='47px'
                      mdHeight='27px'
                    />
                  </FlexBox>
                </FlexBox>
                <FlexBox gap="72px" mdGap='22px' flexWrap='wrap' mdDirection='row' mdJustifyContent='start' >
                  <FlexBox direction='column' gap="19px" width='auto' mdGap='8px' mdWidth='40%'>
                    <CustomText
                      text={`About`}
                      color='white'
                      fontSize='21px'
                      fontWeight='700'
                      bgColor='none'
                      width='min-content'
                      mdFontSize='18px'
                      fontFamily='Inconsolata'
                    />
                    <CustomLinkButton
                      text={`Crashr`}
                      link={`https://crashr.io/about`}
                      color='white'
                      fontSize='21px'
                      fontWeight='400'
                      bgColor='none'
                      hoverBgColor='none'
                      width='min-content'
                      smWidth='98px'
                      smHeight='32px'
                      justifyContent='start'
                      smFontSize='13px'
                      fontFamily='Inconsolata'

                    />
                    <CustomLinkButton
                      text={`Bombers`}
                      color='white'
                      fontSize='21px'
                      fontWeight='400'
                      bgColor='none'
                      hoverBgColor='none'
                      width='min-content'
                      smWidth='98px'
                      smHeight='32px'
                      justifyContent='start'
                      smFontSize='13px'
                      fontFamily='Inconsolata'
                    />
                  </FlexBox>
                  <FlexBox direction='column' gap="19px" width='auto' mdGap='8px' mdWidth='40%'>
                    <CustomText
                      text={`Voting`}
                      color='white'
                      fontSize='21px'
                      fontWeight='700'
                      bgColor='none'
                      width='min-content'
                      mdFontSize='18px'
                      fontFamily='Inconsolata'
                    />
                    <CustomLinkButton
                      text={`Create Vote`}
                      link={`https://crashr.io/raffle/create-dao`}
                      color='white'
                      fontSize='21px'
                      fontWeight='400'
                      bgColor='none'
                      hoverBgColor='none'
                      width='min-content'
                      justifyContent='start'
                      smFontSize='13px'
                      smWidth='98px'
                      smHeight='32px'
                      fontFamily='Inconsolata'
                    />
                    <CustomLinkButton
                      text={`All Voting`}
                      link={`https://crashr.io/voting`}
                      color='white'
                      fontSize='21px'
                      fontWeight='400'
                      bgColor='none'
                      hoverBgColor='none'
                      width='min-content'
                      justifyContent='start'
                      smFontSize='13px'
                      smWidth='98px'
                      smHeight='32px'
                      fontFamily='Inconsolata'
                    />
                  </FlexBox>
                  <FlexBox direction='column' gap="19px" width='auto' mdGap='8px'>
                    <CustomText
                      text={`Raffles`}
                      color='white'
                      fontSize='21px'
                      fontWeight='700'
                      bgColor='none'
                      width='min-content'
                      mdFontSize='18px'
                      fontFamily='Inconsolata'
                    />
                    <CustomLinkButton
                      text={`Create Raffle`}
                      link={`https://crashr.io/create-raffle`}
                      color='white'
                      fontSize='21px'
                      fontWeight='400'
                      bgColor='none'
                      hoverBgColor='none'
                      width='min-content'
                      justifyContent='start'
                      smFontSize='13px'
                      smWidth='98px'
                      smHeight='32px'
                      fontFamily='Inconsolata'
                    />
                    <CustomLinkButton
                      text={`All Raffles`}
                      link={`https://crashr.io/raffle`}
                      color='white'
                      fontSize='21px'
                      fontWeight='400'
                      bgColor='none'
                      hoverBgColor='none'
                      width='min-content'
                      justifyContent='start'
                      smFontSize='13px'
                      smWidth='98px'
                      smHeight='32px'
                      fontFamily='Inconsolata'
                    />
                  </FlexBox>

                </FlexBox>
              </FlexBox>

              {
                !isMobile &&
                <FlexBox
                  paddingTop='29px'
                  paddingBottom='59px'
                  justifyContent='end'
                  alignItems='center'
                  gap="10px"
                >
                  <CustomText
                    text={`© 2023 Crashr`}
                    color='white'
                    fontSize='14px'
                    fontWeight='400'
                  />
                  <CustomText
                    text={`|`}
                    color='white'
                    fontSize='14px'
                    fontWeight='400'
                  />

                  <CustomLinkButton
                    text={`Terms & Conditions`}
                    link='/term-conditions'
                    color='white'
                    fontSize='14px'
                    fontWeight='400'
                    bgColor='none'
                    hoverBgColor='none'
                    width='min-content'
                    height='14px'
                    borderRadius='0px'
                  />
                  <CustomText
                    text={`|`}
                    color='white'
                    fontSize='14px'
                    fontWeight='400'
                  />
                  <CustomLinkButton
                    text={`Privacy Policy`}
                    link='/privacy-policy'
                    color='white'
                    fontSize='14px'
                    fontWeight='400'
                    bgColor='none'
                    hoverBgColor='none'
                    width='min-content'
                  />
                </FlexBox>
              }
              {
                isMobile &&
                <>
                  <FlexBox mdMarginTop='42px'>
                    <CustomLinkButton
                      text={`Terms & Conditions`}
                      link='/term-conditions'
                      color='white'
                      fontSize='14px'
                      fontWeight='400'
                      bgColor='none'
                      hoverBgColor='none'
                      width='min-content'
                      borderRadius='0px'
                      smFontSize='13px'
                      justifyContent='start'
                      height="auto"
                    />

                    <CustomLinkButton
                      text={`Privacy Policy`}
                      link='/privacy-policy'
                      color='white'
                      fontSize='14px'
                      fontWeight='400'
                      bgColor='none'
                      hoverBgColor='none'
                      width='min-content'
                      smFontSize='13px'
                      justifyContent='start'
                      height='auto'
                    />
                  </FlexBox>
                  <CustomText
                    text={`© 2023 Crashr`}
                    color='white'
                    fontSize='14px'
                    fontWeight='400'
                    mdFontSize='13px'
                    mdMarginTop='22px'
                  />
                </>
              }
            </>
          }


        </Container>
      </FooterWrapper>
    );
  }
);

export default Footer;