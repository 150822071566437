import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { FlexBox } from '../common/FlexBox';
import CustomText from '../common/CustomText';
import CustomButton from '../common/CustomButton';
import CustomImage from 'components/common/CustomImage';
import { DEFAULT_AVATAR_IMAGE } from 'constants/image.constants';


const StyledModal = styled(Modal)`
  .modal-dialog{
    margin: auto;
    max-width: 840px;
    width: 100%;
    background: transparent;
    border-radius: 16px;
    
    @media screen and (max-width: 550px) {
      max-width: 100%;
      height: 100vh;
    }
  }
  .modal-header{
    border-bottom: none;
    align-items: start;
    &.btn-close{
        font-size: 40px !important;
    }

  }
  .connect-success-content {
    background: white;
    border-radius: 3px;
    width: 100%;
    overflow: hidden;
    border: none;
    height: 540px;
    
    @media screen and (max-width: 550px) {
      height: 100%;
    }
  }
`;

const ModalBody = styled(Modal.Body)`
    padding: 18px 47px 28px 28px; 
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media screen and (max-width: 768px) {
        justify-content: start;
        align-items: start;
    }
`

interface Props {
    show: boolean;
    onClose: () => void;
    clubInputData: ClubType;
}
const CreateClubSuccessModal = ({ show, onClose, clubInputData }: Props) => {

    return (
        <StyledModal show={show} onHide={onClose} centered contentClassName="connect-success-content">


            <Modal.Header closeButton>
            </Modal.Header>
            <ModalBody>
                <CustomText
                    text="Congratulations!"
                    fontSize='50.25px'
                    fontWeight='700'
                />
                <CustomText
                    text='Your club has been created.'
                    fontSize='16x'
                    fontWeight='400'
                    marginTop='15.75px'
                    mdMarginTop='0'
                />
                <FlexBox
                    border='1px solid black'
                    paddingRight='15.5px'
                    paddingTop='15.5px'
                    paddingBottom='15.5px'
                    paddingLeft='26px'
                    justifyContent='space-between'
                    maxWidth='385px'
                    marginTop='38.25px'
                    mdDirection='row'
                    mdPaddingRight='8px'
                    mdPaddingTop='8px'
                    mdPaddingBottom='8px'
                    mdPaddingLeft='8px'
                    mdMarginTop='19px'
                >
                    <FlexBox
                        justifyContent='start'
                        direction='column'

                    >
                        <CustomText
                            text={clubInputData && clubInputData.club_name}
                            fontSize='25px'
                            fontWeight='700'
                            marginTop='14px'
                            mdFontSize='21px'
                            mdMarginTop='15px'
                        />
                        <CustomText
                            text={clubInputData && clubInputData.website && clubInputData.website}
                            fontWeight='600'
                            fontSize='14px'
                            mdFontSize='13px'
                            marginTop='27.5px'
                            mdMarginTop='10px'
                        />
                        {/* <CustomText
                            text={clubInputData && clubInputData.description && clubInputData.description}
                            fontWeight='600'
                            fontSize='14px'
                            marginTop='14px'
                            mdMarginTop='10px'
                            mdFontSize='12px'
                            mdFontWeight='400'
                            mdLineHeight='100%'
                        /> */}
                        <CustomText
                            text={clubInputData && clubInputData.description && clubInputData.description}
                            
                            fontWeight='400'
                            fontSize='10.5px'
                            mdFontSize='12px'
                            mdLineHeight='130%'
                        />
                    </FlexBox>
                    <CustomImage
                        image={clubInputData && clubInputData.image === "" ? DEFAULT_AVATAR_IMAGE : clubInputData.image}
                        width='157px'
                        height='157px'
                        mdWidth='112px'
                        mdHeight='112px'
                        borderRadius='3px'
                    />
                </FlexBox>



                <FlexBox
                    mdDirection='row-reverse'
                    mdJustifyContent='end'
                    marginTop='36px'
                >
                    <CustomButton
                        text="Close"
                        width='300px'
                        height="48px"
                        fontSize='12px'
                        fontWeight='600'
                        onClick={onClose}
                    />
                </FlexBox>
            </ModalBody>
        </StyledModal>
    )
}

export default CreateClubSuccessModal