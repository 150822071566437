
import CustomText from '../../../components/common/CustomText'
import { Container, PageWrapper } from '../../../styles/GlobalStyles'
import { FlexBox } from '../../../components/common/FlexBox'
import CustomButton from '../../../components/common/CustomButton'
import styled from 'styled-components'
import { useState } from 'react'
import CreateClubSuccessModal from 'components/modal/CreateClubSuccessModal'

const CreateNewClubStyle = styled.div`
  
`

const CustomInput = styled.input`
  width: 100%;
  height: 79px;
  border-radius: 10px;
  padding: 16px 75px 14px 32px;
  color: #787878;
  font-size: 24px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
`

const CustomTextArea = styled.textarea`
  width: 100%;
  height: 198px;
  border-radius: 10px;
  padding: 16px 75px 14px 32px;
  color: #787878;
  font-size: 24px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
`


const CreateNewClub = () => {

  return (
    <PageWrapper>
      <Container maxWidth='706px'>
        <CreateNewClubStyle>
          <CustomText
            text="Create New Club"
            fontSize='36px'
            lineHeight='41px'
            fontWeight='700'
            color='black'
            justifyContent='center'
            textAlign='center'
          />
          <FlexBox direction='column' marginTop='57px'>
            <FlexBox justifyContent='space-between'>
              <CustomText
                text="<string>* </string>&nbsp;Title of Club"
                fontSize='24px'
                lineHeight='24px'
                fontWeight='700'
              />
              <CustomText
                text="Max. 25 Characters"
                fontSize='14px'
                lineHeight='24px'
                fontWeight='700'
                color="#F73737"
              />
            </FlexBox>
            <CustomInput
              placeholder='First thing users will see! Ex: Meet up in Miami'
            />
          </FlexBox>

          <FlexBox direction='column' marginTop='14px'>
            <FlexBox justifyContent='space-between'>
              <CustomText
                text="<string>* </string>&nbsp;Description"
                fontSize='24px'
                lineHeight='24px'
                fontWeight='700'
              />
              <CustomText
                text="Max. 60 Characters"
                fontSize='14px'
                lineHeight='24px'
                fontWeight='700'
                color="#F73737"
              />
            </FlexBox>
            <CustomTextArea
              placeholder='Any other notes?'
            />
          </FlexBox>

          <FlexBox direction='column' marginTop='14px'>
            <CustomText
              text="<string>* </string>&nbsp;Cover Image"
              fontSize='24px'
              lineHeight='24px'
              fontWeight='700'
            />
            <CustomButton
              text="Attach"
              width='188px'
              height='65px'
              borderRadius='10px'
              bgColor='#FF8B00'
            />
          </FlexBox>


          <FlexBox direction='column' marginTop='14px'>
            <CustomText
              text="<string>* </string>&nbsp;Website Link"
              fontSize='24px'
              lineHeight='24px'
              fontWeight='700'
            />
            <CustomInput
              placeholder='https://'
            />
          </FlexBox>

          <FlexBox direction='column' marginTop='14px' marginBottom='43px'>
            <CustomText
              text="Social Links"
              fontSize='24px'
              lineHeight='24px'
              fontWeight='700'
            />
            <FlexBox direction='column' gap="8px">
              <CustomInput
                placeholder='discord'
              />

              <CustomInput
                placeholder='Twitter'
              />
              <CustomInput
                placeholder='Instagram'
              />
            </FlexBox>
          </FlexBox>

          <CustomButton
            text='Post Event'
            width='209px'
            height='48px'
            bgColor='#FF7A00'
            fontSize='20px'
            fontWeight='700'
            lineHeight='24px'
          />


        </CreateNewClubStyle>
      </Container>

    </PageWrapper>
  )
}

export default CreateNewClub