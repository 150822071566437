import React, { useState } from 'react'
import { PageWrapper, Container } from '../../styles/GlobalStyles'
import CustomButton from '../../components/common/CustomButton'
import { LeaderboardItem, LeaderboardList, SearchBar } from './index.styled'
import { M } from 'lucid-cardano'
import { BOMB_ICON, CRASHR_ICON, DEFAULT_AVATAR_IMAGE, DEFAULT_NFT_IAMGE, DISCORD_BLACK_ICON, INSTAGRAM_ICON, TWITTER_ICON, VOTE_UP_IMAGE } from '../../constants/image.constants'
import { FlexBox } from '../../components/common/FlexBox'
import { useMedia } from 'react-use'
import CustomText from 'components/common/CustomText'
import LeaderboardTopMember from 'components/card/LeaderboardTopMember'
import CustomSearchInput from 'components/common/CustomSearchInput'
import SocialImageLink from 'components/common/SocialImageLink'
import { CRASHR_DISCORD_URL, CRASHR_TWITTER_URL } from 'constants/url.constant'
import CustomInput from 'components/common/CustomInput'
import ComingSoon from 'components/ComingSoon'

const TABLE_HEADER_DATA = [
  "Rank",
  "User",
  "CRASH",
  "Holding",
  "Socials"
]

const TABLE_CONTENTS_DATA = [
  {
    rank: 1,
    user: "Ghost",
    crash: "2,300,00",
    holding: "200 Bombers"
  },
  {
    rank: 2,
    user: "Ghost",
    crash: "2,300,00",
    holding: "200 Bombers"
  },
  {
    rank: 3,
    user: "Ghost",
    crash: "2,300,00",
    holding: "200 Bombers"
  },
  {
    rank: 4,
    user: "Ghost",
    crash: "2,300,00",
    holding: "200 Bombers"
  },
  {
    rank: 1,
    user: "Ghost",
    crash: "2,300,00",
    holding: "200 Bombers"
  },
  {
    rank: 2,
    user: "Ghost",
    crash: "2,300,00",
    holding: "200 Bombers"
  },
  {
    rank: 3,
    user: "Ghost",
    crash: "2,300,00",
    holding: "200 Bombers"
  },
  {
    rank: 4,
    user: "Ghost",
    crash: "2,300,00",
    holding: "200 Bombers"
  },
  {
    rank: 1,
    user: "Ghost",
    crash: "2,300,00",
    holding: "200 Bombers"
  },
  {
    rank: 2,
    user: "Ghost",
    crash: "2,300,00",
    holding: "200 Bombers"
  },
  {
    rank: 3,
    user: "Ghost",
    crash: "2,300,00",
    holding: "200 Bombers"
  },
  {
    rank: 4,
    user: "Ghost",
    crash: "2,300,00",
    holding: "200 Bombers"
  },
  {
    rank: 1,
    user: "Ghost",
    crash: "2,300,00",
    holding: "200 Bombers"
  },
  {
    rank: 2,
    user: "Ghost",
    crash: "2,300,00",
    holding: "200 Bombers"
  },
  {
    rank: 3,
    user: "Ghost",
    crash: "2,300,00",
    holding: "200 Bombers"
  },
  {
    rank: 4,
    user: "Ghost",
    crash: "2,300,00",
    holding: "200 Bombers"
  },
  {
    rank: 1,
    user: "Ghost",
    crash: "2,300,00",
    holding: "200 Bombers"
  },
  {
    rank: 2,
    user: "Ghost",
    crash: "2,300,00",
    holding: "200 Bombers"
  },
  {
    rank: 3,
    user: "Ghost",
    crash: "2,300,00",
    holding: "200 Bombers"
  },
  {
    rank: 4,
    user: "Ghost",
    crash: "2,300,00",
    holding: "200 Bombers"
  },
  {
    rank: 1,
    user: "Ghost",
    crash: "2,300,00",
    holding: "200 Bombers"
  },
  {
    rank: 2,
    user: "Ghost",
    crash: "2,300,00",
    holding: "200 Bombers"
  },
  {
    rank: 3,
    user: "Ghost",
    crash: "2,300,00",
    holding: "200 Bombers"
  },
  {
    rank: 4,
    user: "Ghost",
    crash: "2,300,00",
    holding: "200 Bombers"
  },
]

const Leaderboards = () => {

  const [search, setSearch] = useState<string>("");
  const isMobile = useMedia('(max-width: 768px)')
  return (
    <PageWrapper>
      <Container
        maxWidth='1206px'
        paddingLeft='30px'
        paddingRight='30px'
      >
        <ComingSoon />
        {/* <FlexBox
          justifyContent='start'
          mdJustifyContent='center'
          mdAlignItems='center'
        >
          <CustomText
            text='Leaderboard'
            fontSize='50px'
            fontWeight='700'
            mdFontSize='38px'
          />
        </FlexBox>

        <FlexBox
          justifyContent='center'
          mdMarginTop='48px'
          marginTop='52px'
        >
          <CustomSearchInput
            placeholder='Search Users'
            input={search}
            setInput={setSearch}
          />
        </FlexBox>
        <FlexBox
          marginTop='85px'
          gap="66px"
          mdGap='45px'
          mdDirection='row'
        >
          <LeaderboardTopMember
            paddingTop='131px'
            mdPaddingTop='45px'
            image={DEFAULT_AVATAR_IMAGE}
          />
          <LeaderboardTopMember
            image={DEFAULT_AVATAR_IMAGE}
          />
          <LeaderboardTopMember
            paddingTop='131px'
            mdPaddingTop='45px'
            image={DEFAULT_AVATAR_IMAGE}
          />
        </FlexBox>
        <FlexBox
          justifyContent='center'
          marginTop='48px'>
          <LeaderboardList>
            {
              TABLE_CONTENTS_DATA.map((item, index) => {
                return (
                  <LeaderboardItem
                    key={index}
                  >
                    <div>
                      #4
                      <img src={VOTE_UP_IMAGE} />
                    </div>
                    <div><img src={DEFAULT_NFT_IAMGE} /></div>
                    <div>
                      24
                      <img src={BOMB_ICON} />
                    </div>
                  </LeaderboardItem>
                )
              })
            }

          </LeaderboardList>
        </FlexBox> */}
      </Container>
    </PageWrapper>
  )
}

export default Leaderboards