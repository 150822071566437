import CustomButton from 'components/common/CustomButton'
import CustomText from 'components/common/CustomText'
import { FlexBox } from 'components/common/FlexBox'
import React, { useContext, useEffect, useState } from 'react'
import { Container, PageWrapper } from 'styles/GlobalStyles'


import CustomImage from 'components/common/CustomImage'
import { CRASHR_BLACK_ICON, DEFAULT_AVATAR_IMAGE, DEFAULT_NFT_IAMGE, DISCORD_BLACK_ICON, TWITTER_ICON } from 'constants/image.constants'
import CopyToClipboard from 'react-copy-to-clipboard'
import WalletCopyBoard from 'components/WalletCopyBoard'
import { useMedia } from 'react-use'
import { useWalletConnect } from 'hooks/WalletConnect'
import CustomLinkButton from 'components/common/CustomLinkButton'
import { GlobalProvider } from 'App'
import ExplorerNFTCard from 'components/pages/explorer/ExplorerNFTCard'
import styled from 'styled-components'
import axios from 'axios'
import { getMyNFTs } from 'api/api'
import { MAIN_POLICY_ID } from 'constants/_main.constant'
import { UPDATE_USER_DATA_API } from 'constants/api.constants'
import { infoAlert } from 'hooks/alert'
import { C } from 'lucid-cardano'

const UserName = styled.div`
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: 600px;
  width: 100%;
  color: black;
  font-family: Inconsolata;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.84px;
  @media screen and (max-width: 768px) {
    font-size: 28px;
  }
`

const SocialLink = styled.a`
  cursor: pointer;
  img{
    width: 36px;
    height: 36px;
  }
`

const walletAddress = window.location.href.split("/users/")[1];
const User = () => {
  const [commonUserData, setcommonUserData] = useState<UserDataType>({
    username: '',
    avatar: '',
    points: 0,
    bomber_username: '',
    bomber_avatar: '',
    crashr: '',
    twitter: '',
    discord: '',
    wallet: '', // Make sure to include the 'wallet' property.
    friends: []
  });
  const [myNFTs, setMyNFTs] = useState([]);
  const { myWalletAddress } = useWalletConnect()
  const { userData } = useContext(GlobalProvider)
  const isMobile = useMedia('(max-width: 768px)');

  const getUserByWallet = async () => {
    try {
      const res = await axios.get("https://qru4p76ipi.execute-api.us-west-2.amazonaws.com/items/" + walletAddress);
      // console.log("user data", res.data)
      if (res.data === "") {
        // console.log("not existing");
        const data = {
          id: walletAddress,
          username: walletAddress,
          avatar: "https://mdccimages.s3.eu-west-2.amazonaws.com/_detault_avatar.png",

        };
        const response = await axios.post("https://5200m71emb.execute-api.us-west-2.amazonaws.com/test/users", JSON.stringify(data));
        // @ts-ignore
        setcommonUserData(data)
      } else {
        // console.log("existing")
        setcommonUserData(res.data);
      }
    } catch (err) {
      // console.log("err", err)
    }
  }

  const getMyNFTsData = async () => {
    try {
      const data = await getMyNFTs(walletAddress
      );
      if (Object.values(data).length > 0) {
        // Ensure 'data' is an array before mapping.
        const filteredNFTs: any = Object.values(data).filter((nft: MyNFT) => nft.asset.slice(0, 56) === MAIN_POLICY_ID);
        setMyNFTs(filteredNFTs);
      }
    } catch (error) {
      console.error('Error fetching NFTs:', error);
    }
  };

  const AddFriend = async () => {
    if(!myWalletAddress){
      infoAlert("You need to connect wallet to add this user to your friend list")
    }
    const req_data = {
      id: myWalletAddress,
      friends: walletAddress
    };
    const response = await axios.post(UPDATE_USER_DATA_API, JSON.stringify(req_data));
    if (response.data.statusCode === 200) {
      infoAlert("This user is added to your friends list")
    }
  }

  const RemoveFriend = async () => {
    
    const req_data = {
      id: myWalletAddress,
      friends: walletAddress,
      remove: true
    };
    const response = await axios.post(UPDATE_USER_DATA_API, JSON.stringify(req_data));
    if (response.data.statusCode === 200) {
      infoAlert("This user is removed from your friends list")
    }
  }

  useEffect(() => {
    if (walletAddress !== '') {
      getUserByWallet()
      getMyNFTsData()
    }
  }, [walletAddress])

  return (
    <PageWrapper>
      <Container
        maxWidth='1121px'
        paddingLeft='16px'
        paddingRight='16px'
      >
        <FlexBox
          justifyContent='start'
          gap="50px"
          mdBgColor='white'
          borderRadius='3px'
          mdPaddingTop='15px'
          mdPaddingRight='18px'
          mdPaddingBottom='27px'
          mdPaddingLeft='13px'
          mdDirection='row'
          mdGap='0px'
          mdJustifyContent='space-between'
        >
          <FlexBox
            direction='column'
            gap="55px"
            maxWidth='240px'
            mdGap='10px'
          >
            <CustomText
              borderRadius='59px'
              bgColor='#c8ede9'
              text="Rank #1"
              fontSize='31px'
              fontWeight='700'
              paddingLeft='28px'
              paddingBottom='8px'
              paddingRight='28px'
              paddingTop='8px'
            />
            <CustomImage
              image={commonUserData && commonUserData?.bomber_avatar ? commonUserData?.bomber_avatar : commonUserData?.avatar}
              width='240px'
              height='240px'
              mdWidth='127px'
              mdHeight='127px'
              borderRadius='9px'
            />

            {
              isMobile && userData && walletAddress && userData.friends && !userData.friends.includes(walletAddress) &&
              <CustomButton
                text='Add Friend'
                width='243px'
                height='50px'
                smWidth='117px'
                smHeight='42px'
                fontSize='19px'
                fontWeight='600'
                marginTop='0px'
                smFontSize='16px'
                onClick={() => {
                  AddFriend()
                }}
              />
            }
            {
              isMobile && userData && walletAddress && userData.friends && userData.friends.includes(walletAddress) &&
              <CustomButton
                text='Remove Friend'
                width='243px'
                height='50px'
                smWidth='117px'
                smHeight='42px'
                fontSize='19px'
                fontWeight='600'
                marginTop='0px'
                smFontSize='16px'
                onClick={() => {
                  RemoveFriend()
                }}
              />
            }

          </FlexBox>
          <FlexBox
            direction='column'
            justifyContent='space-between'
            alignItems='default'
            mdPaddingTop='28px'
          >
            <UserName>
              {commonUserData && commonUserData.bomber_username ? commonUserData.bomber_username : walletAddress}
            </UserName>

            <FlexBox direction='column' gap='12px' marginTop='17px'>
              <FlexBox
                justifyContent='start'
                gap="30px"
                mdDirection='row'
                mdJustifyContent='start'
                mdGap="10px"
              >
                <CustomText
                  text={commonUserData !== undefined && commonUserData.points && commonUserData.points}
                  fontSize='28px'
                  fontWeight='700'
                  color='#21b6a8'
                />
                <CustomText
                  text={`PTS`}
                  fontSize='28px'
                  fontWeight='700'
                  color="#4f4f4f"
                />
              </FlexBox>
              <FlexBox
                justifyContent='start'
                gap="30px"
                mdDirection='row'
                mdJustifyContent='start'
                mdGap="10px"
              >
                <CustomText
                  text={myNFTs && myNFTs.length}
                  fontSize='28px'
                  fontWeight='700'
                  color='#21b6a8'
                />
                <CustomText
                  text={`Bombers Owned`}
                  fontSize='28px'
                  fontWeight='700'
                  color="#4f4f4f"
                  className='text-nowrap'
                />
              </FlexBox>
              <FlexBox
                justifyContent='start'
                gap="30px"
                mdDirection='row'
                mdJustifyContent='start'
                mdGap="10px"
              >
                <CustomText
                  text={
                    `${commonUserData.friends && commonUserData.friends.length > 0 ? commonUserData.friends.length : 0
                    }`}
                  fontSize='28px'
                  fontWeight='700'
                  color='#21b6a8'
                />
                <CustomText
                  text={`Friends`}
                  fontSize='28px'
                  fontWeight='700'
                  color="#4f4f4f"
                />
              </FlexBox>
            </FlexBox>
            <FlexBox
              gap="40px"
              justifyContent='start'
              marginTop='24px'
              mdDirection='row'
              mdJustifyContent='start'
              mdGap='27px'
            >
              <SocialLink
                href={commonUserData && commonUserData.crashr}
              >
                <img src={CRASHR_BLACK_ICON} />
              </SocialLink>
              <SocialLink
                href={commonUserData && commonUserData.discord}
              >
                <img src={DISCORD_BLACK_ICON} />
              </SocialLink>
              <SocialLink
                href={commonUserData && commonUserData.twitter}
              >
                <img src={TWITTER_ICON} />
              </SocialLink>

            </FlexBox>
            {
              !isMobile && userData && walletAddress && userData.friends && !userData.friends.includes(walletAddress) &&
              <CustomButton
                text='Add Friend'
                width='243px'
                height='50px'
                smWidth='117px'
                smHeight='42px'
                fontSize='19px'
                fontWeight='600'
                marginTop='27px'
                smFontSize='16px'
                onClick={() => { AddFriend() }}
              />
            }
            {
              !isMobile && userData && walletAddress && userData.friends && userData.friends.includes(walletAddress) &&
              <CustomButton
                text='Remove Friend'
                width='243px'
                height='50px'
                smWidth='117px'
                smHeight='42px'
                fontSize='19px'
                fontWeight='600'
                marginTop='27px'
                smFontSize='16px'
                onClick={() => { RemoveFriend() }}
              />
            }

          </FlexBox>
        </FlexBox>
        {/**** Bombers */}
        <CustomText
          text="Bombers"
          fontSize='50px'
          fontWeight='700'
          marginTop='80px'
          mdFontSize='24px'
        />

        <FlexBox
          flexWrap='wrap'
          gap="72px"
          marginTop='52px'
          justifyContent='center'
          alignItems='center'
        >
          {
            myNFTs && myNFTs.map((nft: MyNFT) => {
              return (
                <ExplorerNFTCard
                  image={nft.image.replace(
                    "ipfs://",
                    "https://image-optimizer.jpgstoreapis.com/"
                  )}
                  name={nft?.name}
                />
              )
            })
          }
        </FlexBox>
      </Container>
    </PageWrapper>

  )
}

export default User