import React, { useContext, useEffect, useState } from 'react'
import { Container, PageWrapper } from '../../styles/GlobalStyles'
import CustomText from '../../components/common/CustomText'
import { FlexBox } from '../../components/common/FlexBox'

import StakingSuccessModal from 'components/modal/StakingSuccessModal'
import styled from 'styled-components'
import { GlobalProvider } from 'App'
import { useWalletConnect } from 'hooks/WalletConnect'
import { mutateCreateStakeTransaction, mutateSubmitStakeTransaction } from 'api/graphql/stake_mutation'
import { CreateStakeTransactionInput, StakeComponent } from 'utils/types/Transactions/StakeTransaction/CreateStakeTransactionInput'
import { CreateStakeTransactionPayload } from 'utils/types/Transactions/StakeTransaction/CreateStakeTransactionPayload'
import { queryStakedNfts } from 'api/graphql/query'
import { TEST_SATURN_ID } from 'constants/_test.contants'
import CustomLinkButton from 'components/common/CustomLinkButton'
import { SubmitStakeTransactionPayload } from 'utils/types/Transactions/StakeTransaction/SubmitStakeTransactionPayload'
import { MAIN_POLICY_ID } from 'constants/_main.constant'
import { updateUserProfile } from 'api/api'

import { toast } from 'react-toastify'
import NFTStakingCard from 'components/card/NFTStakingCard'


const StakeAllButton = styled.button`
  position: relative;
  border: none;
  background-color: #FEF8E6;
  color: black;
  text-align: center;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 20%;
    width: 60%;
    height: 2px;
    background-color: #000;
  }
`

interface StakedNFT {
  assetName: string;
  daysStaked: number;
  policyId: string;
  rewardsAccumulated: string;
}

const StakingMain = () => {
  const [showModal, setShowModal] = useState(false)
  const [stakedNFTs, setStakedNFTs] = useState<StakedNFT[]>()
  const [myStakedNFTs, setMyStakedNFTs] = useState<MyNFT[]>([])
  const [myUnStakedNFTs, setMyUnStakedNFTs] = useState<MyNFT[]>([])
  const [myStakedActionNFTs, setMyStakedActionNFTs] = useState<StakeComponent[]>([])
  const [myUnStakedActionNFTs, setMyUnStakedActionNFTs] = useState<StakeComponent[]>([])
  const [stakedNum, setStakedNum] = useState<number>(0);
  const [stakedAsset, setStakedAsset] = useState<string>()

  const { myNFTs, userData } = useContext(GlobalProvider)
  const { myWalletAddress, lucid } = useWalletConnect()

  const getStakedNFTsFunction = async () => {
    try {
      const stakeProjectId = TEST_SATURN_ID;
      const result = await queryStakedNfts(stakeProjectId);
      setStakedNFTs(result)
    } catch (error: any) {
      console.log(error);
    }
  };

  const stakeNFT = async (flag: number, nft?: MyNFT) => {
    try {
      let stakeComponent: StakeComponent;
      if (flag === 0)
        stakeComponent = {
          stakeProjectId: TEST_SATURN_ID,
          policyId: MAIN_POLICY_ID,
          assetName: nft.asset.slice(56),
        };
      // console.log("stakeComponent", stakeComponent)
      // console.log("myUnStakedActionNFTs", myUnStakedActionNFTs)

      const createInput: CreateStakeTransactionInput = {
        paymentAddress: myWalletAddress,
        stakeComponents: flag === 0 ? [stakeComponent] : myUnStakedActionNFTs,
        unstakeComponents: [],
        addStakeTokenComponents: [],
      };
      console.log("createInput", createInput)

      const createTransaction: CreateStakeTransactionPayload = await mutateCreateStakeTransaction(createInput);
      console.log("createTransaction", createTransaction)

      const tx = lucid.fromTx(createTransaction.successTransactions[0].hexTransaction)
      console.log("tx", tx, tx.toString())
      const signedTx = await tx?.sign().complete();
      const signedHexTx = signedTx?.toString();
      console.log("signedHexTx", signedHexTx)



      const submitTx: SubmitStakeTransactionPayload = await mutateSubmitStakeTransaction(
        {
          paymentAddress: myWalletAddress,
          successTransactions: [
            {
              transactionId: createTransaction.successTransactions[0].transactionId,
              hexTransaction: signedHexTx,
            }
          ]
        }
      );
      if (submitTx.transactionIds[0]) {
        getStakedNFTsFunction()
        toast(submitTx.transactionIds[0])
        const target_time: number = Date.now() + 86400 * 1000;
        if (flag === 0) {
          setStakedAsset(nft.name)
          setShowModal(true)
          if (myStakedNFTs.length === 0) {
            const reqData = {
              id: myWalletAddress,
              entries: [
                {
                  bomber_id: nft.name,
                  rewards_time: target_time
                }
              ],
              staked: true,
              bomber_start: new Date().getTime(),
              staked_num: 1
            }
            console.log("reqDta", reqData)
            const response = await updateUserProfile(reqData)
            console.log("updateUserProfile response", response)
          } else {
            const reqData = {
              id: myWalletAddress,
              entries: [
                {
                  bomber_id: nft.name,
                  rewards_time: target_time
                }
              ],
              staked_num: myStakedNFTs.length + 1
            }
            console.log("reqDta", reqData)
            const response = await updateUserProfile(reqData)
            console.log("updateUserProfile response", response)
          }

        } else {
          const originalArray = myUnStakedNFTs

          const newArray = originalArray.map((item) => ({
            bomber_id: item.name,
            rewards_time: target_time,
          }));

          const response = await updateUserProfile({
            id: myWalletAddress,
            entries: newArray,
            staked: true,
            bomber_start: new Date().getTime(),
            staked_num: myUnStakedActionNFTs.length
          })
          console.log("updateUserProfile response", response)
        }

      }

      console.log("submitTx", submitTx.transactionIds[0])
    } catch (error: any) {
      console.log(error);
    }
  }

  const unStakeNFT = async (flag: number, nft?: MyNFT) => {
    try {

      let stakeComponent: StakeComponent;
      if (flag === 0)
        stakeComponent = {
          stakeProjectId: TEST_SATURN_ID,
          policyId: MAIN_POLICY_ID,
          assetName: nft.asset.slice(56),
        };
      // console.log("myStakedActionNFTs", myStakedActionNFTs)
      console.log("stakeComponent", stakeComponent)

      const createInput: CreateStakeTransactionInput = {
        paymentAddress: myWalletAddress,
        stakeComponents: [],
        unstakeComponents: flag === 0 ? [stakeComponent] : myStakedActionNFTs,
        addStakeTokenComponents: [],
      };
      console.log("createInput", createInput)

      const createTransaction: CreateStakeTransactionPayload = await mutateCreateStakeTransaction(createInput);
      console.log("createTransaction", createTransaction)

      const tx = lucid.fromTx(createTransaction.successTransactions[0].hexTransaction)
      console.log("tx", tx, tx.toString())
      const signedTx = await tx?.sign().complete();
      const signedHexTx = signedTx?.toString();
      console.log("signedHexTx", signedHexTx)


      const subtmitTx: SubmitStakeTransactionPayload = await mutateSubmitStakeTransaction(
        {
          paymentAddress: myWalletAddress,
          successTransactions: [
            {
              transactionId: createTransaction.successTransactions[0].transactionId,
              hexTransaction: signedHexTx,
            }
          ]
        }
      );

      if (subtmitTx.transactionIds[0]) {
        getStakedNFTsFunction()
        console.log("submitTx", subtmitTx.transactionIds[0])
        toast(subtmitTx.transactionIds[0])
        if (flag === 0) {
          let staked = true;
          if (myStakedNFTs.length === 1) {
            console.log("hey hey hey")
            staked = false;
            const response = await updateUserProfile({
              id: myWalletAddress,
              entries: [
                {
                  bomber_id: nft.name,
                  rewards_time: -1
                }
              ],
              staked: staked,
              bomber_end: new Date().getTime(),
              staked_num: 0
            })
            console.log("updateUserProfile response", response)
          } else {
            const response = await updateUserProfile({
              id: myWalletAddress,
              entries: [
                {
                  bomber_id: nft.name,
                  rewards_time: -1
                }
              ],
              staked: staked,
              staked_num: myStakedNFTs.length - 1
            })
            console.log("updateUserProfile response", response)
          }


        } else {
          const originalArray = myStakedNFTs

          const newArray = originalArray.map((item) => ({
            bomber_id: item.name,
            rewards_time: -1,
          }));
          const response = await updateUserProfile({
            id: myWalletAddress,
            entries: newArray,
            staked: false,
            bomber_end: new Date().getTime(),
            staked_num: 0
          })
          console.log("updateUserProfile response", response)
        }

      }
    } catch (error: any) {
      console.log(error);
    }
  }


  useEffect(() => {
    getStakedNFTsFunction()
  }, [])

  useEffect(() => {
    let staked_arr = [], unstaked_arr = [], staked_action_arr = [], unstaked_action_arr = []
    if (stakedNFTs && myNFTs && myNFTs.length > 0) {
      for (let i = 0; i < myNFTs.length; i++) {
        const item = myNFTs[i]
        const assetToFind: string = item.asset.slice(56)
        const stakedElement = stakedNFTs.find(item => item.assetName === assetToFind);
        if (stakedElement) {
          staked_arr.push(item)
          staked_action_arr.push({
            stakeProjectId: TEST_SATURN_ID,
            policyId: MAIN_POLICY_ID,
            assetName: item.asset.slice(56),
          })
        } else {
          unstaked_arr.push(item)
          unstaked_action_arr.push({
            stakeProjectId: TEST_SATURN_ID,
            policyId: MAIN_POLICY_ID,
            assetName: item.asset.slice(56),
          })
        }
      }
      console.log("staked_action_arr", staked_action_arr)
      console.log("unstaked_action_arr", unstaked_action_arr)
      setMyStakedNFTs(staked_arr)
      setMyUnStakedNFTs(unstaked_arr)
      setMyStakedActionNFTs(staked_action_arr)
      setMyUnStakedActionNFTs(unstaked_action_arr)
      setStakedNum(staked_arr.length)
    }

  }, [stakedNFTs, myNFTs])

  return (
    <PageWrapper>
      {
        <Container maxWidth='1395px' paddingLeft='25px' paddingRight='25px'>
          <FlexBox
            justifyContent='space-between'
            paddingBottom='20px'
            alignItems='center'
            mdDirection='column'
          >
            <FlexBox justifyContent='start'
              direction='column'
              mdJustifyContent='center'
              mdAlignItems='center'
              mdWidth='100%'
            >
              <CustomText
                text="Stake your Bombers!"
                fontSize='50px'
                fontWeight='700'
                color='black'
                mdFontSize='38px'
                textAlign='center'
                mdLineHeight='120%'
              />
              <CustomText
                text={`Each staked Bomber will earn Points.`}
                fontSize='18px'
                height='52px'
                fontWeight='400'
                lineHeight='22px'
                mdFontSize='16px'
                textAlign='center'
                marginTop='20px'
                paddingLeft='6px'
              />
              <CustomLinkButton
                link="my-profile"
                text='View Wallet'
                marginTop='6px'
                smWidth='100%'
              />
              {/* <CustomText
                text={`${userData && userData.rewards_time && userData.rewards_time !== -1 ? getRemainingTime(userData.rewards_time) : ''}`}
              /> */}
            </FlexBox>

            <FlexBox width='default' mdDirection='row' mdPaddingTop='18px'>
              <FlexBox
                borderRight='1px solid black'
                direction='column'
                width=''
                paddingRight='32.5px'
                mdWidth='auto'
                color='black'
                alignItems='center'
                mdPaddingRight='15px'
              >
                <CustomText
                  text={stakedNum ? stakedNum : 0}
                  fontSize='28px'
                  fontWeight='700'
                  lineHeight='41px'
                  letterSpacing='3px'
                  justifyContent='center'
                  textAlign='center'
                />
                <CustomText
                  text='Bombers Staked'
                  fontSize='21px'
                  fontWeight='400'
                  lineHeight='41px'
                  letterSpacing='0.8px'
                  mdFontSize='14px'
                  textAlign='center'
                  className='text-nowrap'
                />
              </FlexBox>
              <FlexBox borderRight='1px solid black' direction='column' paddingLeft='32.5px' paddingRight='32.5px' mdWidth='auto' color='black' alignItems='center'
                mdPaddingLeft='15px'
                mdPaddingRight='15px'
              >
                <CustomText
                  text={userData && userData.points ? userData.points : 0}
                  fontSize='28px'
                  fontWeight='700'
                  lineHeight='41px'
                  letterSpacing='3px'
                  justifyContent='center'
                  textAlign='center'
                />
                <CustomText
                  text='Points'
                  fontSize='21px'
                  fontWeight='400'
                  lineHeight='41px'
                  letterSpacing='0.8px'
                  mdFontSize='14px'
                  textAlign='center'
                />
              </FlexBox>
              <FlexBox
                direction='column'
                width=''
                paddingLeft='32.5px'
                mdPaddingLeft='15px'
                color='black'
                alignItems='center'
                mdWidth='auto'>
                <CustomText
                  text={stakedNum && stakedNum ? stakedNum : 0}
                  fontSize='28px'
                  fontWeight='700'
                  lineHeight='41px'
                  letterSpacing='3px'
                  justifyContent='center'
                  textAlign='center'
                />
                <CustomText
                  text={"Points Per Day"}
                  fontSize='21px'
                  fontWeight='400'
                  lineHeight='41px'
                  letterSpacing='0.8px'
                  mdFontSize='14px'
                  textAlign='center'
                  className='text-nowrap'
                />
              </FlexBox>

            </FlexBox>
          </FlexBox>

          <FlexBox
            marginTop='86px'
            mdMarginTop='24px'
            justifyContent='start'
            gap="52px"
            mdDirection='row'
            alignItems='center'
            mdJustifyContent='center'>
            <StakeAllButton
              onClick={() => {
                stakeNFT(1)
              }}
            >
              Stake All
            </StakeAllButton>
            <StakeAllButton onClick={() => {
              unStakeNFT(1)
            }}>
              Unstake All
            </StakeAllButton>
          </FlexBox>

          <FlexBox
            gap="97px"
            flexWrap='wrap'
            marginTop='42px'
            mdJustifyContent='center'
            alignItems='center'
            mdDirection='row'
            mdGap='40px'
          >
            {
              myUnStakedNFTs && myUnStakedNFTs.length > 0 && myUnStakedNFTs.map((item: MyNFT, index) => {
                console.log("item data", item)
                return (
                  <NFTStakingCard
                    key={index}
                    index={index}
                    data={item}
                    stakeNFT={stakeNFT}
                    unStakeNFT={unStakeNFT}
                    staked={false}
                  />
                )
              })
            }
            {
              myStakedNFTs && myStakedNFTs.length > 0 && myStakedNFTs.map((item, index) => {
                return (
                  <NFTStakingCard
                    key={index}
                    index={index}
                    data={item}
                    stakeNFT={stakeNFT}
                    unStakeNFT={unStakeNFT}
                    staked={true}
                  />
                )
              })
            }
          </FlexBox>


          <StakingSuccessModal
            show={showModal}
            assetID={stakedAsset}
            onClose={() => {
              setShowModal(false)
            }}
          />
        </Container>
      }
    </PageWrapper>
  )
}

export default StakingMain