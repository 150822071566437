import { GlobalProvider } from 'App'
import { updateUserProfile } from 'api/api'
import axios from 'axios'
import CustomButton from 'components/common/CustomButton'
import CustomImage from 'components/common/CustomImage'
import CustomText from 'components/common/CustomText'
import ErrorText from 'components/common/ErrorText'
import { FlexBox } from 'components/common/FlexBox'
import SelectNFTModal from 'components/modal/SelectNFTModal'
import { UPDATE_USER_DATA_API } from 'constants/api.constants'
import { DEFAULT_AVATAR_IMAGE, DEFAULT_NFT_IAMGE } from 'constants/image.constants'
import { useWalletConnect } from 'hooks/WalletConnect'
import { infoAlert } from 'hooks/alert'
import React, { useContext, useEffect, useState } from 'react'
import Feedback from 'react-bootstrap/esm/Feedback'
import { useDebounce } from 'react-use'
import styled from 'styled-components'
import { Container, PageWrapper } from 'styles/GlobalStyles'
const CustomInput = styled.input`
  width: 100%;
  height: 50px;
  border-radius: 4px;
  padding: 9px 72px 10px 21px;
  color: #9e9e9e;
  font-size: 18px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
  border: 2px solid #4f4f4f;
  background: none;
  max-width: 393px;
  width: 100%;
  @media screen and (max-width: 768px) {
    max-width: 100%;
    height: 38px;
    font-size: 16px;
    padding: 8px 12px 8px 10px;
  }
`



const EditProfile = () => {
  const [profile, setProfile] = useState<UserDataType>();
  const [showSelectModal, setShowSelectModal] = useState<boolean>(false)
  const { userData, setUserData } = useContext(GlobalProvider);
  const { myWalletAddress } = useWalletConnect()
  useEffect(() => {
    setProfile(userData)
  }, [userData])

  const updateProfile = async () => {
    const req_data = {
      id: myWalletAddress,
      bomber_username: profile.bomber_username,
      discord: profile.discord ? profile.discord : '',
      twitter: profile.twitter ? profile.twitter : '',
      crashr: profile.crashr ? profile.crashr : ''
    };
    const response = await updateUserProfile(req_data);
    console.log("response", response)
    if (response.status === 200) {
      infoAlert("Your profile is updated successfully")
    }
  }
  return (
    <PageWrapper>
      <Container
        maxWidth='825px'
        paddingLeft='22px'
        paddingRight='22px'
        mdPaddingBottom="73px"
      >
        <FlexBox gap="71px" mdGap='28px' mdAlignItems='center'>
          <FlexBox
            direction='column'
            maxWidth='301px'
            gap='27px'
            mdWidth='208px'
          >
            <CustomImage
              image={userData && userData?.bomber_avatar ? userData?.bomber_avatar : userData?.avatar}
              width='301px'
              height='301px'
              mdWidth='208px'
              mdHeight='208px'
              borderRadius='9px'
            />
            <FlexBox mdDirection='row'>
              <CustomButton
                text={`Upload NFT Image`}
                borderRadius='4px'
                bgColor='none'
                border='1px solid black'
                color='black'
                width='269px'
                height='51px'
                smHeight='42px'
                smWidth='190px'
                onClick={() => {
                  setShowSelectModal(true)
                }}
              />
            </FlexBox>
          </FlexBox>


          <FlexBox direction='column' mdJustifyContent='center' mdAlignItems='center'>
            <CustomText
              text={profile && profile?.bomber_username ? profile?.bomber_username.slice(0, 10) +"..." : ''}
              fontSize='56px'
              fontWeight='700'
              mdFontSize='28px'
            />
            <FlexBox
              direction='column'
              gap='24px'
              marginTop='28px'
            >
              <FlexBox direction='column' gap="4px" mdGap='20px'>
                <CustomText
                  text={`Username`}
                  height='51px'
                  fontSize='18px'
                  fontWeight='700'
                  mdFontSize='16px'
                  mdHeight='22px'
                />
                <CustomInput
                  type="text"
                  placeholder='Change Username'
                  value={profile && profile?.bomber_username ? profile?.bomber_username : ''}
                  onChange={(e) => {
                    setProfile({
                      ...profile,
                      bomber_username: e.target.value
                    })
                  }}
                />
                {
                  (profile?.bomber_username === "" || !profile?.bomber_username) && <ErrorText text={`Username is required`} />
                }
              </FlexBox>
              <FlexBox direction='column' gap="4px" mdGap='20px'>
                <CustomText
                  text={`Crashr Profile Link`}
                  height='51px'
                  fontSize='18px'
                  fontWeight='700'

                  mdFontSize='16px'
                  mdHeight='22px'
                />
                <CustomInput
                  type="text"
                  placeholder='Add Link'
                  value={profile && profile.crashr}
                  onChange={(e) => {
                    setProfile({
                      ...profile,
                      crashr: e.target.value
                    })
                  }}
                />
              </FlexBox>
              <FlexBox direction='column' gap="4px" mdGap='20px'>
                <CustomText
                  text={`X (formerly Twitter) Link`}
                  height='51px'
                  fontSize='18px'
                  fontWeight='700'
                  mdFontSize='16px'
                  mdHeight='22px'
                />
                <CustomInput
                  type="text"
                  placeholder='Add Link'
                  value={profile && profile.twitter}
                  onChange={(e) => {
                    setProfile({
                      ...profile,
                      twitter: e.target.value
                    })
                  }}
                />
              </FlexBox>
              <FlexBox direction='column' gap="4px" mdGap='20px'>
                <CustomText
                  text={`Discord Link`}
                  height='51px'
                  fontSize='18px'
                  fontWeight='700'
                  mdFontSize='16px'
                  mdHeight='22px'
                />
                <CustomInput
                  type="text"
                  placeholder='Add Link'
                  value={profile && profile.discord}
                  onChange={(e) => {
                    setProfile({
                      ...profile,
                      discord: e.target.value
                    })
                  }}
                />
              </FlexBox>
            </FlexBox>

            <FlexBox marginTop='52px' gap="14px" mdMarginTop='33px' mdDirection='row'>
              <CustomButton
                bgColor='none'
                border="1px solid black"
                color="black"
                text='Cancel'
                width='148px'
                height='50px'
                smHeight='42px'
                smWidth='84px'
                smFontSize='16px'
                onClick={() => {
                  setProfile(userData)
                }}
              />
              <CustomButton
                text="Save"
                width='243px'
                height='50px'
                smHeight='42px'
                smWidth='68px'
                smFontSize='16px'
                disabled={profile?.bomber_username === "" || !profile?.bomber_username}
                onClick={() => {
                  updateProfile()
                }}
              />
            </FlexBox>
          </FlexBox>

        </FlexBox>
      </Container>
      <SelectNFTModal
        show={showSelectModal}
        onClose={() => setShowSelectModal(false)}
      />
    </PageWrapper>
  )
}

export default EditProfile