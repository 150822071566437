import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import styled from 'styled-components';
import Navigation from './Navigation';
const LayoutStyle = styled.div`
  // display: flex;
  // justify-content: center;
  // div{
  //   width: 100%;
  //   max-width: 1450px;
  // }
  min-height: 100vh;
`;

// STARTS HERE



const MainLayout = () => {

  return (
    <LayoutStyle>
      <Navigation />
      <Outlet />
      <Footer />

    </LayoutStyle>
  );
};

export default MainLayout;
