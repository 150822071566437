export const timestampToDayTime = (timestamp: number) => {

  const date = new Date(timestamp);

  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const dayOfWeek = daysOfWeek[date.getUTCDay()];
  const month = months[date.getUTCMonth()];
  const dayOfMonth = date.getUTCDate();
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();

  const formattedDate = `${dayOfWeek}, ${month} ${dayOfMonth}, ${hours}am - ${hours + 1}am`;
  return formattedDate;
}


export const timestampToDayTime2 = (timestamp: number) => {
  const date = new Date(timestamp);

  const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const day = date.getUTCDate();

  // Function to add 'th', 'st', 'nd', or 'rd' to the day
  function getDayWithSuffix(day) {
    if (day >= 11 && day <= 13) {
      return day + 'th';
    }
    switch (day % 10) {
      case 1: return day + 'st';
      case 2: return day + 'nd';
      case 3: return day + 'rd';
      default: return day + 'th';
    }
  }

  const formattedDate = `${daysOfWeek[date.getUTCDay()]}, ${months[date.getUTCMonth()]} ${getDayWithSuffix(day)}`;

  return formattedDate;
}


export const getRemainingTime = (timestamp: number) => {
  const target_date = timestamp; // Replace this with your target date timestamp

  // Get the current timestamp in milliseconds
  const current_date = new Date().getTime();

  // Calculate the difference in milliseconds
  const difference_ms = target_date - current_date;
  if (difference_ms > 0) {
    // Calculate hours and minutes
    const hours = Math.floor(difference_ms / (1000 * 60 * 60));
    const minutes = Math.floor((difference_ms % (1000 * 60 * 60)) / (1000 * 60));
    return hours + "hrs " + minutes + "mins"
  } else {
    return "00 : 00"
  }



}