import { getNumOfHolders } from 'api/api';
import axios from 'axios';
import CustomText from 'components/common/CustomText';
import { FlexBox } from 'components/common/FlexBox';
import { MAIN_POLICY_ID } from 'constants/_main.constant';
import { TEST_POLICY_ID } from 'constants/_test.contants';

import { useCallback, useEffect, useState } from 'react';

const ExplorerStats = () => {
  const [floorPrice, setFloorPrice] = useState<number | null>(null);
  const [holders, setHolders] = useState<number>();

  const fetchFloorPrice = useCallback(async () => {
    try {
      const { data } = await axios.get(
        "https://api.opencnft.io/2/collection/" + MAIN_POLICY_ID + "/floor_price",
        {
          headers: {
            'X-Api-Key': 'ocnft_6478b2360e42f6648c04c200',
            "Content-Type": "application/json",
          }
        }
      );

      setFloorPrice(data.floor_price / 1000000);
    } catch (error) {
      console.error("Error fetching floor price:", error);
    }
  }, []);

  const getNumOfHoldersData = useCallback(async () => {
    try {
      const holders_data = await getNumOfHolders();
      setHolders(holders_data)
    } catch (error) {
      console.error("Error getNumOfHoldersData:", error);
    }
  }, []);



  useEffect(() => {
    fetchFloorPrice();
    getNumOfHoldersData()
  }, []);

  return (
    <FlexBox borderBottom='1px solid black' justifyContent='center' alignItems='center' paddingTop='14px' paddingBottom='19px' mdDirection='row'>
      <FlexBox direction='column' justifyContent='center' alignItems='center' maxWidth='176px' borderRight='1px solid black' paddingTop='20px' paddingBottom='20px' >
        <CustomText text='Listed' fontSize='16px' fontWeight='400' lineHeight='41px' letterSpacing='3px' />
        <FlexBox mdDirection='row'>
          <CustomText text="140/" fontWeight='400' fontSize='16px' lineHeight='41px' letterSpacing='3px' />
          <CustomText text="1510" fontWeight='400' fontSize='16px' lineHeight='41px' letterSpacing='3px' />
        </FlexBox>
      </FlexBox>

      <FlexBox direction='column' justifyContent='center' alignItems='center' maxWidth='176px' borderRight='1px solid black' paddingTop='20px' paddingBottom='20px'>
        <CustomText text='Floor' fontSize='16px' fontWeight='400' lineHeight='41px' letterSpacing='3px' />
        <CustomText text={`₳${floorPrice || ''}`} fontWeight='700' fontSize='16px' lineHeight='41px' letterSpacing='3px' />
      </FlexBox>

      <FlexBox direction='column' justifyContent='center' alignItems='center' maxWidth='176px' paddingTop='20px' paddingBottom='20px'>
        <CustomText text='Owners' fontSize='16px' fontWeight='400' lineHeight='41px' letterSpacing='3px' />
        <CustomText text={`${holders || ' '}`} fontWeight='400' fontSize='16px' lineHeight='41px' letterSpacing='3px' />
      </FlexBox>
    </FlexBox>
  )
}

export default ExplorerStats;