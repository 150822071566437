import { useState } from 'react';
import { Placeholder } from 'react-bootstrap';
import styled from 'styled-components';


interface CustomSearchInputType {
    input: string;
    setInput: any;
    placeholder?: string;
}

const SearchBarPanel = styled.div`
    width: 100%;
    max-width: 539px;
    box-shadow: 0px 2px 9px -4px rgba(0, 0, 0, 0.19);
`;

const SearchInput = styled.input`
    width: 100%;
    box-sizing: border-box !important;
    border: none;
    background: #e7e7e7;
    border-radius: 3px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #9e9e9e !important;
    padding: 12px 16px 12px 47px !important;
    background-image: url('/assets/images/icons/search.svg');
    background-position: 12px 13px;
    background-size: 24px 24px;
    background-repeat: no-repeat;
    ::placeholder {
        color: #9e9e9e;
    }
    &:focus{
        border: none;
        outline: none;
    }
    
    @media screen and (max-width: 550px) {
        height: 47px;
        font-size: 18px;
        line-height: 24px;
        background-size: 22px 22px;
        padding: 12px 12px 12px 48px !important;
        background-position: 14px 11px;
    }
`

const CustomSearchInput = (
    { input, setInput, placeholder }: CustomSearchInputType
) => {

    return (
        <SearchBarPanel>
            <SearchInput
                type="text"
                placeholder={placeholder}
                onChange={(e) => {
                    setInput(e.target.value)
                }}
                value={input}
                autoFocus
            />
        </SearchBarPanel>
    );
};

export default CustomSearchInput;
