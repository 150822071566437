import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { FlexBox } from '../common/FlexBox';
import CustomText from '../common/CustomText';
import CustomButton from '../common/CustomButton';
import CustomInput from 'components/common/CustomInput';
import CustomTextArea from 'components/common/CustomTextarea';
import { useMedia } from 'react-use';
import axios from 'axios';
import { toBase64 } from 'hooks/Base64';
import { createClub } from 'api/api';
import { utf8ToHex } from 'lucid-cardano';
import CustomImage from 'components/common/CustomImage';
import { UPLOAD_ICON } from 'constants/image.constants';

const StyledModal = styled(Modal)`
  .modal-dialog{
    margin: auto;
    max-width: 1351px;
    width: 100%;
    
    background: transparent;
    border-radius: 16px;
    
    @media screen and (max-width: 550px) {
      max-width: 100%;
    }
  }
  .modal-header{
    border-bottom: none;
    align-items: start;
    &.btn-close{
        font-size: 40px !important;
    }

  }
  .connect-success-content {
    background: white;
    border-radius: 3px;
    width: 100%;
    overflow: hidden;
    border: none;
    
    @media screen and (max-width: 550px) {
    }
  }
`;

const ModalBody = styled(Modal.Body)`
    padding: 18px 47px 28px 28px; 
    &.modal-body{
    }
`

const CustomFile = styled.label`
  margin-top: 8px;
  height: 120px;
  width: 100%;
  border-radius: 3px;
  padding: 14px 0;
  border: 2px solid #9E9E9E;
  cursor: pointer;
  align-items: center;
  background: white;
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-family: Open Sans;
  color: #9E9E9E;
  font-style: normal;
  font-weight: 600;
  
  display: flex;
  align-items: center;
  text-align: center;
  transition: all 0.3s ease-in;
  
  span{
    padding: 0 20px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
  input {
    display: none;
  }
  &:hover {
    background: #eeeeee;
    color: #000000;
  }
  @media screen and (max-width: 1100px) {
    font-size: 24px;
    line-height: 41px;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    height: 43px;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.05em;
  }
`;

interface Props {
    show: boolean;
    onClose: () => void;
    setShowSuccessModal: any;
    clubInputData: any;
    setClubInputData: any;
    getClubsData: any;
}
const CreateClubModal = ({ show, onClose, getClubsData, setShowSuccessModal, setClubInputData, clubInputData }: Props) => {
    // const isMobile = useMedia('(max-width: 550px)');
    const uploadFile = async (file: Object, choice: number) => {
        // @ts-ignore
        let file_base64: string = await toBase64(file);
        // @ts-ignore
        const file_base64_array: string[] = file_base64.split('base64,');
        //console.log(file_base64_array[1]);
        const send_json = JSON.stringify({
            // @ts-ignore
            name: file.name,
            base64: file_base64_array[1]
        });

        axios
            .post('https://g8uv4l3j8i.execute-api.eu-west-2.amazonaws.com/default/uploadImage', send_json)
            .then(function (res) {
                if (res) {
                    setClubInputData((prev) => ({
                        ...prev,
                        image: res.data.base
                    }));
                }
            })
            .catch(function (err) {
                console.log("uploadFile err", err)
            });
    };

    const createNewClub = async () => {
        try {
            const reqData = {
                id: clubInputData.id,
                club_name: clubInputData.club_name,
                description: clubInputData.description,
                image: clubInputData.image,
                website: clubInputData.website,
                instagram: "https://instagram.com/" + clubInputData.instagram,
                twitter: "https://twitter.com/" + clubInputData.twitter,
                discord: "https://discordapp.com/users/" + clubInputData.discord,
                location: clubInputData.location,
                entries: {},
                organizer: clubInputData.organizer
            }
            const responseStatus: number = await createClub(reqData);
            if (responseStatus === 200) {
                getClubsData();
                onClose()
                setShowSuccessModal(true)
            }
        } catch (err) {
            console.log("createNewClub err", err)
        }
    }
    return (
        <StyledModal show={show} onHide={onClose} centered contentClassName="connect-success-content">
            <Modal.Header closeButton>
            </Modal.Header>
            <ModalBody>
                <CustomText
                    text="Create New Club"
                    fontFamily='Inconsolata'
                    fontSize='67px'
                    fontWeight='700'
                    lineHeight='100%'
                    mdFontSize='21px'
                />
                <CustomText
                    text={`Club Details`}
                    marginTop='6px'
                    fontSize='28px'
                    fontWeight='700'
                    mdFontSize='16px'
                    color='#767676'
                />
                <FlexBox
                    marginTop='69px'
                    mdMarginTop='24px'
                    justifyContent='start'
                    direction='column'
                >
                    <FlexBox maxWidth='557px' direction='column' marginTop='16px'>
                        <CustomText
                            text={`<strong>*</strong>Club name:`}
                            fontSize='21px'
                            fontWeight='600'
                        />
                        <CustomInput
                            marginTop='8px'
                            placeholder='Write club name here'
                            maxWidth='557px'
                            value={clubInputData.club_name}
                            maxLength={20}
                            onChange={async (e) => {
                                setClubInputData((prev) => ({
                                    ...prev,
                                    club_name: e.target.value
                                }));
                                setClubInputData((prev) => ({
                                    ...prev,
                                    id: utf8ToHex(e.target.value + Date.now())
                                }));

                            }}
                        />
                        <FlexBox
                            direction="row-reverse"
                            justifyContent='end'

                        >
                            <CustomText
                                text={`${clubInputData.club_name.length} of 20`}
                                color='#9e9e9e'
                                paddingRight='10px'
                                fontSize='16px'
                                fontWeight='400'
                            />
                        </FlexBox>
                    </FlexBox>
                    <FlexBox justifyContent='space-between' flexWrap='wrap'>
                        <FlexBox maxWidth='557px' direction='column' marginTop='16px'>
                            <CustomText
                                text={`<strong>*</strong>Description:`}
                                fontSize='21px'
                                fontWeight='600'
                            />
                            <CustomTextArea
                                marginTop='8px'
                                placeholder='Write club description here'
                                maxWidth='557px'
                                height="117px"
                                maxLength={300}
                                value={clubInputData.description}
                                onChange={(e) => {

                                    setClubInputData((prev) => ({
                                        ...prev,
                                        description: e.target.value
                                    }));

                                }}
                            />
                            <FlexBox
                                direction="row-reverse"
                                justifyContent='end'
                            >
                                <CustomText
                                    text={`${clubInputData.description.length} of 300`}
                                    color='#9e9e9e'
                                    paddingRight='10px'
                                    fontSize='16px'
                                    fontWeight='400'
                                />
                            </FlexBox>
                        </FlexBox>
                        <FlexBox maxWidth='557px' direction='column' fontSize='21px' fontWeight='600' marginTop='16px'>
                            <CustomText
                                text={`<strong>*</strong>Upload cover image:`}
                                fontSize='21px'
                                fontWeight='600'
                            />


                            <CustomFile>
                                <CustomImage
                                    image={UPLOAD_ICON}
                                    width='32px'
                                    height='32px'
                                />
                                <span>
                                    {
                                        clubInputData.image ? clubInputData.image.split("amazonaws.com/")[1] :
                                            `Upload Image`
                                    }
                                </span>
                                <input
                                    type="file"
                                    //   className="d-none"
                                    // @ts-ignore
                                    onChange={(e) => uploadFile(e.target.files[0], 0)}
                                    // onChange={(e) => setVotingImage(e.target.files[0])}
                                    accept="image/*"
                                />
                            </CustomFile>

                            <FlexBox
                                direction="row-reverse"
                                justifyContent='end'

                            >
                                <CustomText
                                    text={`2 MB`}
                                    color='#9e9e9e'
                                    paddingRight='10px'
                                    fontSize='16px'
                                    fontWeight='400'
                                />
                            </FlexBox>
                        </FlexBox>
                    </FlexBox>

                    {/*** socials */}
                    <FlexBox justifyContent='space-between' flexWrap='wrap' marginTop='53px'>
                        <FlexBox maxWidth='557px' direction='column' marginTop='16px'>
                            <CustomText
                                text={`Website Link:`}
                                fontSize='21px'
                                fontWeight='600'
                            />
                            <CustomInput
                                marginTop='8px'
                                placeholder='https://'
                                maxWidth='557px'
                                value={clubInputData.website}
                                onChange={(e) => {
                                    setClubInputData((prev) => ({
                                        ...prev,
                                        website: e.target.value
                                    }));
                                }}
                            />

                        </FlexBox>
                        <FlexBox maxWidth='557px' direction='column' marginTop='16px'>
                            <CustomText
                                text={`Instagram`}
                                fontSize='21px'
                                fontWeight='600'
                            />
                            <CustomInput
                                marginTop='8px'
                                placeholder='@'
                                maxWidth='557px'
                                value={clubInputData.instagram}
                                onChange={(e) => {
                                    setClubInputData((prev) => ({
                                        ...prev,
                                        instagram: e.target.value
                                    }));
                                }}
                            />
                        </FlexBox>
                    </FlexBox>

                    <FlexBox justifyContent='space-between' flexWrap='wrap'>
                        <FlexBox maxWidth='557px' direction='column' marginTop='16px'>
                            <CustomText
                                text={`X`}
                                fontSize='21px'
                                fontWeight='600'
                            />
                            <CustomInput
                                marginTop='8px'
                                placeholder='@'
                                maxWidth='557px'
                                value={clubInputData.twitter}
                                onChange={(e) => {
                                    setClubInputData((prev) => ({
                                        ...prev,
                                        twitter: e.target.value
                                    }));
                                }}
                            />

                        </FlexBox>
                        <FlexBox maxWidth='557px' direction='column' marginTop='16px'>
                            <CustomText
                                text={`Discord`}
                                fontSize='21px'
                                fontWeight='600'
                            />
                            <CustomInput
                                marginTop='8px'
                                placeholder='@'
                                maxWidth='557px'
                                value={clubInputData.discord}
                                onChange={(e) => {
                                    setClubInputData((prev) => ({
                                        ...prev,
                                        discord: e.target.value
                                    }));
                                }}
                            />
                        </FlexBox>
                    </FlexBox>

                </FlexBox>
                <FlexBox
                    marginTop='76px'
                    justifyContent='end'
                    mdDirection='row-reverse'
                    mdJustifyContent='end'
                >
                    <CustomButton
                        text='Create'
                        onClick={() => { createNewClub() }}
                        width='407px'
                        height='65px'
                        fontSize='30px'
                        fontWeight='600'
                        smWidth='171px'
                        smHeight='42px'
                        smFontSize='16px'
                        disabled={
                            !clubInputData.image || !clubInputData.club_name || !clubInputData.description
                        }
                    />
                </FlexBox>
            </ModalBody>

        </StyledModal>
    )
}

export default CreateClubModal