import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { FlexBox } from '../common/FlexBox';
import CustomText from '../common/CustomText';
import CustomLinkButton from 'components/common/CustomLinkButton';
import CustomBorderButton from 'components/common/CustomBorderButton';


const StyledModal = styled(Modal)`
  .modal-dialog{
    margin: auto;
    max-width: 840px;
    width: 100%;
    background: transparent;
    border-radius: 16px;
    @media screen and (max-width: 550px) {
      max-width: 100%;
      height: 100vh;
    }
  }
  .modal-header{
    border-bottom: none;
  }
  .connect-success-content {
    background: #fae39c;
    border-radius: 3px;
    width: 100%;
    overflow: hidden;
    border: none;
    height: 540px;
    @media screen and (max-width: 550px) {
      height: 100%;
    }
  }
`;

const ModalBody = styled(Modal.Body)`
  &.modal-body{
    padding-top: 118px;
  }
`

interface Props {
  show: boolean;
  onClose: () => void;
}
const StakingAccessModal = ({ show, onClose }: Props) => {
  return (
    <StyledModal show={show} onHide={onClose} centered contentClassName="connect-success-content">
      <Modal.Header>

      </Modal.Header>
      <ModalBody>
        <FlexBox>
          <CustomText
            fontFamily="Inconsolata"
            maxWidth='554px'
            text={`In order to access this page, you must own a Bombers NFT.`}
            fontSize='38px'
            lineHeight='100%'
            fontWeight='700'
            textAlign='center'
            mdFontSize='31px'
            mdLineHeight='120%'
          />
        </FlexBox>
        <FlexBox
          direction='column'
          gap="16px"
          alignItems='center'
          justifyContent='center'
          marginTop='80px'
        >
          <CustomLinkButton
            text="Go to Wallet"
            link="/my-profile"
            justifyContent='center'
            width='300px'
            smWidth='98px'
            smHeight='32px'
            height="48px"
            fontSize='12px'
            fontWeight='600'
            smFontSize='12px'
          />
          <CustomBorderButton
            text="Close"
            width='300px'
            height="48px"
            fontSize='12px'
            fontWeight='600'
            smFontSize='12px'
            onClick={() =>{
              window.location.href = "/"
            }}
          />
        </FlexBox>
      </ModalBody>
    </StyledModal>
  )
}

export default StakingAccessModal