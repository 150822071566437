import axios from "axios";
import { GET_CLUBS_API, GET_MY_NFTS_API, UPDATE_USER_DATA_API } from "constants/api.constants";
import { infoAlert } from "hooks/alert";

// get clubs data from AWS (Clubs table)
export async function getClubs() {
    const options = {
        url: GET_CLUBS_API,
    };
    try {
        const response = await axios.request(options);

        return response.data;
    } catch (error) {
        console.error("getClubs err", error);
    }
}

// create club
export async function createClub(reqData) {
    const url = "https://d29nyuc4ej.execute-api.us-west-2.amazonaws.com/default";

    try {
        const response = await axios.post(url, JSON.stringify(reqData));
        return response.status;
    } catch (error) {
        console.error("createClub err", error);
    }
}


// create event
export async function createEvent(reqData) {
    const url = "https://h5a8xyp2h2.execute-api.us-west-2.amazonaws.com/test";

    try {
        const response = await axios.post(url, JSON.stringify(reqData));
        return response.status;
    } catch (error) {
        console.error("createEvent err", error);
    }
}


// get Events
export async function getEvents() {
    const options = {
        url: "https://9siyc4wqk5.execute-api.us-west-2.amazonaws.com/events",
    };
    try {
        const response = await axios.request(options);
        return response.data;
    } catch (error) {
        console.error("getEvents err", error);
    }
}

// get nft data by address from 3rd party api
export async function getMyNFTs(wallet: string) {
    const options = {
        url: GET_MY_NFTS_API + wallet,
    };
    try {
        const response = await axios.request(options);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

export const updateUserProfile = async (reqData) => {
    const url = "https://c7uwl2c9qh.execute-api.us-west-2.amazonaws.com/put-users";
    const response = await axios.post(url, JSON.stringify(reqData));
    return response;
}


export const getNumOfHolders = async () => {
    const url = "https://api.opencnft.io/2/collection/848838af0c3ab2e3027d420e320c90eb217f25b8b097efb4378e90f5"
    const response = await axios.get(url,
        {
            headers: {
                'X-Api-Key': 'ocnft_6478b2360e42f6648c04c200',
                "Content-Type": "application/json",
            }
        })
    if (response && response.data) {
        return response.data.holders;
    }
}

export const storeEmails = async (address: string) => {
    const url = "https://88vl7gf678.execute-api.us-west-2.amazonaws.com/emails"
    try {
        const response = await axios.post(url, JSON.stringify({
            email: address
        }));
        return response.data.statusCode;
    } catch (error) {
        console.error("createClub err", error);
    }
}

const url_get_emails = "https://q345yid2tc.execute-api.us-west-2.amazonaws.com/items"