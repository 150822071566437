import styled from 'styled-components';


interface CustomTextAreaStyleType {
    className?: string;
    placeholder?: string;
    value?: string;
    onChange?: any;
    type?: string;
    gap?: string;
    color?: string;
    justifyContent?: string;
    direction?: string;
    alignItems?: string;
    marginBottom?: string;
    marginTop?: string;
    maxWidth?: string;
    fontSize?: string;
    lineHeight?: string;
    fontWeight?: string;
    mdDirection?: string;
    mdMarginBottom?: string;
    mdMarginTop?: string;
    mdGap?: string;
    mdFontSize?: string;
    mdLineHeight?: string;
    mdFontWeight?: string;
    padding?: string;
    mdPadding?: string;
    border?: string;
    borderRadius?: string;
    height?: string;
    mdHeight?: string;
    maxLength?: number;
}


export const CustomTextAreaStyle = styled.textarea<CustomTextAreaStyleType>`
  max-width: ${(props) => props.maxWidth};
  height: ${(props) => props.height};
  width: 100%;
  color: ${(props) => props.color || '#9e9e9e'};
  flex-direction: ${(props) => props.direction || 'row'};
  gap: ${(props) => props.gap || '0px'};
  margin-bottom: ${(props) => props.marginBottom || '0px'};
  margin-top: ${(props) => props.marginTop || '0px'};
  font-weight: ${(props) => props.fontWeight || '400'};
  font-size: ${(props) => props.fontSize || '18px'};
  line-height: ${(props) => props.lineHeight || '22px'};
  padding: ${(props) => props.padding || '8px 10px 8px 10px'};
  border: ${(props) => props.border || '2px solid #4f4f4f'};
  border-radius: ${(props) => props.borderRadius || '3px'};
  &.three-dots{
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: ${(props) => props.mdMarginBottom || '0px'};
    margin-top: ${(props) => props.mdMarginTop || '0px'};
    gap: ${(props) => props.mdGap || props.gap};
    font-weight: ${(props) => props.mdFontWeight || '400'};
    font-size:${(props) => props.mdFontSize || '18px'};
    line-height: ${(props) => props.mdLineHeight || '26px'};
    padding: ${(props) => props.mdPadding};
    height: ${(props) => props.mdHeight};
  }
`
const CustomTextArea = ({
    placeholder,
    maxWidth,
    color,
    direction,
    justifyContent,
    alignItems,
    gap,
    marginBottom,
    marginTop,
    fontWeight,
    fontSize,
    lineHeight,
    mdMarginBottom,
    mdMarginTop,
    mdGap,
    mdFontWeight,
    mdFontSize,
    mdLineHeight,
    className,
    type,
    value,
    onChange,
    border,
    borderRadius,
    padding,
    mdPadding,
    height,
    mdHeight,
    maxLength
}: CustomTextAreaStyleType) => {
    return (
        <CustomTextAreaStyle
            placeholder={placeholder}
            type={type}
            value={value}
            onChange={onChange}
            maxLength= {maxLength}
            maxWidth={maxWidth}
            color={color}
            direction={direction}
            justifyContent={justifyContent}
            alignItems={alignItems}
            gap={gap}
            marginBottom={marginBottom}
            marginTop={marginTop}
            fontWeight={fontWeight}
            fontSize={fontSize}
            lineHeight={lineHeight}
            mdMarginBottom={mdMarginBottom}
            mdMarginTop={mdMarginTop}
            mdGap={mdGap}
            mdFontWeight={mdFontWeight}
            mdFontSize={mdFontSize}
            mdLineHeight={mdLineHeight}
            className={className}
            border={border}
            borderRadius={borderRadius}
            padding={padding}
            mdPadding={mdPadding}
            height={height}
            mdHeight={mdHeight}
        />
    )
}

export default CustomTextArea;