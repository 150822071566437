import _NFT_FILTER_DATA from './nftfilter.data.json'
import _TEST_NFT_DATA from './_testnft.data.json';
import _BOMBER_NFT_DATA from './nft.data.json';
import _TERM_CONDITIONS_DATA from './termconditions.data.json';
import _PRIVACY_POLICY_DATA from './privacypolicy.data.json';
import _NFT_ATTRIBUTE_DATA from './nft.attribute.data.json';

export const NFT_FILTER_DATA = _NFT_FILTER_DATA
export const TEST_NFT_DATA = _TEST_NFT_DATA
export const TERM_CONDITIONS_DATA = _TERM_CONDITIONS_DATA
export const PRIVACY_POLICY_DATA = _PRIVACY_POLICY_DATA
export const BOMBER_NFT_DATA = _BOMBER_NFT_DATA
export const NFT_ATTRIBUTE_IMAGES = _NFT_ATTRIBUTE_DATA