import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { FlexBox } from '../common/FlexBox';
import CustomText from '../common/CustomText';
import CustomButton from '../common/CustomButton';
import { useMedia } from 'react-use';
import { useState } from 'react';
import CustomImage from 'components/common/CustomImage';
import {  CONNECT_ICON, DISCORD_BLACK_ICON, DISCORD_WHITE_ICON, LOCATION_ICON, TWITTER_ICON, WEBSITE_ICON } from 'constants/image.constants';
import SocialImageLink from 'components/common/SocialImageLink';
import axios from 'axios';
import { useWalletConnect } from 'hooks/WalletConnect';
import { infoAlert } from 'hooks/alert';
import CustomLinkButton from 'components/common/CustomLinkButton';


const StyledModal = styled(Modal)`
  .modal-dialog{
    margin: auto;
    max-width: 1351px;
    width: 100%;
    
    background: transparent;
    border-radius: 16px;
    
    @media screen and (max-width: 550px) {
      max-width: 100%;

    }
  }
  .modal-header{
    border-bottom: none;
    align-items: start;
    &.btn-close{
        font-size: 40px !important;
    }

  }
  .connect-success-content {
    background: white;
    border-radius: 3px;
    width: 100%;
    overflow: hidden;
    border: none;
    
    @media screen and (max-width: 550px) {
    //   height: 100%;
    }
  }
`;

const ModalBody = styled(Modal.Body)`
    padding: 18px 47px 28px 28px; 
    &.modal-body{
    }
`

const CloseButton = styled.button`
    border:none;
    width: 407px;
    height: 65px;
    background: #282828;
    border-radius: 4px;
    color: white;
    text-align: center;
    font-family: Open Sans;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    // position: absolute;
    // bottom: 95px;
    @media screen and (max-width: 768px) {
        width: 100%;
        height: 42px;
        font-size: 16px;
        bottom: 21px;
    }
`

interface Props {
  show: boolean;
  onClose: () => void;
  data: any;
  getClubsData: any;
}
const ClubRegisterModal = ({ show, onClose, data, getClubsData }: Props) => {
  const [step, setStep] = useState<number>(1);
  const isMobile = useMedia('(max-width: 550px)');
  const { myWalletAddress } = useWalletConnect()

  const registerClub = async () => {
    const url = "https://d29nyuc4ej.execute-api.us-west-2.amazonaws.com/default";
    const reqData = {
      id: data.id,
      entries: myWalletAddress
    }

    try {
      const response = await axios.post(url, JSON.stringify(reqData));
      console.log("response", response)
      if (response.status === 200) {
        getClubsData()
        onClose()
        infoAlert("You successfully registered at the club!");
      }
    } catch (error) {
      console.error("createClub err", error);
    }
  }

  return (
    <StyledModal show={show} onHide={onClose} centered contentClassName="connect-success-content">
      <Modal.Header closeButton>
      </Modal.Header>
      <ModalBody>
        <CustomText
          fontFamily='Inconsolata'
          fontSize='67px'
          fontWeight='700'
          text={data.club_name && data.club_name}
          lineHeight='100%'
          mdFontSize='21px'
        />
        <CustomText
          text={`Hosted by ${data.organizer && data.organizer.slice(0, 10)}...`}
          marginTop='28px'
          width='default'
          bgColor='none'
          height='default'
          fontSize='28px'
          fontWeight='400'
          mdFontSize='14px'
          mdMarginTop='33px'
        />
        {
          isMobile &&
          <CustomText
            text={`${data.entries.length} people going`}
            color="#58c8be"
            fontSize='36px'
            fontWeight='400'
            mdFontSize='14px'
            className='text-nowrap'
          />
        }
        <FlexBox marginTop='71px' direction='column' gap="18px" mdGap='8px' mdMarginTop='33px'>
          <FlexBox justifyContent='start' gap="16px" alignItems='center' mdDirection='row' mdJustifyContent='start'>
            <CustomImage
              image={WEBSITE_ICON}
              width='58px'
              height='58px'
              mdHeight='19px'
              mdWidth='19px'
            />
            <CustomText
              text={data.website && data.website}
              fontWeight='400'
              fontSize='28px'
              mdFontSize='14px'
            />
          </FlexBox>

        </FlexBox>


        <CustomText
          text={data.description && data.description}
          fontSize='28px'
          fontWeight='400'
          marginTop='90px'
          mdMarginTop='32px'
          mdFontSize='16px'
        />
        <FlexBox
          justifyContent='space-between'
          alignItems='center'
          marginTop='24px'
        >
          {
            !isMobile &&
            <CustomText
              text={`${data.entries.length} members`}
              color="#58c8be"
              fontSize='36px'
              fontWeight='700'
              className='text-nowrap'
            />
          }

          <FlexBox
            justifyContent='start'
            paddingLeft='74px' marginTop='44px' gap='25px'
            width='auto'
            mdDirection='row'
          >
            {
              data.twitter && data.twitter !== "" &&
              <SocialImageLink
                image={TWITTER_ICON}
                width='63px'
                height='63px'
                mdWidth='27px'
                mdHeight='24px'
                link={data.twitter}
              />
            }
            {
              data.instagram && data.instagram !== "" &&
              <SocialImageLink
                image={CONNECT_ICON}
                width='63px'
                height='63px'
                mdWidth='27px'
                mdHeight='24px'
                link={data.instagram}
              />
            }
            {
              data.discord && data.discord !== "" &&
              <SocialImageLink
                image={DISCORD_BLACK_ICON}
                width='63px'
                height='63px'
                mdWidth='27px'
                mdHeight='24px'
                link={data.discord}
              />
            }

          </FlexBox>
        </FlexBox>
        <FlexBox
          marginTop='76px'
          justifyContent='end'
        >
          <CustomButton
            width='407px'
            height='65px'
            fontSize='30px'
            fontWeight='600'
            smWidth='100%'
            smHeight='42px'
            smFontSize='16px'
            disabled={data.organizer === myWalletAddress || data.entries.includes(myWalletAddress)}
            onClick={() => {
              registerClub();
            }}
            text={`Register`}
          />
        </FlexBox>
      </ModalBody>
    </StyledModal>
  )
}

export default ClubRegisterModal