import styled from 'styled-components'

export const ExplorerFiltering = styled.div`
  width: 100%;
  max-width: 240px;
`

export const ExplorerFilteringSelection = styled.select`
  background: white;
  width: 240px;
  height: 52px;
  margin-top: 15px;
  border: 1px solid black;
  font-size: 16px;
  line-height: normal;
  color: #9E9E9E;
  font-weight: 400;
  border-radius: 3px;
  padding-left: 23px;
  background-image: url('/assets/images/icons/select_down.svg');
  background-repeat: no-repeat;
  background-position: right center;
  appearance: none; /* Remove default styles (not supported in all browsers) */
  -webkit-appearance: none;
  ::-ms-expand {
    display: none; /* Hide arrow icon in IE/Edge */
  }
`

export const BomberNFTsList = styled.div`
  padding: 0px 15px;
  max-width: 890px;
  width: 100%;
  gap: 47px;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
`

export const BomberNFT = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
`
export const BomberImage = styled.img`
  width: 170px;
  height: 170px;
  background: #D9D9D9;
`

export const BomberAsset = styled.div`
  border: 1px solid black;
  color: black;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  line-height: 41px;
  word-wrap: break-word;
  background: white;
  width: 144px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`