import React, { useContext, useEffect, useState } from 'react'
import Calendar from "react-calendar";
import { Container, PageWrapper } from '../../../styles/GlobalStyles'
import { FlexBox } from '../../../components/common/FlexBox'
import CustomButton from '../../../components/common/CustomButton'
import CustomText from '../../../components/common/CustomText'
import EventCard from '../../../components/card/EventCard'
import CreateEventSuccessModal from 'components/modal/CreateEventSuccessModal';
import EventRegisterModal from 'components/modal/EventRegisterModal';
import CustomImage from 'components/common/CustomImage';
import { BG_CALENDAR_IAMGE } from 'constants/image.constants';
import { useMedia } from 'react-use';
import CustomInput from 'components/common/CustomInput';
import { getEvents } from 'api/api';
import CreateEventModal from 'components/modal/CreateEventModal';
import { GlobalProvider } from 'App';
import { useWalletConnect } from 'hooks/WalletConnect';
import { timestampToDayTime, timestampToDayTime2 } from 'hooks/date';
import "react-calendar/dist/Calendar.css";
type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];
const CommunityEvents = () => {
  const [events, setEvents] = useState<EventType[]>([])
  const [displayEvents, setDisplayEvents] = useState<EventType[]>([])
  const [cal_date, onChange] = useState(new Date());
  const [eventInputData, setEventInputData] = useState<EventType>({
    id: '',
    event_name: '',
    description: '',
    image: '',
    website: '',
    instagram: '',
    twitter: '',
    discord: '',
    event_location: '',
    event_time: 0,
    entries: {},
    organizer: '',
    endTimeString: ''
  }
  )
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [activeRegisterEvent, setActiveRegisterEvent] = useState<EventType>();
  const [activeDate, setActiveDate] = useState<number>(Date.now());

  const { myNFTs, loading } = useContext(GlobalProvider);
  const { myWalletAddress } = useWalletConnect()
  const isMobile = useMedia('(max-width: 550px)');

  const getEventsData = async () => {
    const events = await getEvents();
    setEvents(events)
  };

  useEffect(() => {
    getEventsData()
  }, [])

  useEffect(() => {
    if (myWalletAddress !== undefined) {
      setEventInputData((prev) => ({
        ...prev,
        organizer: myWalletAddress
      }));
    }
  }, [myWalletAddress])

  useEffect(() => {
    // Set the target date to Sat Dec 23 2023 (00:00:00 GMT-0400 Venezolaanse tijd)
    const targetDate = new Date(cal_date);
    setActiveDate(targetDate.getTime())

    // Calculate the start and end timestamps for the entire day
    const startOfDay = targetDate.setHours(0, 0, 0, 0);
    const endOfDay = targetDate.setHours(23, 59, 59, 999);

    const filteredTimestamps = events.filter(event => {
      const timestampDate = new Date(event.event_time).getTime();
      return timestampDate >= startOfDay && timestampDate <= endOfDay;
    });
    setDisplayEvents(filteredTimestamps)

  }, [cal_date, events])
  return (
    <PageWrapper>
      {

        <Container
          maxWidth='1380px'
          paddingLeft='16px' paddingRight='16px'
        >
          <FlexBox direction='column' mdJustifyContent='center' mdAlignItems='center'>
            <CustomText
              text="Events"
              fontSize='50px'
              fontWeight='700'
              mdFontSize='38px'
            />
            <CustomButton
              text="Create Event"
              marginTop='28px'
              smWidth='171px'
              smHeight='42px'
              disabled={myNFTs.length === 0}
              onClick={() => {
                setShowCreateModal(true)
              }}
            />
          </FlexBox>
          <FlexBox
            marginTop='54px'
            gap="142px" mdJustifyContent='center'
            mdAlignItems='center'
            mdGap='54px'
          >
            <Calendar
              // @ts-ignore
              onChange={onChange}
              value={cal_date}
              view={"month"}
            />
            {
              isMobile &&
              <CustomInput
                type="text"
                placeholder="Search Events"
                border='none'
                bgColor={`#e7e7e7`}
                height='48px'
              />
            }
            <FlexBox direction='column' gap="69px">

              {
                displayEvents && displayEvents.length > 0 &&
                displayEvents.map((event: EventType, o) => {
                  return (
                    <EventCard
                      key={o}
                      data={event}
                      setShowRegisterModal={setShowRegisterModal}
                      disabled={myNFTs.length === 0}
                      setActiveRegisterEvent={setActiveRegisterEvent}
                      activeDate={activeDate}
                    />
                  )
                })
              }

            </FlexBox>
          </FlexBox>
        </Container>
      }
      {
        showCreateModal &&
        <CreateEventModal
          show={showCreateModal}
          onClose={() => setShowCreateModal(false)}
          setShowSuccessModal={setShowSuccessModal}
          eventInputData={eventInputData}
          setEventInputData={setEventInputData}
          getEventsData = {getEventsData}
        />
      }
      {
        showSuccessModal &&
        <CreateEventSuccessModal
          show={showSuccessModal}
          onClose={() => {
            setShowSuccessModal(false);
            getEvents()
          }}
          data={eventInputData}
        />
      }
      {
        showRegisterModal &&
        <EventRegisterModal
          show={showRegisterModal}
          onClose={() => setShowRegisterModal(false)}
          data={activeRegisterEvent}
          getEventsData= {getEventsData}
        />
      }
    </PageWrapper>

  )
}

export default CommunityEvents