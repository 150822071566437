import React, { createContext, lazy, Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, useLocation, useSearchParams } from 'react-router-dom';
import MainLayout from './components/layouts/main';
import AppLoader from './components/Loader';
import 'index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-calendar/dist/Calendar.css';
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer } from 'react-toastify';
import Explorer from 'pages/Explorer';
import Leaderboards from './pages/Leaderboards';
import CommunityEvents from './pages/Community/Events';
import CommunityClub from './pages/Community/Club';
import HostEvent from './pages/Community/Events/HostEvent';
import MyProfile from 'pages/MyProfile';
import EditProfile from 'pages/EditProfile';
import Bounties from 'pages/Staking/Bounties';
import Staking from 'pages/Staking/StakingBounties';
import StakingMain from 'pages/Staking/StakingMain';
import { GET_MY_NFTS_API, GET_USER_DATA_API, UPDATE_USER_DATA_API } from 'constants/api.constants';
import axios from 'axios';
import { useWalletConnect } from 'hooks/WalletConnect';
import { DEFAULT_AVATAR_ICONS } from 'constants/image.constants';
import CreateNewClub from 'pages/Community/Club/CreateNeweClub';
import { TEST_POLICY_ID } from 'constants/_test.contants';
import { TEST_NFT_DATA } from 'constants/document';
import PrivacyPolicy from 'pages/PrivacyTerms/PrivacyPolicy';
import TermsCondition from 'pages/PrivacyTerms/TermsCondition';
import { MAIN_POLICY_ID } from 'constants/_main.constant';
import { getMyNFTs } from 'api/api';
import User from 'pages/User';

const HomePage = lazy(() => import('./pages/Home'));



interface GlobalContextType {
  myNFTs: MyNFT[]; // Update with the correct type.
  setMyNFTs: (nfts: MyNFT[]) => void; // Update with the correct type.
  userData: UserDataType;
  setUserData: (data: UserDataType) => void;
  loading: boolean;
}

export const GlobalProvider = createContext<GlobalContextType>({} as GlobalContextType);

function App() {
  // console.log("window.location.pathname", window.location.pathname)

  const [myNFTs, setMyNFTs] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [userData, setUserData] = useState<UserDataType>({
    username: '',
    avatar: '',
    points: 0,
    bomber_username: '',
    bomber_avatar: '',
    crashr: '',
    twitter: '',
    discord: '',
    wallet: '', // Make sure to include the 'wallet' property.
    friends: [],
  });

  const { myWalletAddress, walletConnected } = useWalletConnect();


  const contextValues = {
    myNFTs,
    setMyNFTs,
    userData,
    setUserData,
    loading
  };

  const getMyNFTsData = async () => {
    try {
      const data = await getMyNFTs(myWalletAddress);
      if (Object.values(data).length > 0) {
        const filteredNFTs: any = Object.values(data).filter((nft: MyNFT) => nft.asset.slice(0, 56) === MAIN_POLICY_ID);
        setMyNFTs(filteredNFTs);
        setLoading(true)
      } else {
        setLoading(true)
      }
    } catch (error) {
      console.error('Error fetching NFTs:', error);
    }
  };

  const getUserByWallet = async () => {
    try {
      const res = await axios.get(GET_USER_DATA_API + myWalletAddress);
      // console.log("user data", res)
      if (res.data === "") {
        const data = {
          id: myWalletAddress,
          username: myWalletAddress,
          avatar: DEFAULT_AVATAR_ICONS[Math.floor(Math.random() * 4)],
          points: 0,
          wallet: myWalletAddress, // Provide the wallet address here.
        };
        const response = await axios.post(UPDATE_USER_DATA_API, JSON.stringify(data));
        console.log("response", response)
        


      } else {
        setUserData(res.data);
      }
    } catch (err) {
      console.error('Error fetching user data:', err);
    }
  };

  useEffect(() => {
    if (walletConnected) {
      if (myWalletAddress === undefined) {
        setLoading(true)
      } else {
        getMyNFTsData();
        getUserByWallet();
      }
    }
  }, [myWalletAddress, walletConnected]);



  return (
    
    <GlobalProvider.Provider value={contextValues}>
      <BrowserRouter>
        <Suspense fallback={<AppLoader />}>
          <Routes>
            <Route element={<MainLayout />}>
              <Route path="/" element={<HomePage />} />
              <Route path="/explorer" element={<Explorer />} />
              <Route path="/staking" element={<Staking />} />
              {
                myWalletAddress && myNFTs && myNFTs.length > 0 &&
                <>
                  <Route path="/staking-main" element={<StakingMain />} />
                  <Route path="/bounties" element={<Bounties />} />
                </>
              }
              <Route path="/leaderboards" element={<Leaderboards />} />

              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/term-conditions" element={<TermsCondition />} />

              <Route path="/users/:address" element={<User />} />

              {
                myWalletAddress &&
                <>
                  <Route path="/community-events" element={<CommunityEvents />} />
                  <Route path="/community-club" element={<CommunityClub />} />
                  <Route path="/create-new-club" element={<CreateNewClub />} />
                  <Route path="/host-event" element={<HostEvent />} />
                  <Route path="/my-profile" element={<MyProfile />} />
                  <Route path="/edit-profile" element={<EditProfile />} />
                </>
              }
            </Route>
          </Routes>
          <ToastContainer />
        </Suspense>
      </BrowserRouter>
    </GlobalProvider.Provider>
  );
}

export default App;