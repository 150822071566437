import CustomText from 'components/common/CustomText';
import { PRIVACY_POLICY_DATA } from 'constants/document'
import parse from 'html-react-parser';
import { Container, PageWrapper } from 'styles/GlobalStyles';

const PrivacyPolicy = () => {
    return (
        <PageWrapper>
            <Container maxWidth='1101px' paddingLeft='25px' paddingRight='25px'>

                <CustomText
                    text="Privacy Policy"
                    fontSize='50px'
                    fontWeight='700'
                    color='black'
                    mdFontSize='38px'
                    textAlign='center'
                    mdLineHeight='120%'
                    marginBottom='67px'
                />
                {
                    PRIVACY_POLICY_DATA.map((item, index) => {
                        return (
                            <div key={index} >
                                <div> {parse(item)} </div>
                                <br />
                            </div>
                        )
                    })
                }
            </Container>
        </PageWrapper>
    )
}

export default PrivacyPolicy
