import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { FlexBox } from '../common/FlexBox';
import CustomText from '../common/CustomText';
import CustomButton from '../common/CustomButton';
import CustomLinkButton from 'components/common/CustomLinkButton';
import CustomBorderButton from 'components/common/CustomBorderButton';
import { useContext } from 'react';
import { GlobalProvider } from 'App';
import ExplorerNFTCard from 'components/pages/explorer/ExplorerNFTCard';
import CustomImage from 'components/common/CustomImage';
import { useWalletConnect } from 'hooks/WalletConnect';
import axios from 'axios';
import { infoAlert } from 'hooks/alert';


const StyledModal = styled(Modal)`
  .modal-dialog{
    margin: auto;
    max-width: 840px;
    width: 100%;
    
    background: transparent;
    border-radius: 16px;
    @media screen and (max-width: 550px) {
      max-width: 100%;
      height: 100vh;
    }
  }
  .modal-header{
    border-bottom: none;
  }
  .connect-success-content {
    background: #fae39c;
    border-radius: 3px;
    width: 100%;
    overflow: hidden;
    border: none;
    @media screen and (max-width: 550px) {
      height: 100%;
    }
  }
`;

const ModalBody = styled(Modal.Body)`
  &.modal-body{
    padding-bottom: 118px;
  }
`

const NFTList = styled.div`
    margin-top: 88px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    gap: 31px;
    min-height: 120px;
    max-height: 450px;
    overflow-y: scroll;

    font-family: 'Inconsolata';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #404040;
    @media screen and (max-width: 768px) {
      gap: 15px;
    }
    ::-webkit-scrollbar {
      width: 9px; /* set width of scrollbar */
      // height: 28px;
    }
    
    ::-webkit-scrollbar-track {
      background:none; /* set background color of track */
    }
    
    ::-webkit-scrollbar-thumb {
      background-color: black; /* set background color of thumb */
      border-radius: 5px; /* set border radius of thumb */
    }
    
    ::-webkit-scrollbar-thumb:hover {
      background-color: #555; /* set background color of thumb on hover */
    }
`

interface Props {
  show: boolean;
  onClose: () => void;
  // message: string;
}

const SelectNFTModal = ({ show, onClose }: Props) => {
  const { myNFTs, userData, setUserData, loading } = useContext(GlobalProvider);
  const { myWalletAddress } = useWalletConnect()
  const updateAvatarByNFT = async (imageId: string) => {


    const data = {
      id: myWalletAddress,
      username: userData.username,
      avatar: imageId,

    };
    const response = await axios.post("https://5200m71emb.execute-api.us-west-2.amazonaws.com/test/users", JSON.stringify(data));
    if (response) {

      // Assuming setUserData is a function that updates user data.

      // @ts-ignore
      setUserData((prev) => ({
        ...prev,
        bomber_avatar: imageId, // Assuming imageId is defined elsewhere.
      }));

      // Assuming infoAlert is a function to display an alert or notification.
      infoAlert("Your avatar is set by the NFT you chose");
    }
  }
  return (
    <StyledModal show={show} onHide={onClose} centered contentClassName="connect-success-content">
      <Modal.Header closeButton>
      </Modal.Header>
      <ModalBody>
        <FlexBox>
          <CustomText
            fontFamily="Inconsolata"
            maxWidth='554px'
            text={`Please select an NFT`}
            fontSize='50px'
            lineHeight='100%'
            fontWeight='700'
            textAlign='center'
            mdFontSize='31px'
            mdLineHeight='120%'
          />
        </FlexBox>
        <NFTList>
          {
            loading && myNFTs && myNFTs.length > 0 && myNFTs.map((nft: MyNFT) => {
              let image =
                nft.image.replace(
                  "ipfs://",
                  "https://image-optimizer.jpgstoreapis.com/"
                )
              return (
                <>
                  <CustomImage
                    image={image}
                    width='200px'
                    height='200px'
                    onClick={() => {
                      updateAvatarByNFT(image)
                      onClose()
                    }}
                    cursor='pointer'
                  />
                </>
              )
            })
          }
          {
            loading && myNFTs && myNFTs.length === 0 && <div>You have no Bomber NFTs</div>
          }
        </NFTList>

        <FlexBox
          direction='column'
          gap="16px"
          alignItems='center'
          justifyContent='center'
          marginTop='106px'
        >
          <CustomButton
            text="Close"
            onClick={onClose}
            width='300px'
            height="48px"
            fontSize='12px'
            fontWeight='600'
            smWidth='98px'
            smHeight='32px'
            smFontSize='12px'
          />

        </FlexBox>
      </ModalBody>
    </StyledModal>
  )
}

export default SelectNFTModal