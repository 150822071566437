import { GlobalProvider } from 'App'
import ComingSoon from 'components/ComingSoon'
import CustomButton from 'components/common/CustomButton'
import CustomLinkButton from 'components/common/CustomLinkButton'
import CustomRouterLinkButton from 'components/common/CustomRouterLinkButton'
import CustomText from 'components/common/CustomText'
import { FlexBox } from 'components/common/FlexBox'
import ConnectWalletModal from 'components/modal/ConnectWalletModal'
import StakingAccessModal from 'components/modal/StakingAccessModal'
import StakingCongratsModal from 'components/modal/StakingCongratsModal'
import { useWalletConnect } from 'hooks/WalletConnect'
import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { PageWrapper, Container } from 'styles/GlobalStyles'

const StakingBountiesCard = styled.div`
    border: 2px solid black;
    max-width: 426px;
    width: 100%;
    display: flex;
    padding-top: 84px;
    padding-left: 79px;
    padding-bottom: 130px;
    flex-direction: column;
    @media screen and (max-width: 768px) {
        padding-top: 24px;
        padding-left: 15px;
        padding-bottom: 25px;
    }
`

const StakingBountiesCard2 = styled.div`
    border: 2px solid black;
    max-width: 426px;
    width: 100%;
    display: flex;
    padding-top: 84px;
    padding-left: 54px;
    padding-bottom: 130px;
    flex-direction: column;
    @media screen and (max-width: 768px) {
        padding-top: 24px;
        padding-left: 15px;
        padding-bottom: 25px;
    }
`

const StakingBounties = () => {
    const [showModal, setShowModal] = useState(true)
    const { myNFTs, loading } = useContext(GlobalProvider);
    const { myWalletAddress } = useWalletConnect()
    return (
        <PageWrapper>
            <Container
                maxWidth='1206px'
                paddingLeft='30px'
                paddingRight='30px'
            >
                {/* <ComingSoon /> */}
                {
                    myWalletAddress &&
                    <FlexBox gap="75px" mdGap='32px'>
                        <StakingBountiesCard>
                            <CustomText
                                text={`Staking`}
                                fontSize='50px'
                                fontWeight='700'
                                marginBottom='23px'
                                mdFontSize='38px'
                            />
                            <CustomText
                                fontSize='21px'
                                fontWeight='400'
                                text={`Stake your Bombers<br /> earn points.`}
                                width='289px'
                                height='88px'
                                marginBottom='4px'
                                mdFontSize='21px'
                                alignItems='start'
                            />
                            <CustomLinkButton
                                text="View All"
                                height='42px'
                                width='93px'
                                link="/staking-main"
                                smHeight='42px'
                                smWidth='93px'
                            />
                        </StakingBountiesCard>
                        <StakingBountiesCard2>
                            <CustomText
                                text={`Bounties`}
                                fontSize='50px'
                                fontWeight='700'
                                marginBottom='23px'
                                mdFontSize='38px'
                            />
                            <CustomText
                                fontSize='21px'
                                fontWeight='400'
                                text={`Accomplish community tasks <br/> to earn rewards.`}
                                width='289px'
                                height='88px'
                                marginBottom='4px'
                                mdFontSize='21px'
                                alignItems='start'
                            />
                            <CustomLinkButton
                                text="View All"
                                height='42px'
                                width='93px'
                                link="/Bounties"
                                smHeight='42px'
                                smWidth='93px'
                            />
                        </StakingBountiesCard2>
                    </FlexBox>
                }
                {
                    myNFTs && myNFTs.length === 0 && 
                    <StakingAccessModal
                        show={showModal}
                        onClose={() => { }}
                    />
                }
            </Container>
        </PageWrapper>
    )
}

export default StakingBounties
