import { GlobalProvider } from 'App';
import CustomButton from 'components/common/CustomButton';
import CustomText from 'components/common/CustomText';
import { FlexBox } from 'components/common/FlexBox';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components'
import { getRemainingTime } from 'hooks/date'

interface Props {
  data: MyNFT;
  index: number;
  stakeNFT: any;
  unStakeNFT: any;
  staked: boolean;
}

const NFTStakingCardStyle = styled.div`
  border-radius: 4px;
  max-width: 171px;
  background: white;
  position: relative;
  img{
    width: 171px;
    height: 171px;
    border-radius: 4px 4px 0px 0px;
  }
  @media screen and (max-width: 768px) {
    max-width: 124px;
    width: 100%;
    img{
      max-width: 124px;
      height: 124px;
      width: 100%;
    }
  }  
`

const RemainingBadge = styled.div`
  position: absolute;
  border-radius: 3px 0px 0px 3px;
  background: rgba(0, 0, 0, 0.50);
  z-index: 10;
  right: 0px;
  top: 6px;
  width: 116px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`

const NFTStakingCard = ({
  data, index, stakeNFT, unStakeNFT, staked
}: Props) => {
  const [remain, setRemain] = useState<string>()
  const { userData } = useContext(GlobalProvider)


  useEffect(() =>{
    if(userData.entries && data.name){
      // console.log("userData.entries, data.name", userData.entries, data.name, data)
      const remaining_time = getRewardsTimeByBomberID(userData.entries, data.name);
      // console.log("remaining_time", remaining_time)
      setRemain(remaining_time)
    }
  }, [userData.entries, data.name])

  // Function to get rewards time for a specific bomber_id
  const getRewardsTimeByBomberID = (entries: StakeEntry[], bomberID: string) => {

    for (const item of entries) {
      if (item.bomber_id === bomberID) {
        return getRemainingTime(item.rewards_time);
      }
    }
  }

  return (
    <NFTStakingCardStyle>
      {
        staked &&
        <RemainingBadge>
          {remain}
        </RemainingBadge>
      }
      <img
        src={
          data?.image.replace(
            "ipfs://",
            "https://image-optimizer.jpgstoreapis.com/"
          )}
        alt={`img-nft-${index}`}
      />

      <FlexBox
        bgColor='white'
        direction='column'
        gap="11px"
        justifyContent='center'
        alignItems='center'
        paddingTop='12px'
        paddingBottom='10px'
        paddingLeft='10px'
        paddingRight='10px'
        mdPaddingBottom='7px'
        mdPaddingTop='7px'
        borderRadius='0px 0px 4px 4px'
      >
        <CustomText
          text={
            data.name ? data.name : ''
          }
          color="black"
          lineHeight='41px'
          fontWeight='700'
          fontSize='18px'
          mdFontSize='14px'
          textAlign='center'
        />
        {
          staked ?
            <CustomButton
              onClick={() => {
                unStakeNFT(0, data)
              }}
              text="Unstake"
              bgColor='white'
              color="black"
              border="1px solid black"
              fontWeight='600'
              fontSize='16px'
              lineHeight='41px'
              width='100%'
              height='42px'
              smWidth='106px'
              smHeight='21px'
            />
            :
            <CustomButton
              onClick={() => {
                stakeNFT(0, data)
              }}
              text="Stake"
              bgColor='#282828'
              color="white"
              fontWeight='600'
              fontSize='16px'
              lineHeight='41px'
              width='100%'
              height='42px'
              smWidth='106px'
              smHeight='21px'
            />
        }
      </FlexBox>
    </NFTStakingCardStyle>
  )
}

export default NFTStakingCard