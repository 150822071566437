import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { FlexBox } from '../common/FlexBox';
import CustomText from '../common/CustomText';
import CustomButton from '../common/CustomButton';
import CustomImage from 'components/common/CustomImage';
import { SUCCESS_ICON } from 'constants/image.constants';


const StyledModal = styled(Modal)`
  .modal-dialog{
    margin: auto;
    max-width: 840px;
    width: 100%;
    
    background: transparent;
    border-radius: 16px;
    @media screen and (max-width: 550px) {
      max-width: 100%;
      height: 100vh;
    }
  }
  .modal-header{
    border-bottom: none;
  }
  .connect-success-content {
    background: #fae39c;
    border-radius: 3px;
    width: 100%;
    overflow: hidden;
    border: none;
    height: 540px;
    @media screen and (max-width: 550px) {
      height: 100%;
    }
  }
`;

const ModalBody = styled(Modal.Body)`
  &.modal-body{
  }
`



interface Props {
    show: boolean;
    onClose: () => void;
    assetID: string;
}
const StakingSuccessModal = ({ show, onClose, assetID }: Props) => {
    return (
        <StyledModal show={show} onHide={onClose} centered contentClassName="connect-success-content">


            <Modal.Header closeButton>

            </Modal.Header>
            <ModalBody>
                <FlexBox
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                    mdJustifyContent='start'
                    mdAlignItems='start'
                >
                    <CustomImage
                        image={SUCCESS_ICON}
                        width='68px'
                        height='68px'
                        mdHeight='43px'
                        mdWidth='43px'
                    />
                    <CustomText
                        text={`Congratulations!`}
                        fontSize='50px'
                        fontWeight='700'
                        marginTop='30.8px'
                        mdMarginTop='17px'
                    />
                    <CustomText
                        text="You have successfully started staking!"
                        marginTop='16px'
                        fontSize='16px'
                        fontWeight='400'
                        mdMarginTop='6px'
                        mdFontSize='16px'
                    />
                    <FlexBox
                        direction='column'
                        gap="19px"
                        justifyContent='center'
                        alignItems='center'
                        marginTop='21px'
                        mdJustifyContent='start'
                        mdAlignItems='start'
                        mdMarginTop='30px'
                        mdGap='22px'
                    >

                        <CustomText
                            text={assetID}
                            fontSize='21px'
                            mdFontSize='16px'
                            fontWeight='400'
                        />
                    </FlexBox>
                </FlexBox>
                <FlexBox
                    mdDirection='row-reverse'
                    mdJustifyContent='end'
                    marginTop='36px'
                >
                    <CustomButton
                        text="Close"
                        width='300px'
                        height="48px"
                        fontSize='12px'
                        fontWeight='600'
                        onClick={onClose}
                    />
                </FlexBox>
            </ModalBody>
        </StyledModal>
    )
}

export default StakingSuccessModal