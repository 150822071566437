import styled, { createGlobalStyle } from 'styled-components';
import '@fontsource/barlow';
import '@fontsource/barlow/200.css';
import '@fontsource/barlow/500.css';
import '@fontsource/barlow/600.css';
import '@fontsource/barlow/700.css';
import '@fontsource/barlow-condensed/500.css';


export const GlobalStyles = createGlobalStyle`
  background-color: #fef8e6;
  *,*::before,*::after{
    // background-color: #fef8e6;
    margin:0;
    padding:0;
  }
  body{
    background-color: #fef8e6;
    // min-height: 100vh;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
  }

  #root {
    position: relative;
  }
  .padding-right-112{
    padding-right: 112px;
  }
  .react-datepicker-ignore-onclickoutside{
    max-width: 557px;
    height: 41px;
    width: 100%;
    border-radius: 3px;
    border: 2px solid var(--greyscale-700, #9E9E9E);
    color: var(--greyscale-500, #9E9E9E);
    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }


  @import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
  /* LIGHT MODE + GENERAL */
  
  .react-calendar {
    border: unset;
    background-color: white;
    font-family: "DM Sans", sans-serif;
    padding: 12px 24px;
    border-radius: 8px;
  }
  .react-calendar__navigation__prev2-button {
    display: none;
  }
  .react-calendar__navigation__next2-button {
    display: none;
  }
  .react-calendar__navigation {
    align-items: center;
  }
  abbr[title] {
    border-bottom: none;
    -webkit-text-decoration: unset;
    text-decoration: unset;
    -webkit-text-decoration: unset;
    -webkit-text-decoration: unset;
    text-decoration: unset !important;
  }
  .react-calendar__navigation__prev-button {
    background-color: white !important;
    border-radius: 10px;
    min-width: 34px !important;
    height: 34px !important;
    color: #9E9E9E;
  }
  .react-calendar__navigation__next-button {
    background-color: white !important;
    border-radius: 10px;
    min-width: 34px !important;
    width: 34px !important;
    height: 34px !important;
    color: #9E9E9E;
  }
  .react-calendar__navigation__label {
    color: black;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inconsolata;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 100% */
    text-transform: capitalize;
  }
  .react-calendar__navigation__label:hover,
  .react-calendar__navigation__label:focus {
    background-color: unset !important;
    border-radius: 10px;
  }
  .react-calendar__tile {
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px !important;
    height: 34px !important;
    color: #1b2559;
  }
  .react-calendar__month-view__weekdays {
    border-radius: 10px;
    margin-bottom: 6px;
    color: var(--crashr-brand-greyscale-400, #9E9E9E);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Inconsolata;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 1.5px;
    text-transform: uppercase;

  }
  .react-calendar__tile--now,
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background-color: #f4f7fe;
    border-radius: 10px;
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #b0bbd5;
  }
  .react-calendar__tile--active,
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #4318ff;
    border-radius: 10px;
    color: white;
  }
  .react-calendar__tile--range,
  .react-calendar__tile--range:enabled:hover,
  .react-calendar__tile--range:enabled:focus {
    background: #f4f7fe;
    color: #4318ff;
    border-radius: 0px;
  }
  .react-calendar__tile--rangeStart,
  .react-calendar__tile--rangeStart:enabled:hover,
  .react-calendar__tile--rangeStart:enabled:focus {
    background: #4318ff;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    color: white;
  }
  .react-calendar__tile--rangeEnd,
  .react-calendar__tile--rangeEnd:enabled:hover,
  .react-calendar__tile--rangeEnd:enabled:focus {
    background: #4318ff;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    color: white;
  }
  
  /* DARK MODE */
  
  body.chakra-ui-dark .react-calendar {
    border-radius: 30px;
  }
  body.chakra-ui-dark .react-calendar__navigation__prev-button {
    color: black;
    background-color: white !important;
  }
  body.chakra-ui-dark .react-calendar__navigation__next-button {
    background-color:white !important;
    color: black;
  }
  body.chakra-ui-dark .react-calendar__tile {
    color: white;
  }
  body.chakra-ui-dark .react-calendar__tile:enabled:hover,
  body.chakra-ui-dark .react-calendar__tile:enabled:focus {
    background-color: rgba(255, 255, 255, 0.1);
  }
  body.chakra-ui-dark .react-calendar__month-view__weekdays {
    background-color: rgba(255, 255, 255, 0.1);
  }
  body.chakra-ui-dark .react-calendar__tile--now,
  body.chakra-ui-dark .react-calendar__tile--now:enabled:hover,
  body.chakra-ui-dark .react-calendar__tile--now:enabled:focus {
    background-color: rgba(255, 255, 255, 0.1);
  }
  body.chakra-ui-dark .react-calendar__month-view__days__day--neighboringMonth {
    color: #b0bbd5;
  }
  body.chakra-ui-dark .react-calendar__tile--active,
  body.chakra-ui-dark .react-calendar__tile--active:enabled:hover,
  body.chakra-ui-dark .react-calendar__tile--active:enabled:focus {
    background: #7551ff;
    color: white;
  }
  body.chakra-ui-dark .react-calendar__tile--range,
  body.chakra-ui-dark .react-calendar__tile--range:enabled:hover,
  body.chakra-ui-dark .react-calendar__tile--range:enabled:focus {
    background: rgba(255, 255, 255, 0.1);
    color: #7551ff;
  }
  body.chakra-ui-dark .react-calendar__tile--rangeStart,
  body.chakra-ui-dark .react-calendar__tile--rangeStart:enabled:hover,
  body.chakra-ui-dark .react-calendar__tile--rangeStart:enabled:focus {
    background: #7551ff;
    color: white;
  }
  body.chakra-ui-dark .react-calendar__tile--rangeEnd,
  body.chakra-ui-dark .react-calendar__tile--rangeEnd:enabled:hover,
  body.chakra-ui-dark .react-calendar__tile--rangeEnd:enabled:focus {
    background: #7551ff;
    color: white;
  }
  

`;

interface PageWrapperType {
  paddingTop?: string;
}

/** Page Wrapper */
export const PageWrapper = styled.div<PageWrapperType>`
  background-color: #fef8e6 !important;
  padding-top: ${(props) => props.paddingTop || '0px'};
  padding-bottom: ${(props) => '449.78px'};
  @media screen and (max-width: 768px) {
    padding-top: ${(props) => '0px'};
    padding-bottom: ${(props) => '659px'};
  }
`;


/** Page Container */
interface ContainerType {
  maxWidth?: string;
  marginTop?: string;
  paddingLeft?: string;
  paddingRight?: string;
  paddingTop?: string;
  paddingBottom?: string;
  mdPaddingTop?: string;
  mdPaddingBottom?: string;
}

export const Container = styled.div<ContainerType>`
  margin: auto;
  max-width: ${(props) => props.maxWidth || '1512px'}; // Corrected '1141x' to '1141px'
  margin-top: ${(props) => props.marginTop || '0px'};
  padding-left: ${(props) => props.paddingLeft || '96px'};
  padding-right: ${(props) => props.paddingRight || '96px'};
  padding-top: ${(props) => props.paddingTop || '75px'};
  padding-bottom: ${(props) => props.paddingBottom || '89px'};
  width: 100%;
  @media screen and (max-width: 768px) {
    padding-top: ${(props) => props.mdPaddingTop || '41px'};
    padding-bottom: ${(props) => props.mdPaddingBottom || '50px'};
  }
`