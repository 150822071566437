import React from 'react'
import CustomText from '../common/CustomText'
import { FlexBox } from '../common/FlexBox';
import { CALENDAR_ICON, CLOCK_ICON, CONNECT_ICON, DEFAULT_AVATAR_IMAGE, TWITTER_ICON } from '../../constants/image.constants';
import CustomButton from '../common/CustomButton';
import styled from 'styled-components';
import CustomImage from 'components/common/CustomImage';
import { useMedia } from 'react-use';
import { timestampToDayTime, timestampToDayTime2 } from 'hooks/date';


interface EventCardType {
  setShowRegisterModal?: any;
  data: EventType;
  disabled: boolean;
  setActiveRegisterEvent: any;
  activeDate?: number;
}

const EventCard = ({
  setShowRegisterModal, data, setActiveRegisterEvent, activeDate, disabled
}: EventCardType) => {
  const isMobile = useMedia('(max-width: 550px)');
  return (
    <FlexBox direction='column'>
      <CustomText
        text={timestampToDayTime2(activeDate)}
        fontSize='16px'
        lineHeight='41px'
        fontWeight='400'
        height='54px'
        justifyContent='center'
      />
      <FlexBox
        gap="12px"
        justifyContent='start'
        alignItems='center'
        mdDirection='row'
        mdBgColor='white'

        mdPaddingTop='8px'
        mdPaddingBottom='8px'
        mdPaddingLeft='8px'
        mdPaddingRight='8px'
        borderRadius='4px'
      >
        <FlexBox
          borderRadius='4px'
          bgColor='white'
          maxWidth='483px'
          justifyContent='center'
          direction='column'
          height='223px'
          paddingLeft='21px'
          mdHeight='auto'
          paddingRight='20px'
        >
          <CustomText
            text={data.event_name && data.event_name ? data.event_name : ''}
            fontSize='28px'
            fontWeight='700'
            mdFontSize='21px'
            justifyContent='start'
          />
          <CustomText
            marginTop='6px'
            text={`Hosted by ${data.organizer && data.organizer ? data.organizer.slice(0, 10) + "..." : ''}`}
            fontSize='14px'
            fontWeight='400'
            mdFontSize='12px'
          />
          <FlexBox alignItems='center' gap="13.46px" mdDirection='row' justifyContent='start' mdJustifyContent='start'>
            {/* <CustomImage
              image={CLOCK_ICON}
              width='18px'
              height='18px'
            /> */}
            <CustomText
              text={data.event_time && data.event_time ? timestampToDayTime(data.event_time) : ''}
              marginTop='12px'
              mdMarginTop='10px'
              fontSize='16px'
              fontWeight='400'
              mdFontSize='12px'
              textAlign='left'
              mdAlignItems='left'
            />
          </FlexBox>
          <CustomText
            marginTop='12px'
            mdMarginTop='10px'
            text={data.description && data.description ? data.description.slice(0, 100) + "..." : ''}
            fontSize='16px'
            fontWeight='400'
            mdFontSize='12px'
            mdLineHeight='120%'
          />
        </FlexBox>

        <CustomImage
          image={data.image && data.image !== "" ? data.image : DEFAULT_AVATAR_IMAGE}
          width='221px'
          height='221px'
          mdHeight='112px'
          mdWidth='112px'
          borderRadius='4px'
        />
      </FlexBox>
      {
        isMobile &&
        <FlexBox
          justifyContent='space-between'
          mdDirection='row'
          bgColor='#e7e7e7'
          mdPaddingTop='8px'
          mdPaddingBottom='9px'
          mdPaddingLeft='15px'
          mdPaddingRight='18px'
          alignItems='center'
          borderRadius='0px 0px 4px 4px'
        >
          <CustomButton
            text="Register"
            width='317px'
            fontWeight='600'
            onClick={() => {
              setActiveRegisterEvent(data)
              setShowRegisterModal(true)
            }}
            smWidth='96px'
            smHeight='42px'
            smFontSize='16px'
            smLineHeight='100%'
          />
          <FlexBox
            justifyContent='start'
            paddingLeft='74px'
            gap='25px'
            mdDirection='row'
            mdJustifyContent='end'
          >
            <CustomImage
              image={TWITTER_ICON}
              width='40px'
              height='40px'
              mdWidth='25px'
              mdHeight='25px'
            />
            <CustomImage
              image={CONNECT_ICON}
              width='40px'
              height='40px'
              mdWidth='25px'
              mdHeight='25px'
            />
            <CustomImage
              image={CALENDAR_ICON}
              width='40px'
              height='40px'
              mdWidth='25px'
              mdHeight='25px'
            />

          </FlexBox>

        </FlexBox>
      }
      {
        !isMobile &&
        <>
          <FlexBox
            justifyContent='start'
            paddingLeft='74px'
            gap='25px'
            mdDirection='row'
            marginTop='44px'
          >
            <CustomImage
              image={TWITTER_ICON}
              width='40px'
              height='40px'
              mdWidth='25px'
              mdHeight='25px'
            />
            <CustomImage
              image={CONNECT_ICON}
              width='40px'
              height='40px'
              mdWidth='25px'
              mdHeight='25px'
            />
            <CustomImage
              image={CALENDAR_ICON}
              width='40px'
              height='40px'
              mdWidth='25px'
              mdHeight='25px'
            />
          </FlexBox>
          <CustomButton
            text="Register"
            width='317px'
            marginTop='25px'
            onClick={() => {
              setActiveRegisterEvent(data)
              setShowRegisterModal(true)
            }}
            disabled={disabled}
          />
        </>
      }



    </FlexBox>
  )
}

export default EventCard