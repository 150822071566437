import CustomButton from 'components/common/CustomButton'
import CustomText from 'components/common/CustomText'
import { FlexBox } from 'components/common/FlexBox'
import React, { useContext } from 'react'
import { Container, PageWrapper } from 'styles/GlobalStyles'


import CustomImage from 'components/common/CustomImage'
import { CRASHR_BLACK_ICON, DEFAULT_AVATAR_IMAGE, DEFAULT_NFT_IAMGE, DISCORD_BLACK_ICON, TWITTER_ICON } from 'constants/image.constants'
import CopyToClipboard from 'react-copy-to-clipboard'
import WalletCopyBoard from 'components/WalletCopyBoard'
import { useMedia } from 'react-use'
import { useWalletConnect } from 'hooks/WalletConnect'
import CustomLinkButton from 'components/common/CustomLinkButton'
import { GlobalProvider } from 'App'
import ExplorerNFTCard from 'components/pages/explorer/ExplorerNFTCard'
import styled from 'styled-components'

const UserName = styled.div`
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  max-width: 600px;
  width: 100%;
  color: black;
  font-family: Inconsolata;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.84px;
  @media screen and (max-width: 768px) {
    font-size: 28px;
  }
`

const SocialLink = styled.a`
  cursor: pointer;
  img{
    width: 36px;
    height: 36px;
  }
`

const MyProfile = () => {
  const isMobile = useMedia('(max-width: 768px)');

  const { myNFTs, userData } = useContext(GlobalProvider);
  const { myWalletAddress } = useWalletConnect()
  return (
    <PageWrapper>
      <Container
        maxWidth='1121px'
        paddingLeft='16px'
        paddingRight='16px'
      >
        <FlexBox
          justifyContent='start'
          gap="50px"
          mdBgColor='white'
          borderRadius='3px'
          mdPaddingTop='15px'
          mdPaddingRight='18px'
          mdPaddingBottom='27px'
          mdPaddingLeft='13px'
          mdDirection='row'
          mdGap='0px'
          mdJustifyContent='space-between'
        >
          <FlexBox
            direction='column'
            gap="55px"
            maxWidth='240px'
            mdGap='10px'
          >
            <CustomText
              borderRadius='59px'
              bgColor='#c8ede9'
              text="Rank #1"
              fontSize='31px'
              fontWeight='700'
              paddingLeft='28px'
              paddingBottom='8px'
              paddingRight='28px'
              paddingTop='8px'
            />
            <CustomImage
              image={userData && userData?.bomber_avatar ? userData?.bomber_avatar : userData?.avatar}
              width='240px'
              height='240px'
              mdWidth='127px'
              mdHeight='127px'
              borderRadius='9px'
            />
            {
              isMobile &&
              <CustomLinkButton
                text='Edit Profile'
                link="/edit-profile"
                width='243px'
                height='50px'
                smWidth='117px'
                smHeight='42px'
                fontSize='19px'
                fontWeight='600'
                marginTop='0px'
                smFontSize='16px'

              />
            }
          </FlexBox>
          <FlexBox
            direction='column'
            justifyContent='space-between'
            alignItems='default'
            mdPaddingTop='28px'
          >
            <UserName>
              {userData && userData.bomber_username ? userData.bomber_username : myWalletAddress}
            </UserName>

            <FlexBox direction='column' gap='12px' marginTop='17px'>
              <FlexBox
                justifyContent='start'
                gap="30px"
                mdDirection='row'
                mdJustifyContent='start'
                mdGap="10px"
              >
                <CustomText
                  text={userData !== undefined && userData.points && userData.points}
                  fontSize='28px'
                  fontWeight='700'
                  color='#21b6a8'
                />
                <CustomText
                  text={`PTS`}
                  fontSize='28px'
                  fontWeight='700'
                  color="#4f4f4f"
                />
              </FlexBox>
              <FlexBox
                justifyContent='start'
                gap="30px"
                mdDirection='row'
                mdJustifyContent='start'
                mdGap="10px"
              >
                <CustomText
                  text={myNFTs && myNFTs.length}
                  fontSize='28px'
                  fontWeight='700'
                  color='#21b6a8'
                />
                <CustomText
                  text={`Bombers Owned`}
                  fontSize='28px'
                  fontWeight='700'
                  color="#4f4f4f"
                  className='text-nowrap'
                />
              </FlexBox>
              <FlexBox
                justifyContent='start'
                gap="30px"
                mdDirection='row'
                mdJustifyContent='start'
                mdGap="10px"
              >
                <CustomText
                  text={
                    `${userData.friends && userData.friends.length > 0 ? userData.friends.length : 0
                    }`}
                  fontSize='28px'
                  fontWeight='700'
                  color='#21b6a8'
                />
                <CustomText
                  text={`Friends`}
                  fontSize='28px'
                  fontWeight='700'
                  color="#4f4f4f"
                />
              </FlexBox>
            </FlexBox>
            <FlexBox
              gap="40px"
              justifyContent='start'
              marginTop='24px'
              mdDirection='row'
              mdJustifyContent='start'
              mdGap='27px'
            >
              <SocialLink
                href={userData && userData.crashr}
              >
                <img src={CRASHR_BLACK_ICON} />
              </SocialLink>
              <SocialLink
                href={userData && userData.discord}
              >
                <img src={DISCORD_BLACK_ICON} />
              </SocialLink>
              <SocialLink
                href={userData && userData.twitter}
              >
                <img src={TWITTER_ICON} />
              </SocialLink>

            </FlexBox>
            {
              !isMobile &&
              <CustomLinkButton
                text='Edit Profile'
                link="/edit-profile"
                width='243px'
                height='50px'
                smWidth='117px'
                smHeight='42px'
                fontSize='19px'
                fontWeight='600'
                marginTop='27px'
                smFontSize='16px'
              />
            }

          </FlexBox>
        </FlexBox>
        {/**** Bombers */}
        <CustomText
          text="Bombers"
          fontSize='50px'
          fontWeight='700'
          marginTop='80px'
          mdFontSize='24px'
        />

        <FlexBox
          flexWrap='wrap'
          gap="72px"
          marginTop='52px'
          justifyContent='center'
          alignItems='center'
        >
          {
            myNFTs && myNFTs.map((nft: MyNFT) => {
              return (
                <ExplorerNFTCard
                  image={nft.image.replace(
                    "ipfs://",
                    "https://image-optimizer.jpgstoreapis.com/"
                  )}
                  name={nft?.name}
                />
              )
            })
          }
        </FlexBox>
      </Container>
    </PageWrapper>

  )
}

export default MyProfile