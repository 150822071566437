import React, { useEffect, useState } from 'react'
import { Container, PageWrapper } from '../../styles/GlobalStyles'
import CustomText from '../../components/common/CustomText'
import { FlexBox } from '../../components/common/FlexBox'
import CustomButton from '../../components/common/CustomButton'


import { BomberAsset, BomberImage, BomberNFT, BomberNFTsList, ExplorerFiltering, ExplorerFilteringSelection } from './index.styled';
import { useMedia } from 'react-use'
import ExplorerStats from './Stats'


import { useWalletConnect } from 'hooks/WalletConnect'

import data from './plutus.json'
import { Constr, Data, fromHex, toHex } from 'lucid-cardano'
import { decode, encode } from 'cbor-x';
import ExplorerTab from 'components/pages/explorer/ExplorerTab'
import ExplorerMain from './ExplorerMain'
import ExplorerOverview from './ExplorerOverview'
import ExplorerAttributes from './ExplorerAttributes'


const TAB_LIST = [
  // {
  //   text: "Explorer",
  //   borderRadius: "3px 0px 0px 3px",
  //   borderRight: "none"
  // },
  //3px 0px 0px 3px
  //
  {
    text: "Overview",
    borderRadius: "3px 0px 0px 3px",
    borderRight: "none"
  },
  {
    text: "Attributes",
    borderRadius: "0px 3px 3px 0px"
  },
]

const Explorer = () => {
  const [activeTab, setActiveTab] = useState("Overview")
  const { lucid, myWalletAddress } = useWalletConnect();

  const isMobile = useMedia('(max-width: 425px)');

  const listingAPI = async () => {
    // const marketScript = {
    //   type: "PlutusV2",
    //   script: toHex(cbor.encode(fromHex(data.validators[0].compiledCode)))
    // };

    // console.log("data");
    try {
      const marketScript = {
        type: "PlutusV2",
        script: toHex(encode(fromHex(data.validators[0].compiledCode)))
      };


      // @ts-ignore
      const validatorHash = lucid.utils.validatorToScriptHash(marketScript);
      // console.log("validator hash")
      // console.log(validatorHash)
      const CredentialSC = lucid.utils.scriptHashToCredential(validatorHash);

      let api = undefined

      const { paymentCredential, stakeCredential } = lucid.utils.getAddressDetails(
        myWalletAddress
      );
      // console.log("credentials")
      // console.log(paymentCredential.hash)
      // console.log(stakeCredential.hash)
      const addressRequest = lucid.utils.credentialToAddress(CredentialSC);
      // console.log("address request")
      // console.log(addressRequest)
      const payment_vkh = new Constr(0, [paymentCredential.hash]);
      const staking_vkh = new Constr(0, [stakeCredential.hash]); //secondo me qua è 0
      const staking_inline = new Constr(0, [new Constr(0, [staking_vkh])])
      const addressCbor = new Constr(0, [payment_vkh, staking_inline])
      // console.log(addressCbor)


      var datumRequest = Data.to(new Constr(0,
        [addressCbor,//policy Borrower
          "",//HERE THE POLICY OF THE TOKEN, if ADA is empty
          "",//HERE THE ASSETNAME IN HEX, if ADA is empty
          BigInt(5000000),//HERE THE PRICE BEWARE OF DECIMALES
        ])
      );
      // console.log(datumRequest)

      let nfts = {}
      //This is the unit of the NFT I want to sell policyid+assetname
      nfts['2a54d0a4ff765ad06a84440494b92419e769b17898415341f3b5467547616d696e6743617264616e6f564950333034'] = 1n


      const tx = await lucid
        .newTx()
        .payToContract(addressRequest, { inline: datumRequest }, nfts)
        .complete();

      const signedTx = await tx.sign().complete();
      const txHash = await signedTx.submit();
      console.log(txHash)
    } catch (err) {
      console.log("err", err)
    }

  }

  return (
    <PageWrapper paddingTop="0px">
      <Container
        paddingTop='0px'
        maxWidth='1350px'
        paddingLeft='25px'
        paddingRight='25px'
      >
        {/** stats values */}

        {
          !isMobile &&
          <ExplorerStats />
        }

        <FlexBox
          marginTop='87px'
          mdDirection='row'
          justifyContent='start'
        >
          {
            TAB_LIST.map((tab, index) => {
              return (
                <ExplorerTab
                  key={index}
                  text={tab.text}
                  active={activeTab === tab.text}
                  onClick={() => {
                    setActiveTab(tab.text)
                  }}
                  borderRadius={tab.borderRadius}
                  borderRight={tab.borderRight}
                />
              )
            })
          }
        </FlexBox>
        {
          activeTab === "Explorer" &&
          <ExplorerMain />
        }
        {
          activeTab === "Overview" &&
          <ExplorerOverview />
        }
        {
          activeTab === "Attributes" &&
          <ExplorerAttributes />
        }


      </Container>
    </PageWrapper>
  )
}

export default Explorer