import React, { useContext } from 'react'
import styled from 'styled-components'
import { FlexBox } from '../common/FlexBox'
import CustomText from '../common/CustomText'
import CustomButton from '../common/CustomButton'
import { CONNECT_ICON, DEFAULT_NFT_IAMGE, DISCORD_BLACK_ICON, INSTAGRAM_ICON, TWITTER_ICON } from '../../constants/image.constants'
import CustomImage from 'components/common/CustomImage'
import SocialImageLink from 'components/common/SocialImageLink'
import { GlobalProvider } from 'App'

const ClubCardStyle = styled.div`
  background: white;
  padding: 19px 13px 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 405px;
  width: 100%;
  gap: 10px;
  @media screen and (max-width: 768px) {
  }
`
interface Props {
  data: ClubType;
  setShowRegisterModal: any;
  setActiveRegisterClub: any;
}

const ClubCard = ({
  data, setShowRegisterModal, setActiveRegisterClub
}: Props) => {
  const { myNFTs, loading } = useContext(GlobalProvider);
  return (
    <ClubCardStyle>
      <FlexBox mdDirection='row' mdHeight='121px' height='130px' mdAlignItems='center'>
        <FlexBox  justifyContent='start' direction='column'>
          <CustomText
            color='black'
            text={data?.club_name ? data?.club_name.slice(0,12) : ""}
            fontSize='28px'
            lineHeight='28px'
            fontWeight='700'
            mdFontSize='21px'
          />
          <CustomText
            text={data?.website ? data?.website.slice(0, 20) + "..." : ""}
            color='black'
            fontSize='14px'
            lineHeight='20px'
            fontWeight='600'
            marginTop='18px'
            display='default'
            className='three-dots'
            mdMaxWidth='150px'
            mdFontSize='12px'
            mdLineHeight='16px'
          />

          <CustomText
            text={
              data?.description ? data?.description.slice(0, 90) + "..." : ""
            }
            color='black'
            fontSize='14px'
            fontWeight='400'
            marginTop='6px'
            lineHeight='20px'
            mdFontSize='12px'
            mdLineHeight='16px'
          />
        </FlexBox>
        <CustomImage
          image={data?.image ? data?.image : DEFAULT_NFT_IAMGE}
          width='124px'
          height='124px'
          mdWidth='93px'
          mdHeight='93px'
          borderRadius='4px'
        />
      </FlexBox>
      <FlexBox mdDirection='row' alignItems='center'>
        <FlexBox justifyContent='start'>
          <CustomButton
            width='121px'
            height='42px'
            bgColor='#282828'
            text="Register"
            color='white'
            borderRadius='3px'
            fontSize='16px'
            fontWeight='600'
            disabled={myNFTs.length === 0}
            onClick={() => {
              setActiveRegisterClub(data);
              setShowRegisterModal(true)
            }}
          />
        </FlexBox>

        <FlexBox direction='column' gap='19px' maxWidth='124px' mdWidth='112px'>

          <FlexBox justifyContent='start' gap="18px" mdDirection='row'
            mdJustifyContent='start' mdGap='10px'>
            {
              data.twitter && data.twitter !== "" &&
              <SocialImageLink
                width='23px'
                height='23px'
                image={TWITTER_ICON}
                link={data.twitter}
              />
            }
            {
              data.instagram && data.instagram !== "" &&
              <SocialImageLink
                width='23px'
                height='23px'
                image={CONNECT_ICON}
                link={data.instagram}
              />

            }
            {
              data.discord && data.discord !== "" &&
              <SocialImageLink
                width='23px'
                height='23px'
                image={DISCORD_BLACK_ICON}
                link={data.discord}
              />
            }
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </ClubCardStyle >
  )
}

export default ClubCard