import styled from 'styled-components';


interface FlexBoxStyleType {
  gap?: string;
  justifyContent?: string;
  direction?: string;
  alignItems?: string;
  marginBottom?: string;
  marginTop?: string;
  marginLeft?: string;
  marginRight?: string;
  width?: string;
  padding?: string;
  paddingRight?: string;
  paddingLeft?: string;
  paddingTop?: string;
  paddingBottom?: string;
  height?: string;
  flexWrap?: string;
  position?: string;
  fontSize?: string;
  lineHeight?: string;
  fontWeight?: string;
  maxWidth?: string;
  maxHeight?: string;
  border?: string;
  borderRadius?: string;
  bgColor?: string;
  borderTop?: string;
  borderLeft?: string;
  borderBottom?: string;
  borderRight?: string;
  boxShadow?: string;
  textAlign?: string;

  borderTopLeftRadius?: string;
  borderTopRightRadius?: string;
  borderBottomLeftRadius?: string;
  borderBottomRightRadius?: string;
  top?: string;
  right?: string;
  display?: string;
  bgImage?: string;
  color?: string;
  cursor?: string;
  alignSelf?: string;

  mdDirection?: string;
  mdMarginBottom?: string;
  mdMarginTop?: string;
  mdGap?: string;
  mdFontSize?: string;
  mdLineHeight?: string;
  mdFontWeight?: string;
  mdJustifyContent?: string;

  mdPaddingRight?: string;
  mdPaddingLeft?: string;
  mdPaddingTop?: string;
  mdPaddingBottom?: string;
  mdPadding?: string;
  mdHeight?: string;
  mdPosition?: string;
  mdWidth?: string;
  mdBorderRight?: string;
  mdAlignItems?: string;
  mdBorderBottom?: string;
  mdBorderTop?: string;
  mdBorderLeft?: string;
  mdBgColor?: string;
}


export const FlexBox = styled.div<FlexBoxStyleType>`
  display: ${(props) => props.display ? props.display : 'flex'};
  top: ${(props) => props.top || ''};
  text-align: ${(props) => props.textAlign};
  right: ${(props) => props.right || ''};
  position: ${(props) => props.position || ''};
  width: ${(props) => props.width || '100%'};
  max-width: ${(props) => props.maxWidth};
  align-items: ${(props) => props.alignItems || 'start'};
  flex-wrap: ${(props) => props.flexWrap || ''};
  justify-content: ${(props) => props.justifyContent || 'center'};
  flex-direction: ${(props) => props.direction || 'row'};
  gap: ${(props) => props.gap || '0px'};
  height: ${(props) => props.height};
  color: ${(props) => props.color || 'white'};
  max-height: ${(props) => props.maxHeight};
  box-shadow: ${(props) => props.boxShadow};
  margin-bottom: ${(props) => props.marginBottom || '0px'};
  margin-top: ${(props) => props.marginTop || '0px'};
  margin-left: ${(props) => props.marginLeft || '0px'};
  margin-right: ${(props) => props.marginRight || '0px'};
  padding: ${(props) => props.padding};
  padding-right: ${(props) => props.paddingRight || '0px'};
  padding-left: ${(props) => props.paddingLeft || '0px'};
  padding-bottom: ${(props) => props.paddingBottom || '0px'};
  padding-top: ${(props) => props.paddingTop || '0px'};
  font-weight: ${(props) => props.fontWeight || '700'};
  font-size: ${(props) => props.fontSize || '28px'};
  line-height: ${(props) => props.lineHeight || '41px'};
  border-top: ${(props) => props.border ? props.border : props.borderTop};
  border-right: ${(props) => props.border ? props.border : props.borderRight};
  border-left: ${(props) => props.border ? props.border : props.borderLeft};
  border-bottom: ${(props) => props.border ? props.border : props.borderBottom};
  border-radius: ${(props) => props.borderRadius};
  border-top-left-radius: ${(props) => props.borderRadius ? props.borderRadius : props.borderTopLeftRadius};
  border-bottom-left-radius: ${(props) => props.borderRadius ? props.borderRadius : props.borderBottomLeftRadius};
  border-top-right-radius: ${(props) => props.borderRadius ? props.borderRadius : props.borderTopRightRadius};
  border-bottom-right-radius: ${(props) => props.borderRadius ? props.borderRadius : props.borderBottomRightRadius};
  background-color: ${(props) => props.bgColor};
  background-image: ${(props) => props.bgImage};
  cursor: ${(props) => props.cursor};
  align-self: ${(props) => props.alignSelf};
  @media screen and (max-width: 768px) {
    flex-direction: ${(props) => props.mdDirection || 'column'};
    margin-bottom: ${(props) => props.mdMarginBottom || props.marginBottom};
    margin-top: ${(props) => props.mdMarginTop || props.marginTop};
    gap: ${(props) => props.mdGap || props.gap};


    font-weight: ${(props) => props.mdFontWeight || props.fontWeight};
    font-size:${(props) => props.mdFontSize || '18px'};
    line-height: ${(props) => props.mdLineHeight || '26px'};
    justify-content: ${(props) => props.mdJustifyContent || 'center'};
    padding-right: ${(props) => props.mdPaddingRight || '0px'};
    padding-left: ${(props) => props.mdPaddingLeft || '0px'};
    padding-bottom: ${(props) => props.mdPaddingBottom || '0px'};
    padding-top: ${(props) => props.mdPaddingTop || '0px'};
    height: ${(props) => props.mdHeight};
    width: ${(props) => props.mdWidth};
    position: ${(props) => props.mdPosition || ''};
    align-items: ${(props) => props.mdAlignItems};
    padding: ${(props) => props.padding};
    background-color: ${(props) => props.mdBgColor};
    border-top: ${(props) => props.mdBorderTop};
    border-right: ${(props) => props.mdBorderRight};
    border-left: ${(props) => props.mdBorderLeft};
    border-bottom: ${(props) => props.mdBorderBottom};
    
  }
`
