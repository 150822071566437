// import styled from 'styled-components';
// import { H5 } from '../common/Typography';

// const Wrapper = styled.div`
//   position: fixed;
//   top: 0;
//   bottom: 0;
//   width: 100vw;
//   height: 100vh;
//   background: rgba(0, 0, 0, 0.64);
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-flow: column;
//   z-index: 9999;

//   img {
//     margin-bottom: 20px;
//   }
// `;

const AppLoader = () => {
  return <>
    Loading..
  </>;
};

export default AppLoader;
