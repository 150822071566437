import CustomImage from 'components/common/CustomImage'
import CustomText from 'components/common/CustomText'
import { FlexBox } from 'components/common/FlexBox'
import { TEST_EXP_IMAGE } from 'constants/image.constants'
import styled from 'styled-components';

const ExplorerMainTable = styled.div`
    max-width: 1070px;
    width: 100%;
    margin: auto;
    margin-top: 66px;
`
const ExplorerMainHeader = styled.div`
    width: 100%;
    color: #000;
    font-family: Open Sans;
    font-size: 23.936px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;  
    display: flex;  
    padding: 7px 12px;
    div:nth-child(1){
        width: 10%;
    }
    div:nth-child(2){
        width: 80%;
    }
    div:nth-child(3){
        width: 10%;
        justify-content: center;
        display: flex;
        align-items: center;        
    }
    @media screen and (max-width: 768px) {
        font-size: 14px;
        div:nth-child(1){
            width: 20%;
            
        }  
        div:nth-child(2){
            width: 60%;
        }  
        div:nth-child(3){
            width: 20%;
        }    
    }
`
const ExplorerMainItem = styled.div`
    width: 100%;
    color: #000;
    font-family: Open Sans;
    font-size: 23.936px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;  
    display: flex; 
    border-bottom: 1px solid #9E9E9E;
    padding: 16px;
    div:nth-child(1){
        width: 10%;
        display: flex;
        align-items: center;
    }
    div:nth-child(2){
        width: 80%;
        display: flex;
        align-items: center;
        div:nth-child(1){
            color: var(--greyscale-600, #9E9E9E);
            font-family: Open Sans;
            font-size: 18.178px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            width: inherit;
        }
        div:nth-child(2){
            color: black;
            font-family: Open Sans;
            font-size: 24.238px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
    div:nth-child(3){
        width: 10%;
        color: #7c1c1c;
        font-family: Open Sans;
        font-size: 31.812px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        justify-content: center;
        display: flex;
        align-items: center;
    }
    @media screen and (max-width: 768px) {
        font-size: 14px;
        padding: 8px;
        border-bottom: none;
        div:nth-child(1){
            width: 20%;
        }  
        div:nth-child(2){
            width: 60%;
            div:nth-child(1){
                font-size: 12px;
            }
            div:nth-child(2){
                font-size: 16px;
            }
        }  
        div:nth-child(3){
            width: 20%;
            font-size: 16px;
        }    
    }
`

const data = [
    {
        floor: "10",
    },
    {
        floor: "10",
    },

    {
        floor: "10",
    },
    {
        floor: "10",
    },
    {
        floor: "10",
    },
    {
        floor: "10",
    },

]

const ExplorerMain = () => {
    return (
        <ExplorerMainTable>
            <ExplorerMainHeader>
                <div>
                    #
                </div>
                <div>
                    Trait
                </div>
                <div>
                    Floor
                </div>
            </ExplorerMainHeader>
            {
                data.map((item, index) => {
                    return (
                        <ExplorerMainItem key={index}>
                            <div>
                                1
                            </div>
                            <div>
                                <CustomImage
                                    image={TEST_EXP_IMAGE}
                                    width='76px'
                                    height='76px'
                                    mdWidth='50px'
                                    mdHeight='50px'
                                />
                                <div className="d-flex flex-column justify-content-between">
                                    <div>Eyewear</div>
                                    <div>Nouns</div>
                                </div>
                            </div>
                            <div>
                                ₳5

                            </div>
                        </ExplorerMainItem>
                    )
                })
            }

        </ExplorerMainTable>
    )
}

export default ExplorerMain
