import React from 'react'
import styled from 'styled-components';

interface SocialImageLinkStyleType {
    width?: string;
    height?: string;
    mdWidth?: string;
    mdHeight?: string;
    image?: string;
    link?: string;
}



const SocialImageLinkStyle = styled.a<SocialImageLinkStyleType>`
    cursor: pointer;
    img{
        width: ${(props) => props.width};
        height: ${(props) => props.height};
        
        @media screen and (max-width: 768px) {
            width: ${(props) => props.mdWidth};
            height: ${(props) => props.mdHeight};
        }
    }
`

const SocialImageLink = (
    {
        width,
        height,
        mdWidth,
        mdHeight,
        link,
        image
    }: SocialImageLinkStyleType
) => {
    return (
        <SocialImageLinkStyle
            width={width}
            height={height}
            mdHeight={mdHeight}
            mdWidth={mdWidth}
            href={link}
            target='_blank'
        >
            <img src={image} />
        </SocialImageLinkStyle>
    )
}

export default SocialImageLink