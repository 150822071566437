import React from 'react'
import styled from 'styled-components';

interface CustomImageStyleType {
  width?: string;
  height?: string;
  image?: string;
  mdWidth?: string;
  mdHeight?: string;
  onClick?: any;
  cursor?: string;
  borderRadius?: string;
}



const CustomImageStyle = styled.img<CustomImageStyleType>`
  max-width: ${(props) => props.width};
  width: 100%;
  height: ${(props) => props.height};
  border: 4px;
  cursor: ${(props) => props.cursor};
  border-radius: ${(props) => props.borderRadius};
  @media screen and (max-width: 768px) {
    max-width: ${(props) => props.mdWidth};
    height: ${(props) => props.mdHeight};
  }
`

const CustomImage = (
  {
    width,
    height,
    image,
    mdWidth,
    mdHeight,
    onClick,
    cursor,
    borderRadius
  }: CustomImageStyleType
) => {
  return (
    <CustomImageStyle
      width={width}
      height={height}
      src={image}
      mdHeight={mdHeight}
      mdWidth={mdWidth}
      onClick={onClick}
      cursor={cursor}
      borderRadius={borderRadius}
    ></CustomImageStyle>
  )
}

export default CustomImage